import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ref, onValue, update } from "firebase/database"
import { database } from './lib/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import SocialMediaDashboard from './components/socialMediaDashboard';
import { toast } from 'react-toastify';
import * as constants from './constants.jsx';
import './css/web.css';

const SocialMediaManager = ({user}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [contentData, setContentData] = useState(null);
  const [instagramData, setInstagramData] = useState(null);
  const [tiktokData, setTiktokData] = useState(null);

  const fetchData = async (uid) => {
    const businessDbRef = ref(database, `businesses/${uid}`);
    onValue(businessDbRef, (snapshot) => {
      const business = snapshot.val();
      if (business) {
        axios.get(`${constants.backendDomain}/business/v1/social/refresh?id=${uid}`);
        setContentData(business.cached?.content);
        setInstagramData(business.cached?.ig);
        setTiktokData(business.cached?.tt);
        if (business.cached?.ig) {
          const lastRefreshed = Object.values(business.cached?.ig?.instagramData || {}).map((data) => data.refreshed).reduce((a, b) => Math.max(a, b), 0);
          if (lastRefreshed) {
            const now = new Date().getTime() / 1000;
            if (now - lastRefreshed > 86400) {
              axios.get(`${constants.backendDomain}/business/v1/social/refresh?id=${uid}`);
            }
          } else {
            axios.get(`${constants.backendDomain}/business/v1/social/refresh?id=${uid}`);
          }
        }
      }
    });
  };

  const persistTokensIfExist = async (uid) => {
    const params = new URLSearchParams(location.search);
    if (params.has('fb_access_token')) {
      const fbAccessToken = params.get('fb_access_token');

      const accountsResponse = await axios.get(`https://graph.facebook.com/v19.0/me/accounts?fields=id,name,access_token&access_token=${fbAccessToken}`);
      const accounts = accountsResponse.data.data;

      const instagramAccounts = {};
      const media = {};
      for (const account of accounts) {
        const instagramAccountsResponse = await axios.get(`https://graph.facebook.com/v19.0/${account.id}?fields=instagram_business_account&access_token=${fbAccessToken}`);
        const instagramAccount = instagramAccountsResponse.data;
        if (instagramAccount?.instagram_business_account?.id) {
          const usernameResponse = await axios.get(`https://graph.facebook.com/v19.0/${instagramAccount.instagram_business_account.id}?fields=username,id&access_token=${fbAccessToken}`);
          instagramAccounts[account.id] = usernameResponse.data;
          const mediaResponse = await axios.get(`https://graph.facebook.com/v19.0/${instagramAccount.instagram_business_account.id}/media?fields=id,media_type,media_url,permalink,thumbnail_url,timestamp&access_token=${fbAccessToken}`);
          media[instagramAccount.instagram_business_account.id] = mediaResponse.data.data;
        }
      };
      const paramRef = ref(database, `businesses/${uid}/cached/ig`);
      await update(paramRef, { accounts, instagramAccounts, media });

      persistFacebookToken(uid, {
        'fb_access_token': fbAccessToken
      });
      navigate(window.location.hash.split('?')[0].substring(1));
    }
    if (params.has('tt_access_token') && params.has('expiry') && params.has('tt_refresh_token') && params.has('refresh_expiry')) {
      const ttAccessToken = params.get('tt_access_token');
      const ttExpiry = params.get('expiry');
      const ttRefreshToken = params.get('tt_refresh_token');
      const ttRefreshExpiry = params.get('refresh_expiry');

      const authStr = 'Bearer '.concat(ttAccessToken); 
      const userResponse = await axios.get('https://open.tiktokapis.com/v2/user/info/?fields=display_name,follower_count,likes_count,video_count', { headers: { Authorization: authStr } })
      const userData = userResponse.data.data.user;
      const paramRef = ref(database, `businesses/${uid}/cached/tt`);
      await update(paramRef, { userData });

      persistTikTokToken(uid, {
        'tt_access_token': ttAccessToken,
        'expiry': ttExpiry,
        'tt_refresh_token': ttRefreshToken,
        'refresh_expiry': ttRefreshExpiry
      });
      navigate(window.location.hash.split('?')[0].substring(1));
    }
  };

  const persistFacebookToken = async (uid, facebookToken) => {
    const paramRef = ref(database, `tokens/${uid}/fb-biz`);
    await update(paramRef, facebookToken);
  }

  const persistTikTokToken = async (uid, tiktokToken) => {
    const paramRef = ref(database, `tokens/${uid}/tt-biz`);
    await update(paramRef, tiktokToken);
  };

  const handleInstagramLogin = async () => {
    const newWindow = window.open(`${constants.backendDomain}/business/v1/auth/instagram`, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const handleTikTokLogin = async () => {
    const newWindow = window.open(`${constants.backendDomain}/business/v1/auth/tiktok`, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const handlePostClick = async (caption, url) => {
    try {
      toast.success('Posting video... please wait for up to 30 seconds for the video to post.');
      await axios.post(`${constants.backendDomain}/business/v1/social/post`,
      {
        id: user.uid,
        caption,
        videoUrl: url,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      toast.success('Video posted successfully.');
    } catch (error) {
      console.error(error);
      toast.error('Failed to post video');
    }
  };

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid);
      persistTokensIfExist(user.uid);
    }
  }, [user]);

  return (
    <div className="content-container" style={{ alignItems: 'center' }}>
      Link your social media accounts to get started!
      <div style={{ display: 'flex', gap: '20px', padding: '10px' }}>
        <div className="social-button-detail-container">
          <button className={`social-button ${instagramData ? 'active' : 'inactive' }`} onClick={handleInstagramLogin}><FontAwesomeIcon icon={faInstagram} /></button>
          {instagramData
            ? <div className="social-detail"><p>{Object.values(instagramData.instagramAccounts || {}).map((account) => account.username).join(', ')}</p></div>
            : <div className="social-detail"><p>Link your Instagram</p></div>
          }
        </div>
        <div className="social-button-detail-container">
          <button className={`social-button ${tiktokData ? 'active' : 'inactive' }`} onClick={handleTikTokLogin}><FontAwesomeIcon icon={faTiktok} /></button>
          {tiktokData
            ? <div className="social-detail"><p>{tiktokData.userData.display_name}</p></div>
            : <div className="social-detail"><p>Link your TikTok</p></div>
          }
        </div>
      </div>
      <SocialMediaDashboard
        contentData={contentData}
        instagramData={instagramData}
        tiktokData={tiktokData}
        postHandler={handlePostClick}
      />
    </div>
  );
}

export default SocialMediaManager;

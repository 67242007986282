import React, { useEffect, useState } from 'react';

const AvailabilityMessageModal = ( { shouldShow, businessName, onSuccess, onError } ) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [availability, setAvailability] = useState({ avail1: '', avail2: '', avail3: '' });

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleAvailabilityChange = (e) => {
    setAvailability({
      ...availability,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async () => {
    if (message.trim() && (availability.avail1.trim() || availability.avail2.trim() || availability.avail3.trim())) {
      onSuccess({ message, availability });
      setIsOpen(false);
    } else {
      onError('Please enter a message and provide at least one availability.');
    }
  };

  useEffect(() => {
    setIsOpen(shouldShow);
  }, [shouldShow]);

  return (
    <div>
      {isOpen &&
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div style={{
            position: 'relative',
            zIndex: 0,
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            width: '80%',
            minWidth: '400px',
            maxWidth: '600px',
            textAlign: 'center',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          }}>
            <form style={{ alignItems: 'center' }} onSubmit={handleSubmit}>
              <h2>Start planning your collab with:<br/>{businessName}</h2>
              <label style={{ display: 'flex', flexDirection: 'column', width: '90%', textAlign: 'left' }}>
                Get the conversation going:
              </label>
              <textarea style={{ width: '90%', maxWidth: '500px', height: '150px' }} value={message} placeholder={`Hey ${businessName} - I'm looking forward to working with you!`} onChange={handleMessageChange} />
              <b>Suggest some times to get things started:</b>
              <div>
                <label>
                  Availability 1:&ensp;
                </label>
                <input type="text" name="avail1" placeholder="January 5, 3-5pm" value={availability.avail1} onChange={handleAvailabilityChange} />
              </div>
              <div>
                <label>
                  Availability 2:&ensp;
                </label>
                <input type="text" name="avail2" placeholder="January 6, 2-4pm" value={availability.avail2} onChange={handleAvailabilityChange} />
              </div>
              <div>
                <label>
                  Availability 3:&ensp;
                </label>
                <input type="text" name="avail3" placeholder="January 7, 12-4pm" value={availability.avail3} onChange={handleAvailabilityChange} />
              </div>
            </form>
            <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleSubmit} style={{ fontSize: '1.1em', padding: '10px', margin: '10px 0', width: '100%' }}>Submit</button>
            <a style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => setIsOpen(false)}><u><b>Skip</b></u></a>
          </div>
        </div>
      }
    </div>
  );
};

export default AvailabilityMessageModal;

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

amplitude.init('513046550652de1d5c9ddd3211344fd8', {
  defaultTracking: true,
});

const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: 0.1,
});
amplitude.add(sessionReplayTracking);

export { amplitude };
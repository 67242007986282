import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { database, storage } from './lib/firebase';
import { ref as dbRef, onValue, child, ref, get, set, push, update, off } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from 'axios';
import { toast } from 'react-toastify';
import { ReachCalculator } from './lib/ReachCalculator.jsx';
import { NumberFormatter } from './lib/NumberFormatter.jsx';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faCircleInfo, faComment, faEdit, faEye, faPeopleGroup, faFaceSmile, faVideo, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import MediaItem from './components/mediaItem';
import UsernameModal from './components/usernameModal';
import YelpModal from './components/yelpModal';
import StatsCard from './components/statsCard';
import BadgesContainer from './components/badgesContainer';
import * as constants from './constants.jsx';
import './css/web.css';

function Profile({user}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { username } = useParams();
  const [uid, setUid] = useState(null);
  const [isSelf, setIsSelf] = useState(false);

  const params = new URLSearchParams(location.search);
  const businessUid = params.get('uid');
  const campaignId = params.get('id');

  const [campaignData, setCampaignData] = useState(null);

  const [profileContainerActive, setProfileContainerActive] = useState(false);
  const [restOfViewsActive, setRestOfViewsActive] = useState(false);

  const [publicName, setPublicName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [market, setMarket] = useState('');
  const [publicProfileUrl, setPublicProfileUrl] = useState(null);
  const [userTags, setUserTags] = useState([]);
  const [compensationTags, setCompensationTags] = useState([]);
  const [compensationAmount, setCompensationAmount] = useState(null);
  const [bio, setBio] = useState('');
  const [profilePic, setProfilePic] = useState('../images/pfp.png');
  const [isPobblesPro, setIsPobblesPro] = useState(false);

  const fileInputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [shouldShowUsernameModal, setShouldShowUsernameModal] = useState(false);
  const [isHoveringReach, setIsHoveringReach] = useState(false);
  const [isHoveringDisplayName, setIsHoveringDisplayName] = useState(false);
  const [isHoveringContentType, setIsHoveringContentType] = useState(false);
  const [isHoveringRate, setIsHoveringRate] = useState(false);

  const [availableMarkets, setAvailableMarkets] = useState([]);

  const [reach, setReach] = useState(null);
  const [instagramData, setIGData] = useState(null);
  const [tiktokData, setTTData] = useState(null);
  const [yelpData, setYelpData] = useState(null);

  const [mediaItemsDataSource, setMediaItemsDataSource] = useState([]);

  const [igStatsData, setIGStatsData] = useState([]);
  const [ttStatsData, setTTStatsData] = useState([]);

  const igEngagementThreshold = 2.2;
  const ttEngagementThreshold = 4.3;

  // const [reviewsData, setReviewsData] = useState([]);

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = ((newValue) => {
    if (newValue === tabValue) {
      setTabValue(-1);
    } else {
      setTabValue(newValue);
    }
  });

  const toggleTag = (tag) => {
    if (userTags.includes(tag)) {
      setUserTags(userTags.filter(t => t !== tag));
    } else {
      setUserTags([...userTags, tag]);
    }
  };

  const toggleCompensation = (tag) => {
    if (compensationTags.includes(tag)) {
      setCompensationTags(compensationTags.filter(t => t !== tag));
    } else {
      setCompensationTags([...compensationTags, tag]);
    }
  };

  const setUserData = async (param, value) => {
    if (!user?.uid) { return }
    var paramRef = null;
    if (param === 'market') { // update market in both
      paramRef = dbRef(database, `users/${user.uid}/${param}`);
      paramRef = dbRef(database, `influencers/${user.uid}/${param}`);
    } else if (param === 'first_name' || param === 'last_name') { // first / last name go in users/
      paramRef = dbRef(database, `users/${user.uid}/${param}`);
    } else {  // everything else goes in influencers/
      paramRef = dbRef(database, `influencers/${user.uid}/${param}`);
    }
    await set(paramRef, value);
  }

  const persistInstagramData = async (instagramData) => {
    if (!user?.uid) { return }
    const paramRef = dbRef(database, `influencers/${user.uid}/cached/ig`);
    await update(paramRef, instagramData);
  }

  const persistInstagramToken = async (instagramToken) => {
    if (!user?.uid) { return }
    const paramRef = dbRef(database, `tokens/${user.uid}/ig`);
    await update(paramRef, instagramToken);
  }

  const persistTikTokData = async (tiktokData) => {
    if (!user?.uid) { return }
    const paramRef = dbRef(database, `influencers/${user.uid}/cached/tt`);
    await update(paramRef, tiktokData);
  }

  const persistTikTokToken = async (tiktokTokenData) => {
    if (!user?.uid) { return }
    const paramRef = dbRef(database, `tokens/${user.uid}/tt`);
    await update(paramRef, tiktokTokenData);
  };

  const handleViewPublicProfile = async () => {
    if (publicProfileUrl) {
      window.location.href = publicProfileUrl;
    } else {
      toast.error("Oops! Looks like you haven't set a public profile username yet! Go to your settings to set a custom profile username first!");
    }
  };

  const handleSaveChanges = async () => {
    if (!isSelf) { return }

    // Simple validation for firstName and lastName
    if (!firstName || !lastName) {
        toast.error("First and Last names are required!");
        return;
    }

    await setUserData('first_name', firstName);
    await setUserData('last_name', lastName);
    await setUserData('display_name', displayName);
    await setUserData('market', market);
    await setUserData('tags', userTags)
    await setUserData('compensation', {
      'tags': compensationTags,
      'amount': compensationAmount
    });
    await setUserData('bio', bio);
    
    setIsEditing(false);
  };

  const handleHireMe = () => {
    if (businessUid && campaignId) {
      const campaignDbRef = dbRef(database, `campaigns/${businessUid}/${campaignId}`);

      get(campaignDbRef)
        .then((snapshot) => {
          if (!snapshot.exists()) {
            toast.error('Oops! Your campaign was not found. Please visit your campaign dashboard and select a campaign to browse with.');
            return
          }
          const campaign_name = snapshot.val().campaign_name;
          const influencers = snapshot.val().influencers;
          if (influencers && uid in influencers) {
            toast.error(`Oops! ${publicName} is already a part of ${campaign_name}! Visit your campaign dashboard to view the collaboration.`);
            return
          }
          update(child(campaignDbRef, `/influencers/${uid}`), {
            'status': 'new'
          });
          toast.success(`Successfully added ${publicName} to your campaign! Visit the campaign page to fund the collaboration now.`);
        }).catch((error) => {
          toast.error(error.message);
        });
    } else if (businessUid) {
      if (!user?.uid) {
        toast.error("Oops! You need to login first to hire this creator.");
        return
      }
      const campaignData = {
        status: 'created',
        business_id: user.uid,
        campaign_name: `Collab with ${publicName}`,
        campaign_description: `Campaign to collaborate with ${publicName}`,
        campaign_picture: '',
        location: null,
        meet_date: null,
        post_date: null,
        influencers: { [uid]: { status: 'new' } },
        tt_expectations: '1 Tiktok video',
        ig_expectations: '1 video post (reel) and 2-3 stories',
        is_recurring: false,
        targeted_compensation: ['paid'],
        is_comped: null,
        subscription_type: 'standard',
        freebie_comp_description: '',
        creator_details: 'Looking forward to working with you!',
      };
      const campaignId = push(ref(database, `campaigns/${user.uid}`), campaignData).key;
      const redirect_uri = encodeURIComponent(`${window.location.origin}/#/campaign/${user.uid}/${campaignId}`);
      const newWindow = window.open(`${constants.backendDomain}/checkout-influencer?uid=${uid}&business_uid=${user.uid}&campaign_id=${campaignId}&redirect_uri=${redirect_uri}?uids=${uid}`, "_blank");
      if (newWindow) {
        newWindow.opener = null;
      }
    } else {
      navigate(`/hire/${username}`);
    }
  };

  const handleProfilePicClick = () => {
    if (uid !== user?.uid) { return }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImageToStorage = async (file, uid) => {
    if (uid !== user?.uid) { return }
    const profilePicRef = storageRef(storage, `profilePics/${uid}/${file.name}`);
    await uploadBytes(profilePicRef, file);
    const downloadURL = await getDownloadURL(profilePicRef);
    return downloadURL;
  };

  // platform = 'ig' or 'tt', lastRefreshed = iso timestamp from cached data, threshold in hours
  const refreshProfileIfNeeded = (platform, lastRefreshed, threshold) => {
    const lastRefreshedDate = new Date(lastRefreshed);
    const currentDate = new Date();

    if (currentDate - lastRefreshedDate > (threshold * 60 * 60 * 1000)) {
      axios.post(`${constants.backendDomain}/refresh-profile`,
        {
          'id': uid,
          'refresh': platform
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
    }
  };

  const handleTikTokLogin = () => {
    if (!isSelf) { return }
    const newWindow = window.open(`${constants.backendDomain}/auth/tiktok`, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const handleInstagramLogin = () => {
    if (!isSelf) { return }
    const newWindow = window.open(`${constants.backendDomain}/auth/instagram`, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  useEffect(() => {
    if (username) {
      setIsSelf(false);
      const influencerUsernames = dbRef(database, `influencer_usernames/${username}`);
      get(influencerUsernames)
        .then((snapshot) => {
          if (snapshot.exists()) {
            setUid(snapshot.val());
          } else {
            setUid(null);
          }
          setIsLoading(false);
        });
    } else {
      if (user) {
        setUid(user.uid);
        setIsSelf(true);
        setIsLoading(false);
      }
    }
  }, [username]);

  useEffect(() => {
    setTimeout(() => {
      setProfileContainerActive(true);
    }, 400);

    setTimeout(() => {
      setRestOfViewsActive(true);
    }, 600);
  }, []);

  useEffect(() => {
    if (tiktokData && tiktokData.refreshed) {
      refreshProfileIfNeeded('tt', tiktokData.refreshed, 0.5);  // 30 min threshold
    }
    if (tiktokData && tiktokData.recentMedia) {
      const ttAvgLikes = Math.round((tiktokData.userData.likes_count/tiktokData.userData.video_count) * 10) / 10;
      const ttAvgComments = Math.round((Object.values(tiktokData.recentMedia || {}).reduce((comments, post) => comments + post.comment_count, 0)/tiktokData.recentMedia.length) * 10) / 10;
      const ttAvgShares = Math.round((Object.values(tiktokData.recentMedia || {}).reduce((shares, post) => shares + post.share_count, 0)/tiktokData.recentMedia.length) * 10) / 10;
      const ttAvgViews = Math.round((Object.values(tiktokData.recentMedia || {}).reduce((views, post) => views + post.view_count, 0)/tiktokData.recentMedia.length) * 10) / 10;
      setTTStatsData([
        { icon: <FontAwesomeIcon icon={faPeopleGroup} />, label: "FOLLOWERS", value: tiktokData.userData.follower_count },
        { icon: <FontAwesomeIcon icon={faFaceSmile} />, label: "ENGAGEMENT", value: `${Math.round(((ttAvgLikes + ttAvgComments + ttAvgShares) / tiktokData.userData.follower_count * 100) * 10) / 10}%` },
        { icon: <FontAwesomeIcon icon={faVideo} />, label: "TOTAL POSTS", value: tiktokData.userData.video_count },
        { icon: <FontAwesomeIcon icon={faThumbsUp} />, label: "AVG LIKES", value: ttAvgLikes },
        { icon: <FontAwesomeIcon icon={faComment} />, label: "AVG COMMENTS", value: ttAvgComments },
        { icon: <FontAwesomeIcon icon={faEye} />, label: "AVG VIEWS", value: ttAvgViews },
      ]);
    }
  }, [tiktokData]);

  useEffect(() => {
    if (instagramData && instagramData.refreshed) {
      refreshProfileIfNeeded('ig', instagramData.refreshed, 1);  // 1 hour threshold
    }
    if (instagramData && instagramData.data) {
      const igAvgLikes = Math.round((Object.values(instagramData.data.post_meta || {}).reduce((likes, post) => post.like_count > 0 ? likes + post.like_count : likes, 0)/instagramData.data.posts) * 10) / 10;
      const igAvgComments = Math.round((Object.values(instagramData.data.post_meta || {}).reduce((comments, post) => comments + post.comment_count, 0)/instagramData.data.posts) * 10) / 10;
      const data = [
        { icon: <FontAwesomeIcon icon={faPeopleGroup} />, label: "FOLLOWERS", value: instagramData.data.followers },
        { icon: <FontAwesomeIcon icon={faFaceSmile} />, label: "ENGAGEMENT", value: `${Math.round(((igAvgLikes + igAvgComments + (instagramData.data.avg_shares || 0)) / instagramData.data.followers * 100) * 10) / 10}%` },
        { icon: <FontAwesomeIcon icon={faVideo} />, label: "TOTAL POSTS", value: instagramData.data.posts },
      ]
      if (igAvgLikes > 0) {
        data.push({ icon: <FontAwesomeIcon icon={faThumbsUp} />, label: "AVG LIKES", value: igAvgLikes });
      }
      if (igAvgComments > 0) {
        data.push({ icon: <FontAwesomeIcon icon={faComment} />, label: "AVG COMMENTS", value: igAvgComments });
      }
      setIGStatsData(data);
    }
  }, [instagramData]);

  useEffect(() => {
    if (isLoading) { return }
    let subscriptions = [];
    const source = axios.CancelToken.source();

    const interleave = ([ x, ...xs ], ys = []) =>
      x === undefined
        ? ys                             // base: no x
        : [ x, ...interleave (ys, xs) ]  // inductive: some x

    const fetchData = async () => {
      const fetchIGData = async () => {
        const params = new URLSearchParams(location.search);
        if (params.has('ig_access_token') && params.has('expiry') && params.has('user_id')) {
          const igAccessToken = params.get('ig_access_token');
          const igExpiry = params.get('expiry');
          const igUserId = params.get('user_id');

          const userResponse = await axios.get(`https://graph.instagram.com/v12.0/me?fields=username&access_token=${igAccessToken}`, { cancelToken: source.token });
          const userData = userResponse.data;

          const mediaResponse = await axios.get(`https://graph.instagram.com/v12.0/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${igAccessToken}`, { cancelToken: source.token });
          const recentMedia = mediaResponse.data.data;

          const refreshed = new Date().toISOString();

          persistInstagramData({ userData, recentMedia, refreshed });
          persistInstagramToken({
            'ig_access_token': igAccessToken,
            'expiry': igExpiry,
            'user_id': igUserId
          });
          navigate(window.location.hash.split('?')[0].substring(1));
        }
      };
      const fetchTTData = async () => {
        const params = new URLSearchParams(location.search);
        if (params.has('tt_access_token') && params.has('expiry') && params.has('tt_refresh_token') && params.has('refresh_expiry')) {
          const ttAccessToken = params.get('tt_access_token');
          const ttExpiry = params.get('expiry');
          const ttRefreshToken = params.get('tt_refresh_token');
          const ttRefreshExpiry = params.get('refresh_expiry');
          
          const authStr = 'Bearer '.concat(ttAccessToken); 
          const userResponse = await axios.get('https://open.tiktokapis.com/v2/user/info/?fields=display_name,follower_count,likes_count,video_count', { headers: { Authorization: authStr } })
          const userData = userResponse.data.data.user;

          const mediaResponse = await axios.post('https://open.tiktokapis.com/v2/video/list/?fields=id,cover_image_url,height,width,title,embed_link,like_count,comment_count,share_count,view_count', {},
          {
            headers: {
              'Authorization': authStr,
              'Content-Type': 'application/json'
            }
          });
          const recentMedia = mediaResponse.data.data.videos;

          const refreshed = new Date().toISOString();

          persistTikTokData({ userData, recentMedia, refreshed });
          persistTikTokToken({
            'tt_access_token': ttAccessToken,
            'expiry': ttExpiry,
            'tt_refresh_token': ttRefreshToken,
            'refresh_expiry': ttRefreshExpiry
          });
          navigate(window.location.hash.split('?')[0].substring(1));
        }
      };
      if (isSelf) {
        fetchIGData();
        fetchTTData();
        const marketsDbRef = dbRef(database, 'markets')
        const marketsDbUnsubscribe = onValue(marketsDbRef, (snapshot) => {
          setAvailableMarkets(Object.keys(snapshot.val()));
        });
        subscriptions.push({ ref: marketsDbRef, unsubscribe: marketsDbUnsubscribe });
      }
      if (isSelf) {
        const usersDbRef = dbRef(database, `users/${user.uid}`)
        const usersDbUnsubscribe = onValue(usersDbRef, (snapshot) => {
          const user = snapshot.val();
          if (user.first_name) {
            setFirstName(user.first_name);
          } else {
            setFirstName('');
          }
          if (user.last_name) {
            setLastName(user.last_name);
          } else {
            setLastName('');
          }
        });
        subscriptions.push({ ref: usersDbRef, unsubscribe: usersDbUnsubscribe });
      }

      const publicUsersDbRef = dbRef(database, `public_users/${uid}`)
      const publicUsersDbUnsubscribe = onValue(publicUsersDbRef, (snapshot) => {
        const publicName = snapshot.val();
        if (publicName) {
          setPublicName(publicName)
        } else {
          setPublicName('');
        }
      });
      subscriptions.push({ ref: publicUsersDbRef, unsubscribe: publicUsersDbUnsubscribe });

      const influencersDbRef = dbRef(database, `influencers/${uid}`)
      const influencersDbUnsubscribe = onValue(influencersDbRef, (snapshot) => {
        const influencer = snapshot.val();
        if (!influencer) {
          return
        }
        if (influencer.display_name) {
          setDisplayName(influencer.display_name);
        } else {
          setDisplayName('');
        }
        if (influencer.market) {
          setMarket(influencer.market);
        } else {
          setMarket('');
        }
        if (influencer.username) {
          setPublicProfileUrl(`https://app.pobbles.co/#/user/${influencer.username}`);
        } else {
          if (isSelf) {
            setShouldShowUsernameModal(true);
          }
        }
        if (influencer.is_pobbles_pro) {
          setIsPobblesPro(true);
        } else {
          setIsPobblesPro(false);
        }
        if (influencer.tags) {
          setUserTags(influencer.tags);
        } else {
          setUserTags([]);
        }
        if (influencer.compensation) {
          if (influencer.compensation.tags) {
            setCompensationTags(influencer.compensation.tags);
          } else {
            setCompensationTags([]);
          }
          if (influencer.compensation.amount) {
            setCompensationAmount(influencer.compensation.amount);
          } else {
            setCompensationAmount(null);
          }
        }
        if (influencer.bio) {
          setBio(influencer.bio);
        } else {
          setBio('');
        }
        if (influencer.profile_pic_url) {
          setProfilePic(influencer.profile_pic_url)
        } else {
          setProfilePic('../images/pfp.png');
        }
        var instagramMediaItems = []
        setReach(ReachCalculator(influencer.cached, true));
        if (influencer.cached && influencer.cached.ig) {
          setIGData(influencer.cached.ig)
          instagramMediaItems = influencer.cached.ig.recentMedia.map((media, index) => {
            if (influencer.cached.ig.data && influencer.cached.ig.data.post_meta) {
              const parts = media.permalink.split("/");
              const shortcode = parts[parts.length - 2];
              const meta = influencer.cached.ig.data.post_meta[shortcode];
              const enhancedMedia = {
                ...media,
                "likes": meta ? meta.like_count : null,
                "comments": meta ? meta.comment_count : null,
              };
              return <MediaItem key={media.id} media={enhancedMedia} />
            } else {
              return <MediaItem key={media.id} media={media} />
            }
          });
        } else {
          setIGData(null);
        }
        var tiktokMediaItems = []
        if (influencer.cached?.tt?.recentMedia) {
          setTTData(influencer.cached.tt)
          tiktokMediaItems = influencer.cached.tt.recentMedia.map((media, index) => (
            <MediaItem key={media.id} media={{
              "caption": media.title,
              "media_type": "TIKTOK",
              "media_url": media.embed_link,
              "media_frame_ratio": (media.height !== 0 && media.width !== 0) ? media.height/media.width : 0,
              "thumbnail_url": media.cover_image_url,
              "likes": media.like_count !== 0 ? media.like_count : null,
              "comments": media.comment_count !== 0 ? media.comment_count : null,
              "shares": media.share_count !== 0 ? media.share_count : null,
              "views": media.view_count !== 0 ? media.view_count : null
            }} />
          ));
        } else {
          setTTData(null);
        }
        if (influencer.cached && influencer.cached.yelp) {
          setYelpData(influencer.cached.yelp);
        } else {
          setYelpData(null);
        }
        setMediaItemsDataSource(interleave(instagramMediaItems, tiktokMediaItems).slice(0,30));
      });
      subscriptions.push({ ref: influencersDbRef, unsubscribe: influencersDbUnsubscribe });

      // const reviewsDbRef = dbRef(database, `reviews/${uid}`);
      // const reviewsDbUnsubscribe = onValue(reviewsDbRef, (snapshot) => {
      //   setReviewsData(snapshot.val());
      // });
      // subscriptions.push({ ref: reviewsDbRef, unsubscribe: reviewsDbUnsubscribe });

      if (campaignId) {
        const campaignsDbRef = dbRef(database, `campaigns/${businessUid}/${campaignId}`);
        const campaignsDbUnsubscribe = onValue(campaignsDbRef, (snapshot) => {
          setCampaignData(snapshot.val());
        });
        subscriptions.push({ ref: campaignsDbRef, unsubscribe: campaignsDbUnsubscribe });
      }
    };
    fetchData();
    return () => {
      source.cancel();
      for (let { ref, unsubscribe } of subscriptions) {
        off(ref, 'value', unsubscribe);
      }
      subscriptions = []
    };
  }, [navigate, user, location, isLoading, isSelf, uid, businessUid, campaignId]);

  if (isLoading) {
    return (<></>);
  }

  if (!uid) {
    // Create a better empty page.
    return (<div className="content-container">Oops. User does not exist.</div>);
  }

  return (
    <div className="content-container">
      <UsernameModal props={{ shouldShow: shouldShowUsernameModal && isSelf }} />
      <div className={`profile-container fadeIn ${profileContainerActive ? 'active' : ''}`}>
        <div className="header-section">
          <div className="profile-section">
            <div className="profilePic-container" onClick={handleProfilePicClick}>
              <img src={profilePic} alt="Profile" className="profilePic" />
              {isSelf &&
                <>
                  <div className="edit-overlay">
                    <FontAwesomeIcon icon={faEdit} />
                  </div>
                  <input
                    type="file"
                    id="profilePicInput"
                    ref={fileInputRef}
                    onChange={async (e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const downloadURL = await uploadImageToStorage(file, uid);
                        setProfilePic(downloadURL);
                        await setUserData('profile_pic_url', downloadURL);
                      }
                    }}
                    style={{ display: 'none' }}
                  />
                </>
              }
            </div>
            {isEditing
              ? // Edit Mode
                <div className="edit-fields">
                  <div className="edit-field">
                      <label htmlFor="firstName">First Name:</label>
                      <input
                        id="firstName"
                        value={firstName}
                        placeholder="First Name"
                        className="input-field"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                  </div>
                  <div className="edit-field">
                      <label htmlFor="lastName">Last Name:</label>
                      <input
                        id="lastName"
                        value={lastName}
                        placeholder="Last Name"
                        className="input-field"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                  </div>
                  <div className="edit-field">
                    <span
                      className="hover-container"
                      onMouseEnter={() => setIsHoveringDisplayName(true)}
                      onMouseLeave={() => setIsHoveringDisplayName(false)}
                    >
                      <label htmlFor="displayName">Display Name:</label>
                      {isHoveringDisplayName && <div className="hover-text">Commonly known by a unique name or handle on other platforms? List it here!</div>}
                    </span>
                    <input
                      id="displayName"
                      value={displayName}
                      placeholder="Nickname"
                      className="input-field"
                      onChange={(e) => setDisplayName(e.target.value)}
                    />
                  </div>
                  <div className="edit-field">
                    <label htmlFor="market">Location:</label>
                    <select
                      name="market"
                      value={market}
                      onChange={(e) => setMarket(e.target.value)}
                      required
                    >
                      <option value="" disabled>Select your location</option>
                      {availableMarkets.map((market, index) => (
                        <option key={index} value={market}>{market}</option>
                      ))}
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="edit-field">
                    <span
                      className="hover-container"
                      onMouseEnter={() => setIsHoveringContentType(true)}
                      onMouseLeave={() => setIsHoveringContentType(false)}
                    >
                      <label htmlFor="tag-container">Content Type:</label>
                      {isHoveringContentType && <div className="hover-text">This helps find the right collab opportunities that match your content and audience.</div>}
                    </span>
                    <div className="tag-container">
                      {constants.availableContentTags.map((tag, index) => (
                        <div
                          key={index}
                          className={`tag ${userTags.includes(tag.name) ? 'selected' : ''}`}
                          onClick={() => toggleTag(tag.name)}
                        >
                          {tag.name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="edit-field">
                    <label htmlFor="tag-container">Compensation:</label>
                    <div className="tag-container" style={{ justifyContent: 'right' }}>
                      {constants.availableCompensations.map((tag, index) => (
                        <div
                          key={index}
                          className={`tag ${compensationTags.includes(tag.name) ? 'selected' : ''}`}
                          onClick={() => toggleCompensation(tag.name)}
                        >
                          {tag.description}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="edit-field">
                    <span
                      className="hover-container"
                      onMouseEnter={() => setIsHoveringRate(true)}
                      onMouseLeave={() => setIsHoveringRate(false)}
                    >
                      <label htmlFor="compensationAmount">Rate per post ($):</label>
                      {isHoveringRate && <div className="hover-text">This helps potential inquiries see what your expected comp is and whether it meets their budget.</div>}
                    </span>
                    <input
                      id="compensationAmount"
                      type="number"
                      min="0"
                      max="1000000"
                      step="1"
                      value={compensationAmount}
                      placeholder="50"
                      className="input-field"
                      onChange={(e) => setCompensationAmount(e.target.value)}
                    />
                  </div>
                </div>
              : // Display Mode
                <div>
                  <div className="name-container">
                    <div className="name">
                      {isSelf ? `${firstName} ${lastName}` : `${publicName}.`}
                    </div>
                    {displayName && <div className="display-name">{`(${displayName})`}</div>}
                    {market && <div className="market">{market}</div>}
                  </div>
                  {userTags && constants.availableContentTags.sort().map((tag, index) =>
                    userTags.includes(tag.name) &&
                    <div
                      key={tag.name}
                      className="badge-container"
                      style={{
                        backgroundColor: tag.color,
                        margin: '2px'
                      }}
                    >
                      <FontAwesomeIcon icon={tag.icon} className="badge-icon"/>
                      <span className="badge-text">{tag.name}</span>
                    </div>
                  )}
                </div>
                }
          </div>
          {!isEditing &&
            <>
              <div className="big-data-and-badges-container">
                <div className="big-data-item">
                  <span
                    className="big-data-label hover-container"
                    onMouseEnter={() => setIsHoveringReach(true)}
                    onMouseLeave={() => setIsHoveringReach(false)}
                  >
                    Est. Reach:
                    {isHoveringReach && <div className="hover-text">This is Pobbles' best estimate of {isSelf ? `${firstName}` : `${publicName}`}'s audience size, calculated from the follower sizes and engagement levels on each platform.</div>}
                  </span>
                  <span className="big-data-number" style={{ fontWeight: 'bold' }}>{reach}</span><span className="big-data-number" style={{ fontSize: '20px' }}>views</span>
                </div>
                <BadgesContainer
                  instagramData={instagramData}
                  tiktokData={tiktokData}
                  yelpData={yelpData}
                  isPobblesPro={isPobblesPro}
                />
              </div>
              <div className="social-container">
                {isSelf &&
                  <div>
                    Platform Connections:
                    <br />
                    <i style={{ fontSize: '10px' }}>We never store or access any password data</i>
                  </div>
                }
                {yelpData && yelpData.data && yelpData.data.eliteYears && <YelpModal props={{'yelpData': yelpData, 'isEditable': isSelf}} />}
                {instagramData &&
                  <div className="social-button-detail-container">
                    <button className={`social-button ${instagramData ? (instagramData.data ? 'active' : 'pending') : 'inactive' }`} onClick={handleInstagramLogin}><FontAwesomeIcon icon={faInstagram} /></button>
                    
                    {instagramData.data && instagramData.data.followers
                      ? <div className="data-item">
                          <span className="data-number">{NumberFormatter(instagramData.data.followers)}</span>
                          <span className="data-label">followers{isSelf && instagramData.userData?.username && <i> ({instagramData.userData?.username})</i>}</span>
                        </div>
                      : <div className="social-detail"><p>{isSelf && instagramData.userData?.username && <>[{instagramData.userData?.username}] </>}Pending Verification</p></div>
                    }
                  </div>
                }
                {tiktokData &&
                  <div className="social-button-detail-container">
                    <button className={`social-button ${tiktokData ? 'active' : 'inactive' }`} onClick={handleTikTokLogin}><FontAwesomeIcon icon={faTiktok} /></button>
                    {tiktokData.userData && tiktokData.userData.follower_count
                      ? <div className="data-item">
                          <span className="data-number">{NumberFormatter(tiktokData.userData.follower_count)}</span>
                          <span className="data-label">followers</span>
                        </div>
                      : <div className="social-detail"><p>Pending Verification</p></div>
                    }
                  </div>
                }
                {yelpData && (!yelpData.data || !yelpData.data.eliteYears) && <YelpModal props={{'yelpData': yelpData, 'isEditable': isSelf}} />}
                {!tiktokData && isSelf &&
                  <div className="social-button-detail-container">
                    <button className={`social-button ${tiktokData ? 'active' : 'inactive' }`} onClick={handleTikTokLogin}><FontAwesomeIcon icon={faTiktok} /></button>
                    <div className="social-detail"><p>Link your TikTok</p></div>
                  </div>
                }
                {!instagramData && isSelf && 
                  <div className="social-button-detail-container">
                    <button className={`social-button ${instagramData ? (instagramData.data ? 'active' : 'pending') : 'inactive' }`} onClick={handleInstagramLogin}><FontAwesomeIcon icon={faInstagram} /></button>
                    <div className="social-detail"><p>Link your Instagram</p></div>
                  </div>
                }
                {!yelpData && isSelf && <YelpModal props={{'yelpData': yelpData, 'isEditable': isSelf}} />}
              </div>
            </>
          }
          {isSelf
            ? isEditing
              ? <div className="edit-profile-button-container">
                  <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleSaveChanges}>Save Changes</button>
                  <button className="btn diamond-btn diamond-btn--sm" onClick={() => setIsEditing(!isEditing)}>Cancel</button>
                </div>
              : <div className="edit-profile-button-container">
                  <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleViewPublicProfile}>Public Profile</button>
                  <button className="btn diamond-btn diamond-btn--sm" onClick={() => setIsEditing(!isEditing)}>Edit</button>
                </div>
            : businessUid && campaignId && campaignData
              ? <div className="edit-profile-button-container">
                  {!Object.keys(campaignData.influencers || []).includes(uid) &&
                    <button className="btn diamond-btn diamond-btn--yellow" onClick={handleHireMe}>Add to Campaign</button>
                  }
                </div>
              : <div className="edit-profile-button-container">
                  <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleHireMe}>Hire Me!</button>
                </div>
          }
        </div>
      </div>
      <div className={`content-section fadeIn ${restOfViewsActive ? 'active' : ''}`}>
        {isEditing
          ? // Edit Mode
            <div className="large-edit-fields">
              <div className="edit-field">
                <label htmlFor="bio">Bio:</label>
                <textarea
                  id="bio"
                  value={bio}
                  placeholder="Bio / Description"
                  className="input-field textarea-field"
                  onChange={(e) => setBio(e.target.value)}
                />
              </div>
            </div>
          : // Display Mode
            <>
              {bio && <div className="bio-section bio">{bio}</div>}
              <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
                <div 
                  style={{ padding: '10px 20px', cursor: 'pointer', borderBottom: tabValue === 0 ? '2px solid blue' : 'none' }}
                  onClick={() => handleChangeTab(0)}
                >
                  Recent Content
                </div>
                <div 
                  style={{ padding: '10px 20px', cursor: 'pointer', borderBottom: tabValue === 1 ? '2px solid blue' : 'none' }}
                  onClick={() => handleChangeTab(1)}
                >
                  Performance & Data
                </div>
                {/*reviewsData && 
                  <div 
                    style={{ padding: '10px 20px', cursor: 'pointer', borderBottom: tabValue === 2 ? '2px solid blue' : 'none' }}
                    onClick={() => handleChangeTab(2)}
                  >
                    Reviews
                  </div>
                */}
              </div>
              {mediaItemsDataSource.length !== 0
                ? <>
                    {tabValue === 0 &&
                      // Recent Content Tab
                      <Masonry
                        breakpointCols={{
                          default: 5,
                          1400: 3,
                          600: 1
                        }}
                        className="media-grid"
                        columnClassName="media-grid_column"
                      >
                        {mediaItemsDataSource}
                      </Masonry>
                    }

                    {tabValue === 1 &&
                      // Performance & Data Tab
                      <div style={{ display: 'flex', padding: '20px' }}>
                        {instagramData &&
                          <StatsCard
                            title={<div><FontAwesomeIcon icon={faInstagram} /> Instagram</div>}
                            noticeText={(igStatsData?.[1] && parseFloat(igStatsData[1].value) > igEngagementThreshold) ? <div><FontAwesomeIcon icon={faCircleInfo} /> <strong>{publicName}</strong>'s engagement rate ({parseFloat(igStatsData[1].value)}%) is higher than the typical rate for Instagram ({igEngagementThreshold}%)</div> : null}
                            refreshDate={new Date(instagramData.refreshed).toLocaleDateString()}
                            statCardItems={igStatsData}
                          />
                        }
                        {tiktokData &&
                          <StatsCard
                            title={<div><FontAwesomeIcon icon={faTiktok} /> TikTok</div>}
                            noticeText={(ttStatsData?.[1] && parseFloat(ttStatsData[1].value) > ttEngagementThreshold) ? <div><FontAwesomeIcon icon={faCircleInfo} /> <strong>{publicName}</strong>'s engagement rate ({parseFloat(ttStatsData[1].value)}%) is higher than the typical rate for TikTok ({ttEngagementThreshold}%)</div> : null}
                            refreshDate={new Date(tiktokData.refreshed).toLocaleDateString()}
                            statCardItems={ttStatsData}
                          />
                        }
                      </div>
                    }

                    {tabValue === 2 &&
                      // Reviews Tab
                      <></>
                    }
                  </>
                // TODO: add copy and image getting users to link their profiles
                : <></>
              }
            </>
        }
      </div>
    </div>
  );
}

export default Profile;
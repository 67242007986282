import React from 'react';
import { NumberFormatter } from '../lib/NumberFormatter.jsx';
import '../css/web.css';

const StatsCard = ({ title, noticeText, refreshDate, statCardItems }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', margin: '20px' }}>
      {noticeText && 
        <div className="stat-card-green-bar">
          <span className="stat-card-green-bar-text">{noticeText}</span>
        </div>
      }
      <div className="stat-card-container">
        <section className="stat-card-header">
          <span className="stat-card-header-title">{title}</span>
          <span className="stat-card-header-subtitle">Last Refreshed: {refreshDate}</span>
        </section>
        <section className="stat-card-body">
          {statCardItems.map((item) => (
            <div className="stat-card-item" key={item.label + item.value}>
              <span className="stat-card-item-label">{item.icon}</span>
              <span className="stat-card-item-label">{item.label}</span>
              <span className="stat-card-item-value">{NumberFormatter(item.value)}</span>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

export default StatsCard;

import { TrendingCalculator } from '../lib/ReachCalculator.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYelp } from '@fortawesome/free-brands-svg-icons';
import { faCircleCheck, faFire } from '@fortawesome/free-solid-svg-icons';

function BadgesContainer({ instagramData, tiktokData, yelpData, isPobblesPro }) {

  return (
    <div className="badges-container">
      { isPobblesPro &&
        <div className="badge-container" style={{backgroundColor: '#edbb11'}} data-testid="pobbles-pro-badge">
          <FontAwesomeIcon icon={faCircleCheck} className="badge-icon"/>
          <span className="badge-text">Pobbles Pro</span>
        </div>
      }
      { TrendingCalculator(instagramData, tiktokData) &&
        <div className="badge-container" style={{backgroundColor: 'red'}} data-testid="trending-badge">
          <FontAwesomeIcon icon={faFire} className="badge-icon"/>
          <span className="badge-text">Trending</span>
        </div>
      }
      {yelpData && yelpData.data && yelpData.data.eliteYears &&
        <div className="badge-container" style={{backgroundColor: '#c41200'}} data-testid="yelp-elite-badge">
          <FontAwesomeIcon icon={faYelp} className="badge-icon"/>
          <span className="badge-text">Yelp Elite</span>
        </div>
      }
    </div>
  );
}

export default BadgesContainer;

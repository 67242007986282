import React, { useEffect } from 'react';
import { auth } from '../lib/firebase';
import { sendSignInLinkToEmail } from "firebase/auth";
import { toast } from 'react-toastify';

function VerifyEmail(props) {
  const emailForSignIn = props.email;
  const routeToWelcome = props.routeToWelcome;

  useEffect(() => {
    const actionCodeSettings = {
      url: `https://app.pobbles.co/#/verify-email?redirect=${routeToWelcome ? 'welcome' : 'campaigns'}`,
      handleCodeInApp: true
    };
    sendSignInLinkToEmail(auth, emailForSignIn, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('type', 'business');
        window.localStorage.setItem('emailForSignIn', emailForSignIn);
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        }
      });
  }, [emailForSignIn, routeToWelcome]);

  return (
    <div className="form-container">
      <h2>Email verification</h2>
      <p style={{ fontSize: '1.2em' }}>Please click on the link in the verification email sent to <b>{props.email}</b> to proceed</p>
    </div>
  );
}

export default VerifyEmail;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { database } from './lib/firebase';
import { ref, onValue, get, off } from "firebase/database";
import { ReachCalculator } from './lib/ReachCalculator.jsx';
import CampaignCardDisplay from './components/campaignCardDisplay';
import './css/web.css';

const Campaigns = ({user, type}) => {
  const [balance, setBalance] = useState(0);

  const [allData, setAllData] = useState([]);
  const [shownData, setShownData] = useState([]);

  const [allInfluencers, setAllInfluencers] = useState([]);
  const [allBusinesses, setAllBusinesses] = useState({});

  const [isHoveringBalance, setIsHoveringBalance] = useState(false);

  const navigate = useNavigate();

  const handleCampaignClick = ((uid, id) => {
    if (uid && id) {
      navigate(`/campaign/${uid}/${id}`);
    } else {
      navigate("/campaign/create");
    }
  });

  useEffect(() => {
    let subscriptions = [];
    const fetchData = async (uid) => {
      let campaignsData = {}
      let campaignsUidData = {}
      let associatedBusinessesCampaignsData = {}
      const updateDatasource = () => {
        setAllData({
          ...campaignsData,
          ...campaignsUidData,
          ...associatedBusinessesCampaignsData,
        });
      }

      const campaignsDbRef = ref(database, `campaigns`);
      const campaignsDbUnsubscribe = onValue(campaignsDbRef, (snapshot) => {
        const allCampaigns = snapshot.val();
        let relevantCampaigns = {};
        Object.values(allCampaigns).forEach((campaigns) => {
          Object.entries(campaigns).forEach(([id, campaign]) => {
            if (campaign.influencers && Object.keys(campaign.influencers).includes(uid) && campaign.influencers[uid].status !== 'new') {
              relevantCampaigns[id] = campaign;
            }
          });
        });
        campaignsData = relevantCampaigns;
        campaignsUidData = allCampaigns[uid];
        updateDatasource();
      });
      subscriptions.push({ ref: campaignsDbRef, unsubscribe: campaignsDbUnsubscribe });

      if (type === 'business') {
        const balancesDbRef = ref(database, `balances/${uid}`);
        const balancesDbUnsubscribe = onValue(balancesDbRef, (snapshot) => {
          const balance = snapshot.val();
          if (balance) {
            setBalance(balance);
          }
        });
        subscriptions.push({ ref: balancesDbRef, unsubscribe: balancesDbUnsubscribe });
      }

      get(ref(database, 'influencers')).then((snapshot) => {
        const datasource = Object.entries(snapshot.val())
          .map(([uid, userData], index) => (
            {
              uid: uid,
              reach: ReachCalculator(userData.cached, false),
            }
          ));
        setAllInfluencers(datasource);
      });

      get(ref(database, 'businesses')).then((snapshot) => {
        const businessesData = snapshot.val();
        setAllBusinesses(businessesData);
        if (businessesData[uid]?.associated_businesses) {
          const associatedBusinesses = businessesData[uid].associated_businesses;
          associatedBusinesses.forEach((uid) => {
            const associatedBusinessesCampaignsDbRef = ref(database, `campaigns/${uid}`);
            const associatedBusinessesCampaignsDbUnsubscribe = onValue(associatedBusinessesCampaignsDbRef, (snapshot) => {
              const associatedBusinessesCampaigns = snapshot.val();
              associatedBusinessesCampaignsData = {
                ...associatedBusinessesCampaignsData,
                ...associatedBusinessesCampaigns,
              };
              updateDatasource();
            });
            subscriptions.push({ ref: associatedBusinessesCampaignsDbRef, unsubscribe: associatedBusinessesCampaignsDbUnsubscribe });
          });
        }
      });
    };
    if (user) {
      fetchData(user.uid);
    }
    return () => {
      for (let { ref, unsubscribe } of subscriptions) {
        off(ref, 'value', unsubscribe);
      }
      subscriptions = []
    };
  }, [user, type]);

  useEffect(() => {
    if (allData) {
      setShownData(Object.entries(allData));
    }
  }, [allData]);

  useEffect(() => {
    const cleanUrl = () => {
      let url = new URL(window.location.href);
      url.search = '';
      const cleanedUrl = url.origin + url.hash;
      window.history.replaceState({}, document.title, cleanedUrl);
    }
    cleanUrl();
  }, []);

  return (
    <div className="content-container" style={{ alignItems: 'center' }}>
      {balance !== 0 &&
        <div style={{ backgroundColor: '#ffecb3', textAlign: 'center', padding: '10px', position: 'relative', width: '100%' }}>
          <span
            className="hover-container"
            onMouseEnter={() => setIsHoveringBalance(true)}
            onMouseLeave={() => setIsHoveringBalance(false)}
          >
            <b>Available creator credit:</b>
            {isHoveringBalance && <div className="hover-text">This credit will automatically be applied when you invite creators in campaigns.</div>}
          </span>
          <span className={balance > 0 ? 'good-color' : 'error-color'} style={{ fontSize: '1.1em' }}> ${balance.toLocaleString()}</span>
        </div>
      }
      {shownData && shownData.length === 0 &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', paddingTop: '40px', maxWidth: '500px' }}>
          <img src='../images/cta-image-2.png' alt="No Campaigns CTA" style={{ padding: '20px', width: '250px', height: '250px', objectFit: 'cover' }}/>
          <span>
            Welcome to Pobbles! You don't have any campaigns yet.<br/>{type === 'business' && <>Create your first campaign now!</>}
          </span>
        </div>
      }
      <CampaignCardDisplay
        campaigns={shownData}
        influencerData={allInfluencers}
        businessData={allBusinesses}
        shouldShowCreate={type === 'business'}
        type={type}
        influencerUid={type === 'influencer' ? user?.uid : null}
        onClick={handleCampaignClick}
      />
    </div>
  );
}

export default Campaigns;

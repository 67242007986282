import React, { useState } from 'react';
import { NumberFormatter } from '../lib/NumberFormatter.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faThumbsUp, faComment, faShare, faEye } from '@fortawesome/free-solid-svg-icons';
import '../css/web.css';

function MediaItem({ media }) {
  const [isPlaying, setIsPlaying] = useState(false);

  const tiktokBackground = 'linear-gradient(to top, #010101, #ee1d52, #69c9d0)';
  const instagramBackground = 'linear-gradient(to right, #515bd4, #8134af, #dd2a7b, #f58529, #feda77 )';

  return (
    <div className="media-container" style={{ background: (media.media_type === "TIKTOK" ? tiktokBackground : instagramBackground) }}>
      <div className="media-content">
        {media.media_type === "TIKTOK" && (!isPlaying || media.media_frame_ratio === 0) &&
          <img
            src={media.thumbnail_url}
            alt={media.caption}
            onClick={() => setIsPlaying(true)}
            onMouseEnter={() => setIsPlaying(true)}
          />
        }

        {media.media_type === "TIKTOK" && (isPlaying && media.media_frame_ratio !== 0) &&
          <div
            style={{
              'position': 'relative',
              'paddingTop': `${media.media_frame_ratio*100}%`,
              'width': '100%',
              'overflow': 'hidden',
            }}>
            <iframe
              title={media.caption}
              src={media.media_url}
              style={{
                'position': 'absolute',
                'top': 0,
                'left': 0,
                'width': '100%',
                'height': '100%'
              }}
              scrolling="no"
              allow="encrypted-media;"
              frameBorder="0"
            />
          </div>
        }

        {media.media_type === "IMAGE" && <img src={media.media_url} alt={media.caption || 'Instagram Media'} />}
        
        {media.media_type === "VIDEO" && <video className="media-video" src={media.media_url} poster={media.thumbnail_url} autoPlay loop muted playsInline style={{ pointerEvents: "none" }}></video>}
        
        {media.media_type === "CAROUSEL_ALBUM" && <img src={media.media_url} alt={media.caption || 'Instagram Media'} />}
        
        {media.caption && <div className="media-caption">{media.caption}</div>}
      </div>
      <div className="media-data">
        <div>{media.media_type === "TIKTOK" ? <FontAwesomeIcon icon={faTiktok} color='white' /> : <FontAwesomeIcon icon={faInstagram} color='white' />}</div>
        <div style={{ color: 'white' }}>
          {media.likes !== null && media.likes > 0 && <><FontAwesomeIcon icon={faThumbsUp} color='white' /> {NumberFormatter(media.likes)} </>}
          {media.comments !== null && media.comments > 0 && <><FontAwesomeIcon icon={faComment} color='white' /> {NumberFormatter(media.comments)} </>}
          {media.shares !== null && media.shares > 0 && <><FontAwesomeIcon icon={faShare} color='white' /> {NumberFormatter(media.shares)} </>}
          {media.views !== null && media.views > 0 && <><FontAwesomeIcon icon={faEye} color='white' /> {NumberFormatter(media.views)} </>}
        </div>
      </div>
    </div>
  );
}

export default MediaItem;
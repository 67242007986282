import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { database } from './lib/firebase';
import { ref, get } from "firebase/database";
import ConfettiAnimation from './components/confetti';
import './css/web.css';

const HireSuccess = () => {
  const navigate = useNavigate();

  const { username } = useParams();
  const [publicName, setPublicName] = useState('');
  const [influencerData, setInfluencerData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const fetchData = (uid) => {
      const publicUsersDbRef = ref(database, `public_users/${uid}`)
      get(publicUsersDbRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            setPublicName(snapshot.val());
          } else {
            setPublicName('');
          }
        });

      const influencersDbRef = ref(database, `influencers/${uid}`)
      get(influencersDbRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            setInfluencerData(snapshot.val());
          }
        });
    };

    if (username) {
      const influencerUsernamesDbRef = ref(database, `influencer_usernames/${username}`);
      get(influencerUsernamesDbRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const uid = snapshot.val();
            setUid(uid);
            fetchData(uid);
          }
          setIsLoading(false);
        });
    }
    // return auth.onAuthStateChanged(user => {
    //   if (user?.uid) {
    //     // TODO: show user as logged in
    //   }
    // });
  }, [navigate, username]);

  if (!isLoading && !uid) {
    return (
      <div className="content-container" style={{ paddingTop: '100px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src='../images/cta-image-3.png' alt="No Creator Found" style={{ padding: '20px', height: '250px', objectFit: 'cover' }}/>
          Oops! This creator does not exist.
        </div>
      </div>
    );
  }

  return (
    <div className="content-container bg-beige" style={{ height: '100dvh' }}>
      <div className="form-container">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '20px 0px', gap: '20px' }}>
          {influencerData?.profile_pic_url && 
            <div className="profilePic-container" style={{ cursor: 'unset' }}>
              <img src={influencerData.profile_pic_url} alt="Profile" className="profilePic" />
            </div>
          }
          <h2>Payment to {publicName} Confirmed!</h2>
          <b>Protected Payments:</b> Your money is secure and will be released to the creator only after your approval.
          <b>Next Steps:</b> Check your email for instructions to track and release your payment.
          <br/><br/><br/>
          <i>Need help? Contact us anytime at <a href="mailto:hello@pobbles.co">hello@pobbles.co</a> for live support.</i>
        </div>
      </div>
      <ConfettiAnimation />
    </div>
  );
}

export default HireSuccess;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { amplitude } from './lib/amplitude.jsx';
import { database } from './lib/firebase';
import { ref, get, onValue, off } from "firebase/database";
import queryString from 'query-string';
import { parse } from 'best-effort-json-parser';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MainContainer, ChatContainer, ConversationHeader, MessageList, MessageGroup, Message, MessageInput, Avatar, TypingIndicator } from "@chatscope/chat-ui-kit-react";
import { ReachCalculator } from './lib/ReachCalculator.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faEye, faMoneyBill, faX } from '@fortawesome/free-solid-svg-icons';
import * as constants from './constants.jsx';
import './css/web.css';

const Welcome = ({user}) => {
  const navigate = useNavigate();

  const [datasource, setDatasource] = useState(null);

  const [messageGroups, setMessageGroups] = useState([]);

  const [businessData, setBusinessData] = useState({
    business_name: '',
    business_description: '',
    market: '',
  });

  const [businessDescription, setBusinessDescription] = useState(null);
  const [excludedUids, setExcludedUids] = useState([]);

  const [allInfluencers, setAllInfluencers] = useState({});
  const [publicUsers, setPublicUsers] = useState([]);

  const [welcomeBusinessTitleActive, setWelcomeBusinessTitleActive] = useState(false);
  const [welcomeBusinessSubtitleActive, setWelcomeBusinessSubtitleActive] = useState(false);
  const [recommendationsActive, setRecommendationsActive] = useState(false);
  const [bottomCTAViewActive, setBottomCTAViewActive] = useState(false);

  const [numCardsShown, setNumCardsShown] = useState(0);

  const welcomeSplashUrl = '../images/welcome-splash.png';
  const defaultProfilePicUrl = '../images/default-girl-pfp.png';
  const pobProfilePicUrl = '../images/default-faqbot-pfp.png';

  const [isTyping, setIsTyping] = useState(false);

  const sendNextAIMessage = async (businessMessage) => {
    const sendMessage = (message) => {
      setMessageGroups(groups => {
        let newGroups = [...groups];
        let lastGroup = newGroups[newGroups.length - 1];
        
        if (lastGroup && lastGroup.sender === "Pob - Pobbles AI") {
          lastGroup.messages.push({ message, direction: "incoming" });
        } else {
          newGroups.push({
            sender: "Pob - Pobbles AI",
            messages: [{ message, direction: "incoming" }],
          });
        }
        return newGroups;
      });
    };
    const updateLastMessage = (message) => {
      setMessageGroups(groups => {
        let newGroups = [...groups];
        let lastGroup = newGroups[newGroups.length - 1];
        lastGroup.messages[lastGroup.messages.length - 1].message = message;
        return newGroups;
      });
    };
    async function sendMessageAsAI(message) {
      const words = message.split(" ");
      let index = 0;
      let string = '';
      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (index < words.length) {
            string += words[index];
            string += ' ';
            if (index === 0) {
              sendMessage(string);
            } else {
              updateLastMessage(string);
            }
            index++;
          } else {
            clearInterval(interval);
            resolve();
          }
        }, 50);
      })
    };
    const fetchChatMessage = async (businessMessage) => {
      if (businessData.business_name && businessData.market && businessMessage) {
        try {
          setIsTyping(true);
          let responseString = '';
          const response = await fetch(`${constants.backendDomain}/welcome/chat`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: queryString.stringify({
              'business_name': businessData.business_name,
              'market': businessData.market,
              'existing_recommendations': datasource ? JSON.stringify(datasource) : null,
              'existing_description': businessDescription,
              'excluded_uids': excludedUids,
              'message': businessMessage,
            }),
          });
          const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();
          let didStartMessage = false;
          while(true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            responseString += value;
            const jsonResponse = parse(responseString);
            if (jsonResponse) {
              if (jsonResponse.message) {
                if (didStartMessage) {
                  updateLastMessage(jsonResponse.message);
                } else {
                  sendMessage(jsonResponse.message);
                  didStartMessage = true;
                }
              }
              if (!datasource?.length && jsonResponse.description) {
                setBusinessDescription(jsonResponse.description);
                setWelcomeBusinessSubtitleActive(true);
              }
              if (jsonResponse.recommendations) {
                setDatasource(jsonResponse.recommendations);
                setNumCardsShown(jsonResponse.recommendations.filter((creator) => creator.reason).length - 1);
                setRecommendationsActive(true);
              }
            }
          };
          const finalJson = JSON.parse(responseString);
          if (finalJson.recommendations.length > 0 && !bottomCTAViewActive) {
            const firstChatAppendedMessages = [
              'Feel free to take a look and give me any feedback. If you click the cards, you’ll be able to see the creator’s work in more detail. If you don’t think a creator is a good fit, simply X them out.',
              'You can also browse more local creators and complete the process without my help by clicking the “Create your first campaign” button at the bottom at any time.'
            ];
            for (const message of firstChatAppendedMessages) {
              await sendMessageAsAI(message);
            }
            setBottomCTAViewActive(true);
          }
          amplitude.track('Welcome Chat Response', finalJson);
        } catch {
          setBottomCTAViewActive(true);
          sendMessage("Sorry! Seems like I'm having some issues right now... You can always manually create a campaign by clicking the 'Create your first campaign' button at the bottom.");
        } finally {
          setIsTyping(false);
        }
      }
    };

    if (businessMessage) {
      fetchChatMessage(businessMessage);
    } else {
      const welcomeMessages = [
        "Hey there! I'm Pob - your personal AI assistant to help you get started on Pobbles!",
        "In a few words, please tell me a little bit about your business."
      ];
      setIsTyping(true);
      for (const message of welcomeMessages) {
        await sendMessageAsAI(message);
      }
      setIsTyping(false);
    }
  };

  const handleSendMessage = (innerHtml, textContent, innerText, nodes) => {
    setMessageGroups(groups => {
      const eventProperties = {
        message: textContent,
      };
      amplitude.track('Welcome Chat Message', eventProperties);
      sendNextAIMessage(textContent);
      let newGroups = [...groups];
      let lastGroup = newGroups[newGroups.length - 1];

      if (lastGroup && lastGroup.sender === "You") {
        lastGroup.messages.push({ message: textContent, direction: "outgoing" });
      } else {
        newGroups.push({
          sender: "You",
          messages: [{ message: textContent, direction: "outgoing" }],
        });
      }

      return newGroups;
    });
  };

  const handleCreatorCardTap = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleCreatorXTap = (e, uid) => {
    e.stopPropagation();
    setDatasource(datasource.filter((creator) => creator.uid !== uid));
    setExcludedUids([
      ...excludedUids,
      uid,
    ]);
  };

  useEffect(() => {
    if (window.screen.width < 1000) { navigate("/campaigns") } // disable for mobile for now.
    setTimeout(() => {
      setWelcomeBusinessTitleActive(true);
      sendNextAIMessage(null);
    }, 400);

    let subscriptions = [];
    const fetchData = async (uid) => {
      const businessesDbRef = ref(database, `businesses/${uid}`);
      const businessesDbUnsubscribe = onValue(businessesDbRef, (snapshot) => {
        const business = snapshot.val();
        if (business) {
          setBusinessData(business);
        }
      });
      subscriptions.push({ ref: businessesDbRef, unsubscribe: businessesDbUnsubscribe });

      get(ref(database, 'influencers')).then((snapshot) => {
        setAllInfluencers(snapshot.val());
      });

      get(ref(database, 'public_users')).then((snapshot) => {
        const publicUsers = snapshot.val();
        setPublicUsers(publicUsers);
      });
    }

    if (user) {
      fetchData(user.uid);
    }

    return () => {
      for (let { ref, unsubscribe } of subscriptions) {
        off(ref, 'value', unsubscribe);
      }
      subscriptions = []
    };
  }, [user, navigate]);

  return (
    <div className="content-container">
      <div style={{ display: 'flex' }}>
        <div className='bg-beige' style={{ width: '65%', height: 'calc(100vh - 65px)', zIndex: '0' }}>
          <div className={recommendationsActive ? 'animateCentered move' : 'animateCentered'}>
            <div className={`fadeIn ${welcomeBusinessTitleActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', paddingTop: '40px' }}>
              {!recommendationsActive && <img src={welcomeSplashUrl} style={{ width: '40%', height: '30%' }} alt="splash" />}
              <h1 style={{ fontSize: '42px' }}>Welcome, <span style={{ color: '#2A5750' }}>{businessData.business_name}</span></h1>
            </div>
            <div className={`fadeIn ${welcomeBusinessSubtitleActive ? 'active' : ''}`} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', paddingBottom: '40px', fontSize: '22px' }}>
              <span style={{ color: '#2A5750' }}><b>{businessDescription}</b></span>&ensp;in&ensp;<span style={{ color: '#2A5750' }}><b>{businessData.market}</b></span>.
            </div>
          </div>
          <div className={`fadeIn ${recommendationsActive ? 'active' : ''}`} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', transitionDelay: '1s' }}>
            <span style={{ fontSize: '16px' }}>Your personally curated local creators:</span>
          </div>
          <div className={`tag-container fadeIn ${recommendationsActive ? 'active' : ''}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', margin: '10px 0px' }}>
            {datasource && datasource.slice(0,3).filter((creator) => allInfluencers[creator.uid]).map((creator, index) => (
              <div
                key={index}
                className={`bundle hoverable fadeIn ${numCardsShown >= index ? 'active' : ''}`}
                style={{ display: 'flex', flexDirection: 'column', width: '35%', minWidth: '150px', maxWidth: '300px', height: '400px', backgroundColor: 'white', borderRadius: '20px', alignItems: 'center', justifyContent: 'space-between', padding: '20px 0px', textAlign: 'center', zIndex: '0' }}
                onClick={() => handleCreatorCardTap(`/#/user/${allInfluencers[creator.uid].username}?uid=${user.uid}`)}
              >
                <div style={{ display: 'flex', width: '85%', justifyContent: 'space-between' }}><FontAwesomeIcon icon={faCircleInfo} color='#555' /><div onClick={(e) => {handleCreatorXTap(e, creator.uid)}}><FontAwesomeIcon icon={faX} color='#555' /></div></div>
                  <img src={allInfluencers[creator.uid]?.profile_pic_url || defaultProfilePicUrl} alt="Profile" className="profilePic" style={{ position: 'relative', width: '150px', height: '150px', flexShrink: '0' }} />
                <div>
                  <h2>{publicUsers[creator.uid]}.</h2>
                  <FontAwesomeIcon icon={faEye} color='#555' />&ensp;{ReachCalculator(allInfluencers[creator.uid]?.cached, true)}&ensp;&ensp;|&ensp;&ensp;<FontAwesomeIcon icon={faMoneyBill} color='#555' />&ensp;${allInfluencers[creator.uid]?.compensation?.amount || constants.defaultPobblesSuggestedRate}
                </div>
                <span style={{ margin: '0px 20px' }}><i>{creator.reason}</i></span>
                <div style={{ paddingLeft: '10px', gap: '5px' }}>
                  <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow">View Profile</button>
                </div>
              </div>
            ))}
          </div>
          <div className={`fadeIn ${bottomCTAViewActive ? 'active' : ''}`} style={{ display: 'flex', paddingTop: '40px', paddingBottom: '20px', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <span><i>Prefer to do it yourself? <a href='/#/campaign/create'><b>Create your first campaign</b></a> today to browse through more local creators!</i></span>
          </div>
        </div>
        <div style={{ width: '35%' }}>
          <MainContainer style={{ height: 'calc(100vh - 65px)', width: '100%' }}>
            <ChatContainer>
              <ConversationHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', fontSize: '24px', height: '100px', paddingBottom: '20px' }}>
                <Avatar src={pobProfilePicUrl} name="Pob - Pobbles AI" />
                <ConversationHeader.Content>
                  <span>Getting Started with Pob, the Pobbles AI</span>
                </ConversationHeader.Content>
              </ConversationHeader>
              <MessageList typingIndicator={isTyping && <TypingIndicator content="Pob - Pobbles AI is thinking..." />}>
                {messageGroups.map((group, index) => (
                  <MessageGroup
                    key={index}
                    sender={group.sender}
                    direction={group.sender === "You" ? "outgoing" : "incoming"}
                  >
                    {group.sender === 'Pob - Pobbles AI' && (
                      <Avatar src={pobProfilePicUrl} name={group.sender} status="available" />
                    )}
                    <MessageGroup.Header>{group.sender}</MessageGroup.Header>
                    <MessageGroup.Messages>
                      {group.messages.map((message, msgIndex) => (
                        <Message key={msgIndex} model={message}/>
                      ))}
                    </MessageGroup.Messages>
                  </MessageGroup>
                ))}
              </MessageList>
              <MessageInput attachButton={false} disabled={isTyping} placeholder="Type message here" onSend={handleSendMessage} autoFocus />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
    </div>
  );
}

export default Welcome;

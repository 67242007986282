import React, { useEffect, useState } from 'react';
import { auth, database } from '../lib/firebase';
import { ref, update } from "firebase/database";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYelp } from '@fortawesome/free-brands-svg-icons';

const YelpModal = ( { props } ) => {
  const yelpData = props.yelpData
  const isEditable = props.isEditable
  const [isOpen, setIsOpen] = useState(false);
  const [yelpProfileURL, setYelpProfileURL] = useState(null);

  const handleOnClick = () => {
    isEditable && setIsOpen(true)
  };

  const handleSubmit = async () => {
    if (!yelpProfileURL) {
      toast.error("Yelp Profile URL must not be blank.");
      return;
    }

    if (!yelpProfileURL.startsWith("https://www.yelp.com/user_details?userid=")) {
      toast.error("Yelp Profile URL must start with https://www.yelp.com/user_details?userid=");
      return;
    }

    const yelpDataRef = ref(database, `influencers/${auth.currentUser.uid}/cached/yelp`);
    update(yelpDataRef, {
      profileURL: yelpProfileURL,
      data: { eliteYears: "" },
      isVerified: false
    });

    toast.success("Success! Your profile will be verified by a member of the team within 24 hours.");
    setIsOpen(false);
  };

  useEffect(() => {
    if (yelpData) {
      setYelpProfileURL(yelpData.profileURL)
    }
  }, [yelpData]);

  return (
    <div>
      <div className="social-button-detail-container">
        <button className={`social-button ${yelpData ? (yelpData.isVerified ? 'active' : 'pending') : 'inactive'}`} onClick={() => handleOnClick()}><FontAwesomeIcon icon={faYelp} /></button>
        {yelpData
          ? yelpData.isVerified && yelpData.data
            ? yelpData.data.eliteYears
                ? <div className="data-item">
                    <span className="data-number">{`${yelpData.data.eliteYears}y`}</span>
                    <span className="data-label">Yelp Elite</span>
                  </div>
                : <div className="social-detail"><p>Yelp Member</p></div>
            : <div className="social-detail"><p>Pending Verification</p></div>
          : <div className="social-detail"><p>Link your Yelp Profile</p></div>
        }
      </div>
      {isOpen &&
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div style={{
            position: 'relative',
            zIndex: 0,
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            width: '400px',
            textAlign: 'center',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          }}>
            <h3>Verify your Yelp Profile</h3>
            <br />
            <p>Submit your Yelp profile url to verify your Yelp Elite status.</p>
            <br />
            <p><b>On web:</b><br /> You can find this url <a target="_blank" rel="noopener noreferrer" href="https://www.yelp.com/user_details">here</a> after logging in.</p>
            <br />
            <p><b>In the Yelp app:</b><br /> Visit your profile {'>'} Share icon (top right corner) {'>'} Copy</p>
            <br />
            <input
              placeholder="https://www.yelp.com/user_details?userid="
              value={yelpProfileURL}
              onChange={e => setYelpProfileURL(e.target.value)}
              style={{ margin: '0px 0', padding: '10px', width: '100%' }}
            />
            <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleSubmit} style={{ fontSize: '1.1em', padding: '10px', margin: '10px 0', width: '100%' }}>Submit</button>
            <button className="btn diamond-btn diamond-btn--sm" onClick={() => setIsOpen(false)} style={{ fontSize: '1.1em', padding: '10px', marginBottom: '10px 0', width: '100%' }}>Close</button>
          </div>
        </div>
      }
    </div>
  );
};

export default YelpModal;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { auth } from '../lib/firebase';
import { toast } from 'react-toastify';
import * as constants from '../constants.jsx';

const UsernameModal = ( { props } ) => {
  const shouldShow = props.shouldShow
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState('');

  const handleSubmit = async () => {
    if (!username) {
      toast.error("Username must not be empty.");
      return;
    }

    if (username.length < 2) {
      toast.error("Username must be at least two characters long.");
      return;
    }

    if (! /^[^.#$[\]]*$/.test(username)) {
      toast.error("Username should only be alphanumeric. (0-9, A-Z)");
      return;
    }

    try {
      await axios.post(`${constants.backendDomain}/set-username`,
        {
          'id': auth.currentUser.uid,
          'username': username
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
        return
      }
    }

    toast.success("Success! Your username has been set!");
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(shouldShow);
  }, [shouldShow]);

  return (
    <div>
      {isOpen &&
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div style={{
            position: 'relative',
            zIndex: 0,
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            width: '400px',
            textAlign: 'center',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          }}>
            <h3>Set a username!</h3>
            <br />
            <p>To get started, please choose a username!</p>
            <p>(You can also set or modify this later on the settings page)</p>
            <br />
            <p>Your profile will be accessible at:</p>
            <p>https://app.pobbles.co/#/user/username</p>
            <br />
            <input
              placeholder="Username"
              value={username}
              onChange={e => setUsername(e.target.value.trim())}
              style={{ margin: '0px 0', padding: '10px', width: '100%' }}
            />
            <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleSubmit} style={{ fontSize: '1.1em', padding: '10px', margin: '10px 0', width: '100%' }}>Submit</button>
            <button className="btn diamond-btn diamond-btn--sm" onClick={() => setIsOpen(false)} style={{ fontSize: '1.1em', padding: '10px', marginBottom: '10px 0', width: '100%' }}>Skip</button>
          </div>
        </div>
      }
    </div>
  );
};

export default UsernameModal;

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import 'react-toastify/dist/ReactToastify.css';
import { amplitude } from './lib/amplitude.jsx';
import TimeAgo from 'javascript-time-ago'
import App from './App';
import en from 'javascript-time-ago/locale/en.json'

Sentry.init({
  dsn: "https://7289af152a2d9b130b316746820d30e4@o4506732606652416.ingest.sentry.io/4506732614516736",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/pobbles-backend-v6diyrelva-uc\.a\.run\.app/],
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

TimeAgo.addDefaultLocale(en);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { amplitude } from './lib/amplitude.jsx';
import { database, auth } from './lib/firebase';
import { ref, onValue, get, set } from "firebase/database"
import { toast } from 'react-toastify';
import * as constants from './constants.jsx';
import './css/web.css';

function Settings({user}) {
  const navigate = useNavigate();

  const type = useRef(window.localStorage.getItem('type'));

  const [balance, setBalance] = useState(0);
  const [businessName, setBusinessName] = useState('');
  const [market, setMarket] = useState('');
  const [username, setUsername] = useState('');
  const [existingUsername, setExistingUsername] = useState('');
  const [publicProfile, setPublicProfile] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [preferredContactMethod, setPreferredContactMethod] = useState(type === 'business' ? 'email' : 'phone');

  const [availableMarkets, setAvailableMarkets] = useState([]);
  const [isHoveringBalance, setIsHoveringBalance] = useState(false);

  const fetchData = async (uid) => {
    const usersDbRef = ref(database, `users/${uid}`);
    onValue(usersDbRef, (snapshot) => {
      const user = snapshot.val();
      setEmail(user.email);
      setPhone(user.phone_number);
      setPreferredContactMethod(user.preferred_contact_method);
      setMarket(user.market);
    });
    const influencersDbRef = ref(database, `influencers/${uid}`);
    onValue(influencersDbRef, (snapshot) => {
      const influencer = snapshot.val();
      if (influencer && influencer.username) {
        setUsername(influencer.username);
        setExistingUsername(influencer.username);
        if (influencer.username) {
          setPublicProfile(`https://app.pobbles.co/#/user/${influencer.username}`)
        }
      }
    });
    const businessDbRef = ref(database, `businesses/${uid}`);
    onValue(businessDbRef, (snapshot) => {
      const business = snapshot.val();
      if (business) {
        setBusinessName(business.business_name);
      }
    });
    const balancesDbRef = ref(database, `balances/${uid}`);
    onValue(balancesDbRef, (snapshot) => {
      const balance = snapshot.val();
      if (balance) {
        setBalance(balance);
      }
    });
    const marketsRef = ref(database, 'markets')
    onValue(marketsRef, (snapshot) => {
      setAvailableMarkets(Object.keys(snapshot.val()));
    });
  };

  function sanitizePhoneNumber(input) {
    let sanitized = input.replace(/\D/g, '');
    if (sanitized.length === 10) {
      return '+1' + sanitized;
    }
    return sanitized;
  }

  const handleSaveChanges = async () => {
    if (type.current === 'business') {
      if (!businessName) {
        toast.error("Please enter a valid business name.");
        return;
      }

      if (! /^[^.#$[\]]*$/.test(businessName)) {
        toast.error("Username cannot contain the characters '.', '#', '$', '[', or ']'.");
        return;
      }
    } else {
      if (username.length < 2) {
        toast.error("Username must be at least two characters long.");
        return;
      }

      if (! /^[^.#$[\]]*$/.test(username)) {
        toast.error("Username cannot contain the characters '.', '#', '$', '[', or ']'.");
        return;
      }
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address!");
      return;
    }

    // Phone validation for US numbers
    const formatted = sanitizePhoneNumber(phone);
    const phoneRegex = /^(\+1|1)?\s?-?\.?\(?\d{3}\)?\s?-?\.?\d{3}\s?-?\.?\d{4}$/;
    if (!phoneRegex.test(formatted)) {
      toast.error("Please enter a valid phone number!");
      return;
    }
    setPhone(formatted)

    if (!market) {
      toast.error("Please select a market.");
    }

    if (type.current === 'business') {
      await setBusinessNameData(businessName);
    } else {
      if (username !== existingUsername) {
        try {
          await axios.post(`${constants.backendDomain}/set-username`,
            {
              'id': user.uid,
              'username': username
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            });
        } catch (error) {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data);
            return
          }
        }
      }
    }

    if (email) {
      await setUserData('email', email);
    }
    if (phone) {
      await setUserData('phone_number', phone);
    }
    if (preferredContactMethod) {
      await setUserData('preferred_contact_method', preferredContactMethod);
    }
    if (market) {
      await setMarketData(market);
    }
    if (type.current === 'business') {
      navigate('/campaigns');
    } else {
      navigate('/profile');
    }
  };

  const setUserData = async (param, value) => {
    const paramRef = ref(database, `users/${user.uid}/${param}`);
    await set(paramRef, value);
  }

  const setBusinessNameData = async (value) => {
    const businessRef = ref(database, `businesses/${user.uid}/business_name`);
    await set(businessRef, value);
    setUserData('business_name', value);
  }

  const setMarketData = async (value) => {
    const businessRef = ref(database, `businesses/${user.uid}/market`);
    const businessSnapshot = await get(businessRef);
    if (businessSnapshot.exists()) {
        await set(businessRef, value);
    }
    const influencerRef = ref(database, `influencers/${user.uid}/market`);
    const influencerSnapshot = await get(influencerRef);
    if (influencerSnapshot.exists()) {
        await set(influencerRef, value);
    }
    setUserData('market', value);
  }

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid);
    }
  }, [user]);

  const handleSignOut = () => {
    auth.signOut()
      .then(() => {
        amplitude.reset();
        if (type.current === 'business') {
          navigate('/business/login');
        } else {
          navigate('/login');
        }
      })
      .catch(error => {
        console.error('Error during sign out:', error);
      });
  };

  if (!user) {
    return null;
  }

  return (
    <div className="content-container" style={{ alignItems: 'center' }}>
      <div className="edit-fields">
        {type.current === 'business'
          ? <div className="edit-field">
              <label htmlFor="businessName">Business Name:</label>
              <input
                id="businessName"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                placeholder="Business Name"
                className="input-field"
              />
            </div>
          : <>
              <div className="edit-field">
                <label htmlFor="username">Custom Profile Username:</label>
                <input
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value.trim())}
                  placeholder="Username"
                  className="input-field"
                />
              </div>
              {publicProfile
                ? <div style={{ textAlign: 'right', paddingBottom: '20px' }}>
                    <p>Your profile is currently accessible at:</p>
                    <a href={publicProfile}>{publicProfile}</a>
                  </div>
                : <div style={{ textAlign: 'right', paddingBottom: '20px' }}>
                    <p>After setting your username, your profile will be accessible at:</p>
                    https://app.pobbles.co/#/user/username
                  </div>
              }
            </>
        }
        <div className="edit-field">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="input-field"
          />
        </div>
        <div className="edit-field">
          <label htmlFor="phone">Phone:</label>
          <input
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
            className="input-field"
          />
        </div>
        <div className="edit-field">
          <label htmlFor="preferred_contact_method">Preferred Contact Method:</label>
          <select
            name="preferred_contact_method"
            value={preferredContactMethod}
            onChange={(e) => setPreferredContactMethod(e.target.value)}
            className="input-field"
            required
          >
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </select>
        </div>
        <div className="edit-field">
          <label htmlFor="market">Location:</label>
          <select
            id="market"
            name="location"
            value={market}
            onChange={(e) => setMarket(e.target.value)}
            style={{ width: '100%', backgroundColor: '#f5f5f5', border: 'none', borderRadius: '5px' }}
          >
            <option value="" disabled>Select your location</option>
            {availableMarkets.map((market, index) => (
              <option key={index} value={market}>{market}</option>
            ))}
            <option value="Other">Other</option>
          </select>
        </div>
        {balance !== 0 &&
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <span
              className="hover-container"
              onMouseEnter={() => setIsHoveringBalance(true)}
              onMouseLeave={() => setIsHoveringBalance(false)}
            >
              <b>Creator Credit:</b>
              {isHoveringBalance && <div className="hover-text">This credit will automatically be applied when you invite creators in campaigns.</div>}
            </span>
            <span className={balance > 0 ? 'good-color' : 'error-color'} style={{ fontSize: '1.1em' }}> ${balance.toLocaleString()}</span>
          </div>
        }
      </div>
      <br/>
      <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleSaveChanges}>Save Changes</button>
      <br/>
      <button className="btn diamond-btn diamond-btn--sm" onClick={handleSignOut}>Sign Out</button>
    </div>
  );
}

export default Settings;
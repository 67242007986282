import React, { useRef, useState, useEffect } from 'react';
import { auth, storage } from '../lib/firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Datetime from 'react-datetime';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import * as constants from '../constants.jsx';

const CampaignEdit = ( { existingData, ctas } ) => {
  const [campaignData, setCampaignData] = useState({
    status: '',
    business_id: '',
    campaign_name: '',
    campaign_description: '',
    campaign_picture: '',
    location: null,
    meet_date: null,
    post_date: null,
    tt_expectations: '',
    ig_expectations: '',
    is_recurring: null,
    targeted_compensation: ['paid'],
    is_comped: null,
    freebie_comp_description: '',
    creator_details: '',
  });

  const fileInputRef = useRef(null);
  const defaultcampaign_picture = '../images/generic-image-placeholder.png';

  const handleCampaignPicClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImageToStorage = async (file, uid) => {
    const campaignsRef = ref(storage, `campaigns/${uid}/${file.name}`);
    await uploadBytes(campaignsRef, file);
    const downloadURL = await getDownloadURL(campaignsRef);
    return downloadURL;
  };

  const handleCompedCheckboxClick = (checked) => {
    if (campaignData.status && campaignData.status !== 'created') {
      toast.error('Oops! You cannot modify the compensation type after matching with creators. Consider creating a new campaign instead.');
      return
    }
    setCampaignData((prevData) => ({
      ...prevData,
      is_comped: checked,
      targeted_compensation: checked ? ['paid', 'freebies'] : ['paid'],
    }));
  };

  const handleCampaignChange = (e) => {
    const { name, value } = e.target;
    setCampaignData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateCampaign = (() => {
    let errors = [];
    const data = campaignData;

    // Validate Campaign Recurring
    if (data.is_recurring === null) {
      errors.push("Campaign type is required.");
    }
    // Validate Campaign Name
    if (!data.campaign_name.trim()) {
      errors.push("Campaign name is required.");
    }
    if (data.is_recurring) {
      // Validate Campaign Description for recurring campaigns
      if (!data.campaign_description.trim()) {
        errors.push("Campaign description is required.");
      }
      // Validate Subscription Type
      if (!data.subscription_type || data.subscription_type === 'standard') {
        errors.push("Please select a paid subscription option.");
      }
      // Validate Subscription Frequency
      if (!data.subscription_frequency) {
        errors.push("Valid subscription frequency is required.");
      }
    } else {
      // Validate Expectations
      if (!data.tt_expectations.trim() || !data.ig_expectations.trim()) {
        errors.push("Expectations for Tiktok and Instagram are required.");
      }
      // Validate Meet and Post Dates
      if (data.meet_date && data.post_date && moment(data.meet_date).isAfter(data.post_date)) {
        errors.push("Post by date must be after the meet/shoot by date.");
      }
    }
    // Validate Reviewed Item Compensation
    if (data.is_comped === null) {
      errors.push("Please specify if the items being reviewed for content will be comped.");
    }
    // Validate Freebies
    if (data.targeted_compensation.includes('freebies') && !data.freebie_comp_description) {
      errors.push("Valid description of freebies is required for freebie compensation campaigns.");
    }
    return { data, errors };
  });

  useEffect(() => {
    if (existingData) {
      setCampaignData((prevData) => ({
        ...prevData,
        ...existingData,
      }));
    }
  }, [existingData]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
        <div className="bordered-edit-fields" style={{ display: 'flex', flexDirection: 'column', margin: '20px', width: '80%', minWidth: '375px' }}>
          <label htmlFor="tag-container" style={{ width: 'unset', textAlign: 'left' }}>Campaign Type:</label>
          <br />
          <div className="tag-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
            {constants.availableCampaignTypes.filter(type => type.isRecurring === campaignData.is_recurring).map((type, index) => (
              <div
                key={index}
                className={`bundle ${campaignData.is_recurring === type.isRecurring ? 'selected' : ''}`}
                style={{ display: 'flex', flexDirection: 'column', width: '300px', height: '350px', alignItems: 'center', textAlign: 'center' }}
              >
                <img src={type.image} alt="Bundle" style={{ paddingTop: '10px', width: '100%', height: '225px', objectFit: 'contain' }}/>
                <h2>{type.name}</h2>
                <br/>
                {type.description}
              </div>
            ))}
          </div>
          {campaignData.is_recurring !== null &&
            <>
              <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
                <label htmlFor="campaign_name" style={{ width: 'unset', textAlign: 'unset' }}>Campaign Name:</label>
                <div className="edit-field">
                  <input
                    id="campaign_name"
                    name="campaign_name"
                    value={campaignData.campaign_name}
                    placeholder="ACME's Happy Hour"
                    className="input-field"
                    onChange={handleCampaignChange}
                    style={{ width: '100%' }}
                  />
                </div>
                {campaignData.is_recurring &&
                  <>
                    <label htmlFor="campaign_description" style={{ width: 'unset', textAlign: 'unset' }}>Campaign Objective:</label>
                    <div className="edit-field">
                      <input
                        id="campaign_description"
                        name="campaign_description"
                        value={campaignData.campaign_description}
                        placeholder="Showcase our new happy hour menu items"
                        className="input-field"
                        onChange={handleCampaignChange}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </>
                }
              </div>
              <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
                <label htmlFor="campaign_picture" style={{ width: 'unset', textAlign: 'unset', paddingBottom: '10px' }}>Campaign image:</label>
                <div className="edit-field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className="profilePic-container" onClick={handleCampaignPicClick} style={{ marginRight: 'unset', width: '100%', height: '200px' }}>
                    <img src={campaignData.campaign_picture || defaultcampaign_picture} className="profilePic" alt="Campaign Profile" style={{ borderRadius: '10px' }}/>
                    <div className="edit-overlay" style={{ borderRadius: '10px' }}>
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <input
                      type="file"
                      id="campaign_picture"
                      ref={fileInputRef}
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const downloadURL = await uploadImageToStorage(file, auth.currentUser.uid);
                          setCampaignData((prevData) =>
                            ({ ...prevData, campaign_picture: downloadURL })
                          );
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
              </div>
            </>
          }
          {campaignData.is_recurring
            ? <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
                <label htmlFor="tag-container" style={{ width: 'unset', textAlign: 'unset' }}>Recurring Subscription Plan:</label>
                <div className="tag-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                  {constants.availableRecurringSubscriptions.filter((subscription) => campaignData.subscription_type === subscription.id).map((type, index) => (
                    <div
                      key={index}
                      className={`bundle ${campaignData.subscription_type === type.id ? 'selected' : ''}`}
                      style={{ display: 'flex', flexDirection: 'column', width: '250px', height: '350px', alignItems: 'center', textAlign: 'center' }}
                    >
                      <img src={type.image} alt="Bundle" style={{ paddingTop: '10px', width: '100%', height: '225px', objectFit: 'contain' }}/>
                      <h2>{type.name}</h2>
                      ${type.price}
                      <br/>
                      <br/>
                      {type.description}
                    </div>
                  ))}
                </div>
                <label htmlFor="tag-container" style={{ width: 'unset', textAlign: 'unset' }}>Subscription Frequency:</label>
                <div className="edit-field">
                  <div className="tag-container" style={{ display: 'contents', width: '90%' }}>
                    {constants.availableSubscriptionFrequencies.filter((frequency) => campaignData.subscription_frequency === frequency.id).map((tag, index) => (
                      <div
                        key={index}
                        className={`tag ${campaignData.subscription_frequency === tag.id ? 'selected' : ''}`}
                      >
                        {tag.name} ({tag.description})
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            : <>
                <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
                  <label htmlFor="tt_expectations" style={{ width: 'unset', textAlign: 'unset' }}>Tiktok Video Expectations:</label>
                  <div className="edit-field">
                    <input
                      id="tt_expectations"
                      name="tt_expectations"
                      value={campaignData.tt_expectations}
                      placeholder="1 Tiktok video"
                      className="input-field"
                      onChange={handleCampaignChange}
                      style={{ width: '100%' }}
                    />
                  </div>

                  <label htmlFor="ig_expectations" style={{ width: 'unset', textAlign: 'unset' }}>Instagram Reel & Story Expectations:</label>
                  <div className="edit-field">
                    <input
                      id="ig_expectations"
                      name="ig_expectations"
                      value={campaignData.ig_expectations}
                      placeholder="1 video post (reel) and 2-3 stories"
                      className="input-field"
                      onChange={handleCampaignChange}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
                <div className="edit-fields bordered-edit-fields" style={{ flexDirection: 'row', maxWidth: 'unset', justifyContent: 'space-between' }}>
                  <div style={{ flexDirection: 'column', width: '40%' }}>
                    <label style={{ width: 'unset', textAlign: 'unset' }}>When should the creator shoot the content by?</label>
                    <div className="edit-field">
                      <Datetime
                        inputProps={{ className: 'datetime-picker', placeholder: new Date().toLocaleDateString(), readOnly: true }}
                        value={campaignData.meet_date ? moment(campaignData.meet_date) : null}
                        onChange={(momentDate) => {
                          setCampaignData((prevData) =>
                            ({
                              ...prevData, meet_date: momentDate.toISOString(),
                              ...prevData, post_date: momentDate.add(7, 'days').toISOString(),
                            })
                          );
                        }}
                        isValidDate={currentDate => currentDate.isAfter(Datetime.moment())}
                        dateFormat={true}
                        timeFormat={false}
                      />
                    </div>
                  </div>
                  <div style={{ flexDirection: 'column', width: '40%' }}>
                    <label style={{ width: 'unset', textAlign: 'unset' }}>When should the content be posted by?</label>
                    <div className="edit-field">
                      <Datetime 
                        inputProps={{ className: 'datetime-picker', placeholder: new Date().toLocaleDateString(), readOnly: true }}
                        value={campaignData.post_date ? moment(campaignData.post_date) : null}
                        onChange={(momentDate) => {
                          setCampaignData((prevData) =>
                            ({ ...prevData, post_date: momentDate.toISOString() })
                          );
                        }}
                        isValidDate={currentDate => currentDate.isAfter((moment(Date(campaignData.meet_date)) || Datetime.moment()).add(1, 'days'))}
                        dateFormat={true}
                        timeFormat={false}
                      />
                    </div>
                  </div>
                </div>
              </>
          }
          <div className="large-edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
            <label htmlFor="isComped" style={{ width: 'unset', textAlign: 'unset' }}>Are you providing comped items as part of your collab? (or free items)</label>
            <div className="edit-field" style={{ justifyContent: 'space-evenly' }}>
              <div>
                <input
                  type="checkbox"
                  name="isComped"
                  checked={campaignData.is_comped !== null ? campaignData.is_comped : false }
                  onChange={() => handleCompedCheckboxClick(true)}
                />
                &ensp;Yes
              </div>
              <div>
                <input
                  type="checkbox"
                  name="isComped"
                  checked={campaignData.is_comped !== null ? !campaignData.is_comped : false }
                  onChange={() => handleCompedCheckboxClick(false)}
                />
                &ensp;No (or not applicable)
              </div>
            </div>
            {campaignData.targeted_compensation.includes('freebies') &&
              <>
                <label htmlFor="freebie_comp_description" style={{ width: 'unset', textAlign: 'unset' }}>Description of the free / comped goods:</label>
                <div className="edit-field">
                  <input
                    id="freebie_comp_description"
                    name="freebie_comp_description"
                    value={campaignData.freebie_comp_description}
                    placeholder="Comped meal for content creation."
                    className="input-field"
                    onChange={handleCampaignChange}
                    style={{ width: '100%' }}
                    disabled={campaignData.status && campaignData.status !== 'created'}
                  />
                </div>
              </>
            }
            <label htmlFor="creator_details" style={{ width: 'unset', textAlign: 'unset' }}>Anything you want to share with the creators?</label>
            <div className="edit-field">
              <textarea
                id="creator_details"
                name="creator_details"
                value={campaignData.creator_details}
                placeholder="Looking forward to working with you!"
                className="input-field textarea-field"
                onChange={handleCampaignChange}
                style={{ width: '100%', height: '100px' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', padding: '30px', marginBottom: '50px', justifyContent: 'center', alignItems: 'center' }}>
        {Object.entries(ctas).map(([title, values], index) => (
          <button key={title} className={values.isPrimary ? 'btn diamond-btn diamond-btn--yellow' : 'btn diamond-btn'} onClick={() => values.handler(validateCampaign())}>{title}</button>
        ))}
      </div>
    </div>
  );
};

export default CampaignEdit;

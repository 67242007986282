import { useGate } from 'statsig-react';

const useFeatureFlag = (feature) => {
  const { value } = useGate(feature);
  return value;
};

const FeatureFlag = {
  isInternalUser: 'internal_user',
  businessSMM: 'business_smm',
}

export {
  useFeatureFlag,
  FeatureFlag
};

const formatCurrency = (total, currency) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(total / 100);
};

const PaymentsListItem = ({ date, title, total, currency, status, detailedContent }) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case 'paid':
        return { backgroundColor: 'green', color: '#fff' };
      case 'complete':
        return { backgroundColor: 'green', color: '#fff' };
      case 'pending':
        return { backgroundColor: '#edbb11', color: '#000' };
      default:
        return {};
    }
  };

  return (
    <div className={`payments-list-item ${status === 'void' ? 'line-through' : ''}`}>
      <div>
        <div className="payments-list-item-title">
          {date} - {title}
        </div>
        {detailedContent}
      </div>
      {total &&
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'flex-end', textAlign: 'right' }}>
          <span className="payments-list-item-detail">{formatCurrency(total, currency)}</span>
          <div className="payments-list-item-status-container" style={getStatusStyle(status)}>
            <span className="payments-list-item-status">{status}</span>
          </div>
        </div>
      }
    </div>
  );
};

export default PaymentsListItem;

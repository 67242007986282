import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../css/socialMediaDashboard.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const SocialMediaDashboard = ({ contentData, instagramData, tiktokData, postHandler }) => {
  const [content, setContent] = useState(contentData || []);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const handleCaptionChange = (e, index) => {
    const newContent = content.map((item, idx) => {
      if (idx === index) {
        return { ...item, caption: e.target.value };
      }
      return item;
    });
    setContent(newContent);
  };

  const handlePostButton = (caption, url) => {
    postHandler(caption, url);
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  useEffect(() => {
    setContent(contentData);
  }, [contentData]);

  useEffect(() => {
    setSelectedAccount(instagramData?.accounts[0]?.id || null);
  }, [instagramData]);

  useEffect(() => {
    if (!selectedAccount) {
      setSelectedData(null);
      return;
    }
    const { id } = instagramData?.instagramAccounts?.[selectedAccount];
    if (id) {
      setSelectedData(instagramData?.instagramData?.[id] || null);
    }
  }, [selectedAccount, instagramData]);

  if (!instagramData && !tiktokData) {
    return <></>;
  }

  return (
    <>
      {instagramData &&
        <>
          <h1>Pending Videos</h1>
          <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', padding: '20px' }}>
            {content?.map((content, idx) => (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} key={content.url}>
                <video src={content.url} controls style={{ width: '270px', height: '480px' }}>
                  Your browser does not support the video tag.
                </video>
                <textarea
                  value={content.caption}
                  onChange={(e) => handleCaptionChange(e, idx)}
                  style={{ margin: '10px', width: '270px', minHeight: '50px', resize: 'vertical' }}
                />
                <p style={{ paddingBottom: '10px' }}><i>Scheduled to post: </i>{(new Date(content.scheduled_date * 1000)).toLocaleString()}</p>
                <button className='btn diamond-btn diamond-btn--yellow' onClick={(e) => handlePostButton(content.caption, content.url)}>Post Now</button>
              </div>
            ))}
          </div>
          <h1>Associated Accounts</h1>
          Select or deselect accounts to view insights:
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', justifyContent: 'center', alignContent: 'center' }}>
            {instagramData.accounts && instagramData.accounts.map((account, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  id={account.id}
                  checked={selectedAccount === account.id}
                  onChange={(e) => setSelectedAccount(e.target.checked ? account.id : null)}
                />
                &ensp;
                <FontAwesomeIcon icon={faFacebook} /> {account.name}
                {instagramData.instagramAccounts && instagramData.instagramAccounts[account.id] && 
                  <>&ensp;(<FontAwesomeIcon icon={faInstagram} /> {instagramData.instagramAccounts[account.id].username})</>
                }
              </div>
            ))}
          </div>
          {selectedData && 
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', textAlign: 'center', padding: '0px 20px' }}>
              <h1>Recent Comments</h1>
              {selectedData.comments.map((comment, index) => 
                <div key={index}>{comment.text} - {(new Date(Date.parse(comment.timestamp))).toLocaleDateString()}</div>
              )}
              <h1>Past 30 day insights</h1>
              <p style={{ textAlign: 'center', paddingBottom: '40px' }}><i>Last Updated: {(new Date(selectedData.refreshed * 1000)).toLocaleDateString()}</i></p>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="socialMediaMasonryGrid"
                columnClassName="socialMediaMasonryGridColumn"
              >
                {Object.values(selectedData.insights?.total || {}).map((insight) => {
                  if (insight.total_value?.value !== undefined) {
                    return (
                      <div className="socialMediaCard" key={insight.id}>
                        <div className="socialMediaCardHeader">{insight.title}</div>
                        <div>{insight.total_value.value.toLocaleString()}</div>
                      </div>
                    );
                  }
                  return null;
                })}
                {Object.values(selectedData.insights?.daily || {}).map((insight) => 
                  <SocialMediaTimeSeriesChart
                    data={insight.values}
                    title={`Daily ${insight.title}`}
                    key={insight.id}
                  />
                )}
                {Object.values(selectedData.insights?.demographic || {}).map((insight) => 
                  <SocialMediaDemographicChart
                    data={Object.entries(insight.values[0]?.value || {}).map(([name, value]) => ({ name, value }))}
                    title={`${insight.title}`}
                    type={insight.title === 'Gender and Age' ? 'bar' : 'pie'}
                    key={insight.id}
                  />
                )}
              </Masonry>
            </div>
          }
        </>
      }
    </>
  );
};

const SocialMediaTimeSeriesChart = ({ data, title }) => (
  <div className="socialMediaCard">
    <div className="socialMediaCardHeader">{title}</div>
    <ResponsiveContainer className="socialMediaChartContainer">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="end_time" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

const SocialMediaDemographicChart = ({ data, title, type = "pie" }) => {
  const ChartComponent = type === "bar" ? BarChart : PieChart;
  return (
    <div className="socialMediaCard">
      <div className="socialMediaCardHeader">{title}</div>
      <ResponsiveContainer className="socialMediaChartContainer">
        <ChartComponent data={data}>
          {type === "bar" ? (
            <>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </>
          ) : (
            <>
              <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </>
          )}
        </ChartComponent>
      </ResponsiveContainer>
    </div>
  );
};

export default SocialMediaDashboard;

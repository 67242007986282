import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { amplitude } from './lib/amplitude.jsx';
import { auth } from './lib/firebase';
import axios from 'axios';
import { signInWithPhoneNumber, RecaptchaVerifier } from '@firebase/auth';
import { toast } from 'react-toastify';
import VerifyPhone from './components/verifyPhone';
import VerifyEmail from './components/verifyEmail';
import * as constants from './constants.jsx';
import './css/web.css';

function Login() {
  const [type, setType] = useState(null);

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [confirmation, setConfirmation] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'button-container', {
      'size': 'invisible',
      /*
      Motivation for the code:
      1. https://github.com/firebase/firebase-js-sdk/issues/3356
      2. https://github.com/jsardev/reaptcha/issues/218
      3. https://github.com/dozoisch/react-google-recaptcha/issues/129

      Cannot read property 'style' of null was the error message, and to get/test this error do the following steps:
      1. Enter a number, and an invalid number ideally
      2. Keep trying to submit till you see the captcha of image selector where it asks you to select buses, etc
      3. Once you click on Verify, it will show an error screen, and in some cases even when number was valid.
      
      Since the error was not due to any other code in this file, I decided to reset the captcha in each type of callback, and this seems to have solved the error
      */
      callback: () => {
        window.recaptchaVerifier.recaptcha.reset();
      },
      'expired-callback': () => {
        window.recaptchaVerifier.recaptcha.reset();
      },
      'error-callback': () => {
        window.recaptchaVerifier.recaptcha.reset();
      },
    });
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
      }
    }
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        amplitude.setUserId(user.uid);
        navigate("/campaigns");
      }
    });
  }, [navigate, type]);

  const checkAccount = async () => {
    let body = {};
    if (type === 'business') {
      body = { email };
    } else {
      body = { phone: sanitizePhoneNumber(phone) };
    }
    try {
      await axios.post(`${constants.backendDomain}/user/auth`, body,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
      return true;
    } catch (error) {
      if (error.response?.status === 400 && error.response?.data === 'account-does-not-exist') {
        toast.error('Oops! Looks like we do not have an account with this info. Try signing up instead.');
      } else {
        toast.error(error.response?.data || 'Oops! Something went wrong. Please try again later.');
      }
      return false;
    }
  };

  const signInWithPhone = async (phone) => {
    signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        setConfirmation(confirmationResult);
      }).catch((error) => {
        toast.error("Could not send SMS to phone. Please reload the page and try again.");
        console.log(error);
        if (window.recaptchaVerifier) {
          window.recaptchaVerifier.render().then(function(widgetId) {
            window.grecaptcha.reset(widgetId);
          });
        }
      });
  };

  function sanitizePhoneNumber(input) {
    const sanitized = input.replace(/\D/g, '');
    if (sanitized.length === 10) {
      return '+1' + sanitized;
    } else if (sanitized.length === 11 && sanitized.startsWith('1')) {
      return '+' + sanitized;
    } else {
      return input;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (type === 'influencer') {
      const formatted = sanitizePhoneNumber(phone);

      const phoneRegex = /^(\+1|1)?\s?-?\.?\(?\d{3}\)?\s?-?\.?\d{3}\s?-?\.?\d{4}$/;
      if (!phoneRegex.test(formatted)) {
          toast.error("Please enter a valid phone number!");
          return;
      }

      setPhone(formatted);

      checkAccount()
        .then((didSucceed) => {
          if (didSucceed) {
            signInWithPhone(formatted);
          }
        });
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
          toast.error("Please enter a valid email address!");
          return;
      }

      checkAccount()
        .then((didSucceed) => {
          if (didSucceed) {
            setConfirmation(email);
          }
        });
    }
  };

  return (
    <div className="content-container bg-beige" style={{ 'position': 'relative', 'zIndex': '0' }}>
      <div className="container" style={{ height: 'calc(100vh - 65px)' }}>
        <div className="splash" style={{ height: '100%' }}>
          <img src='../images/splash-business.png' alt="Splash" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '50%' }}>
          {confirmation
            ? type === 'influencer'
              ? <VerifyPhone
                  phone={phone}
                  confirmation={confirmation}
                />
              : <VerifyEmail
                  email={email}
                  routeToWelcome={false}
                />
            : <div className="form-container">
                <h1>Welcome back</h1>
                <p>Don't have an account? <a href="/#/signup">Sign up</a></p>
                <br/>
                <span style={{ width: '100%', textAlign: 'center', fontSize: '1.2em' }}>I am a:</span>
                <div className="tag-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                  <div
                    className={`bundle ${type === 'business' ? 'selected' : ''}`}
                    onClick={() => setType('business')}
                    style={{ display: 'flex', flexDirection: 'column', width: '250px', alignItems: 'center', textAlign: 'center' }}
                  >
                    <h2>Business</h2>
                  </div>
                  <div
                    className={`bundle ${type === 'influencer' ? 'selected' : ''}`}
                    onClick={() => setType('influencer')}
                    style={{ display: 'flex', flexDirection: 'column', width: '250px', alignItems: 'center', textAlign: 'center' }}
                  >
                    <h2>Creator</h2>
                  </div>
                </div>
                <br/>
                <form>
                  {type
                    ? type === 'influencer'
                        ? <input
                            type="tel"
                            name="phone"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                        : <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                    : <></>
                  }
                  <button
                    id="button-container"
                    className="btn diamond-btn diamond-btn--sm diamond-btn--yellow"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ display: type ? "inline-block" : "none" }}
                  >
                    Login
                  </button>
                </form>
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Login;

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDeDV_qwic9XlYIGfGOOm9lMJo_MgnJPe0",
  authDomain: "pobbles-prod.firebaseapp.com",
  databaseURL: "https://pobbles-prod-default-rtdb.firebaseio.com",
  projectId: "pobbles-prod",
  storageBucket: "pobbles-prod.appspot.com",
  messagingSenderId: "823654297271",
  appId: "1:823654297271:web:3497f8294cd4387a2aa732",
  measurementId: "G-VVELL3PVTS"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);
const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();
const storage = getStorage(firebaseApp);

export { firebaseApp, database, auth, storage };

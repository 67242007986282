import { NumberFormatter } from './NumberFormatter.jsx';

export function ReachCalculator(cachedData, formatted) {
   if (!cachedData) { return 0 }
   const instagramData = cachedData.ig;
   const tiktokData = cachedData.tt;
   let totalReach = 0;
   if (instagramData?.data) {
      if (instagramData.data.followers) {
         totalReach += Math.round(instagramData.data.followers / 10);
      }
      if (instagramData.recentMedia && instagramData.data.post_meta) {
         const engagement = [];
         Object.values(instagramData.recentMedia || {})
            .forEach((post) => {
               const parts = post.permalink.split("/");
               const shortcode = parts[parts.length - 2];
               const meta = instagramData.data.post_meta[shortcode];
               if (meta) {
                  const currentEngagement = meta.like_count > 0 ? meta.like_count + (meta.comment_count || 0) : 0;
                  if (currentEngagement > 0) {
                     engagement.push(currentEngagement);
                  }
               }
            });
         let didUseEngagementData = false;
         engagement.sort((a, b) => b - a);
         if (engagement.length > 10) {
            engagement.shift(); // Removes the first (highest) element
            engagement.pop(); // Removes the last (lowest) element
            engagement.pop(); // Removes the second to last element
            const avgEngagement = Math.round((engagement.reduce((sum, item) => sum + item, 0)/engagement.length) * 10) / 10;
            totalReach += avgEngagement * 8;
            didUseEngagementData = true;
         }
         if (!didUseEngagementData) {
            totalReach += Math.round(Math.sqrt(instagramData.data.followers) * 20);
         }
      }
   }
   if (tiktokData?.recentMedia) {
      const views = [];
      Object.values(tiktokData.recentMedia || {})
         .forEach((post) => views.push(post.view_count));
      views.sort((a, b) => b - a);
      if (views.length > 3) {
         views.shift(); // Removes the first (highest) element
         views.pop(); // Removes the last (lowest) element
         views.pop(); // Removes the second to last element
      }
      totalReach += Math.round((views.reduce((sum, item) => sum + item, 0)/views.length) * 10) / 10;
   }
   if (formatted) {
      return NumberFormatter(Math.round(totalReach));
   } else {
      return Math.round(totalReach);
   }
}

export function TrendingCalculator(instagramData, tiktokData) {
   if (instagramData?.recentMedia && instagramData?.recentMedia.length > 3 && instagramData.data?.post_meta) {
      const calcEngagement = (media) => {
         const engagement = [];
         Object.values(media || {})
            .forEach((post) => {
               const parts = post.permalink.split("/");
               const shortcode = parts[parts.length - 2];
               const meta = instagramData.data.post_meta[shortcode];
               if (meta) {
                  engagement.push(Math.max((meta.like_count || 0), 0) + (meta.comment_count || 0));
               }
            });
         return engagement.reduce((sum, item) => sum + item, 0)/engagement.length;
      };
      if (calcEngagement(instagramData.recentMedia.slice(0,3)) > calcEngagement(instagramData.recentMedia)) {
         return true;
      }
   }
   if (tiktokData?.recentMedia) {
      const calcEngagement = (media) => {
         const views = [];
         Object.values(media || {})
            .forEach((post) => views.push(post.view_count));
         return views.reduce((sum, item) => sum + item, 0)/views.length;
      };
      if (calcEngagement(tiktokData.recentMedia.slice(0,3)) > calcEngagement(tiktokData.recentMedia)) {
         return true;
      }
   }
   return false;
}

import React, { useEffect, useState } from 'react';
import '../css/web.css';

const URLReviewer = ({ igURL, ttURL, yelpURL, approvalHandler, rejectionHandler }) => {
  const [showing, setShowing] = useState(true);

  const handleApprove = (() => {
    approvalHandler();
    setShowing(false);
  });

  const handleReject = (() => {
    rejectionHandler();
    setShowing(false);
  });

  useEffect(() => {
    setShowing(true);
  }, [igURL, ttURL, yelpURL]);

  return (
    <div>
      {(igURL || ttURL || yelpURL) && showing &&
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div style={{
            position: 'relative',
            width: '80vw',
            height: '80vh',
            zIndex: 0,
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ width: '100%', height: '60vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                {igURL &&
                  <span>
                    <p>Instagram:&ensp;</p>
                    <a href={igURL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>
                      {igURL}
                    </a>
                  </span>
                }
                {ttURL &&
                  <span>
                    <p>Tiktok:&ensp;</p>
                    <a href={ttURL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>
                      {ttURL}
                    </a>
                  </span>
                }
                {yelpURL &&
                  <span>
                    <p>Review:&ensp;</p>
                    <a href={yelpURL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>
                      {yelpURL}
                    </a>
                  </span>
                }
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '20px', padding: '20px' }}>
                <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleApprove}>Approve</button>
                <button className="btn diamond-btn diamond-btn--sm" onClick={handleReject}>Reject</button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default URLReviewer;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { database } from './lib/firebase';
import { ref, onValue, update, push, off } from "firebase/database";
import { toast } from 'react-toastify';
import CampaignCreate from './components/campaignCreate';
import 'react-datetime/css/react-datetime.css';
import './css/web.css';

const CreateCampaign = ({user}) => {
  const navigate = useNavigate();

  const [businessUid, setBusinessUid] = useState('');
  const [businessData, setBusinessData] = useState({});

  const handleError = ((errors) => {
    if (errors.length) {
      toast.error(errors[0]);
    }
  });

  const handleBusinessInfoUpdate = ((data, errors) => {
    if (errors.length) {
      toast.error(errors[0]);
      return;
    }
    if (data.business_name) {
      update(ref(database, `users/${businessUid}`), {
        business_name: data.business_name,
      });
    }
    update(ref(database, `businesses/${businessUid}`), data);
  });

  const handleCreateCampaign = ((data, errors) => {
    if (errors.length) {
      toast.error(errors[0]);
      return;
    }
    const campaignId = push(ref(database, `campaigns/${businessUid}`), data).key;
    navigate(`/campaign/${businessUid}/${campaignId}`);
  });

  useEffect(() => {
    let subscriptions = [];
    const fetchData = async (uid) => {
      const businessesDbRef = ref(database, `businesses/${uid}`);
      const businessesDbUnsubscribe = onValue(businessesDbRef, (snapshot) => {
        const business = snapshot.val();
        if (business) {
          setBusinessData(business);
        }
      });
      subscriptions.push({ ref: businessesDbRef, unsubscribe: businessesDbUnsubscribe });
    };
    if (user) {
      setBusinessUid(user.uid);
      fetchData(user.uid);
    }
    return () => {
      for (let { ref, unsubscribe } of subscriptions) {
        off(ref, 'value', unsubscribe);
      }
      subscriptions = []
    };
  }, [user]);

  return (
    <div className="content-container">
      <CampaignCreate
        existingBusinessData={businessData}
        businessUid={businessUid}
        onError={(errors) => handleError(errors)}
        onBusinessComplete={(props) => handleBusinessInfoUpdate(props.data, props.errors)}
        onCampaignComplete={(props) => handleCreateCampaign(props.data, props.errors)}
      />
    </div>
  );
}

export default CreateCampaign;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFeatureFlag, FeatureFlag } from './lib/FeatureFlags.jsx';
import { database } from './lib/firebase';
import { ref, get } from "firebase/database";
import PaymentsListItem from './components/paymentsListItem';
import './css/web.css';

const Payments = ({user}) => {
  const isInternalUser = useFeatureFlag(FeatureFlag.isInternalUser);

  const location = useLocation();

  const [data, setData] = useState({
    subscriptions: null,
    transactions: null,
    payments: null,
  });

  const [earnings, setEarnings] = useState({
    pending: null,
    lifetime: null,
  });

  const [businesses, setBusinesses] = useState([]);

  const params = new URLSearchParams(location.search);
  const queryUid = params.get('uid');

  useEffect(() => {
    if (data.payments) {
      const calculateEarningsWithFee = (lineItem) => {
        const baseAmount = lineItem.amount_total + Number(lineItem.metadata?.balance_applied || 0);
        const feePercentage = lineItem.metadata?.custom_creator_fee_percentage !== undefined
                             ? lineItem.metadata.custom_creator_fee_percentage / 100 
                             : lineItem.metadata?.is_hire_link ? 0 : 0.1; // Default to 10% fee
        return baseAmount * (1 - feePercentage);
      };

      const pending = data.payments.filter((payment) => payment.status === 'complete').reduce((earnings, payment) => 
        earnings + payment.line_items.reduce((price, lineItem) => 
          price + (lineItem.metadata?.is_fee === 'false' && !lineItem.price.payout_status ? calculateEarningsWithFee(lineItem) : 0), 0), 0);

      const lifetime = data.payments.filter((payment) => payment.status === 'complete').reduce((earnings, payment) => 
        earnings + payment.line_items.reduce((price, lineItem) => 
          price + (lineItem.metadata?.is_fee === 'false' && lineItem.price.payout_status === 'paid' ? calculateEarningsWithFee(lineItem) : 0), 0), 0);

      setEarnings({
        pending: pending,
        lifetime: lifetime,
      });
    }
  }, [data.payments]);

  useEffect(() => {
    var subscriptionsData = null;
    var transactionsData = null;

    const updateDatasource = (uid) => {
      if (!subscriptionsData || !transactionsData) {
        return
      }
      const subscriptions = Object.values(subscriptionsData).reverse().filter((subscriptions) => Object.values(subscriptions).filter((subscription) => subscription.business_uid.includes(uid)).length > 0);
      const transactions = Object.values(transactionsData).reverse().filter((transaction) => transaction.business_uid === uid);
      const payments = Object.values(transactionsData).reverse().filter((transaction) => transaction.line_items.some(lineItem => lineItem.metadata?.uid === uid && lineItem.metadata.is_fee === 'false' && lineItem.price.payout_status !== 'void'));
      const datasource = {
        subscriptions: subscriptions,
        transactions: transactions,
        payments: payments,
      }
      setData(datasource);
    };

    const fetchData = async (uid) => {
      get(ref(database, `subscriptions`)).then((snapshot) => {
        subscriptionsData = snapshot.val();
        updateDatasource(uid);
      });

      get(ref(database, `transactions`)).then((snapshot) => {
        transactionsData = snapshot.val();
        updateDatasource(uid);
      });

      get(ref(database, 'businesses')).then((snapshot) => {
        const businesses = snapshot.val();
        setBusinesses(businesses);
      });
    };
    if (user?.uid) {
      if (isInternalUser && queryUid) {
        fetchData(queryUid);
      } else {
        fetchData(user.uid);
      }
    }
  }, [user.uid, queryUid, isInternalUser]);

  if (!data.subscriptions?.length && !data.transactions?.length && !data.payments?.length) {
    return (
      <div className="content-container" style={{ paddingTop: '100px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src='../images/cta-image-1.png' alt="No Payments CTA" style={{ padding: '20px', width: '250px', height: '250px', objectFit: 'cover' }}/>
          You do not have any payments yet!
        </div>
      </div>
    );
  }

  return (
    <div className="content-container" style={{ paddingTop: '100px' }}>
      {(earnings.pending > 0 || earnings.lifetime > 0) &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
            <span className="big-data-number" style={{ color: '#edbb11', fontSize: '42px', fontWeight: 'bold' }}>${(earnings.pending/100).toFixed(2)}</span>
            Pending Earnings
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
            <span className="big-data-number" style={{ color: 'green', fontSize: '42px', fontWeight: 'bold' }}>${(earnings.lifetime/100).toFixed(2)}</span>
            Total Earnings
          </div>
        </div>
      }
      {data.subscriptions && data.subscriptions.length > 0 &&
        <div className="list-container">
          <h3>Recurring Subscriptions</h3>
          {data.subscriptions.map((subscription, index) => {
            return Object.values(subscription).reverse().map((data, dataIndex) => {
              return (
                <PaymentsListItem
                  key={(index * 10) + dataIndex}
                  date={new Date(data.created * 1000).toLocaleDateString()}
                  title={`${!data.livemode ? '[Internal] ' : ''}Subscription ${data.subscription.slice(-5)}`}
                  total={data.total}
                  currency={data.currency}
                  status={data.status}
                  detailedContent={
                    <a href={data.hosted_invoice_url} target="_blank" rel="noopener noreferrer" className="payments-link">
                      View Invoice
                    </a>
                  }
                />
              );
            });
          })}
        </div>
      }
      {data.transactions && data.transactions.length > 0 &&
        <div className="list-container">
          <h3>All Transactions</h3>
          {data.transactions.filter((transaction) => transaction.line_items[0]?.metadata?.is_balance !== 'true' && transaction.line_items[0]?.metadata?.is_subscription !== 'true').sort((a,b) => b.created - a.created).map((transaction, index) => {
            return (
              <PaymentsListItem
                key={index}
                date={new Date(transaction.created * 1000).toLocaleDateString()}
                title={`${!transaction.livemode ? '[Internal] ' : ''}${transaction.line_items[0]?.price.type === 'recurring' ? 'New Subscription Creation' : 'Campaign Payment'}`}
                total={transaction.amount_total}
                currency={transaction.currency}
                status={transaction.status}
                detailedContent={transaction.line_items.map((lineItem, lineItemIndex) => (
                  <div key={lineItemIndex}>
                    {'>'} {lineItem.description} - ${lineItem.amount_total/100}
                  </div>
                ))}
              />
            );
          })}
        </div>
      }
      {data.payments && data.payments.length > 0 &&
        <div className="list-container">
          <h3>Payments</h3>
          {data.payments.filter((payment) => payment.status === 'complete').sort((a,b) => b.created - a.created).map((payment, index) => {
            return payment.line_items.filter((lineItem) => lineItem.metadata?.is_fee === 'false').map((lineItem, lineItemIndex) => {
              const baseAmount = lineItem.amount_total + Number(lineItem.metadata?.balance_applied || 0);
              const feePercentage = lineItem.metadata?.custom_creator_fee_percentage !== undefined
                     ? lineItem.metadata.custom_creator_fee_percentage
                     : lineItem.metadata?.is_hire_link ? 0 : 10; // Default to 10% fee
              const netAmount = baseAmount * (1 - (feePercentage / 100));
              return <PaymentsListItem
                key={(index * 10) + lineItemIndex}
                date={new Date(payment.created * 1000).toLocaleDateString()}
                title={`${!payment.livemode ? '[Internal] ' : ''}${lineItem.metadata?.is_hire_link ? 'Paid collab via your profile link' : (businesses && businesses[payment.business_uid]?.business_name) || 'Pobbles Campaign'}`}
                total={netAmount}
                currency={lineItem.currency}
                status={lineItem.price.payout_status || 'pending'}
                detailedContent={
                  <div key={lineItemIndex}>
                    {'>'} Collab Rate: ${(baseAmount/100).toFixed(2)}
                    <br />
                    {feePercentage !== 0 && `> ${feePercentage}% Marketplace Fee: -$${(baseAmount/100 * (feePercentage / 100)).toFixed(2)}`}
                  </div>
                }
              />
            });
          })}
        </div>
      }
    </div>
  );
}

export default Payments;

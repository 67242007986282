import './css/web.css';

const PobCancel = () => {
  const pobProfilePicUrl = '../images/default-faqbot-pfp.png';

  return (
    <div className="content-container bg-beige" style={{ height: '100dvh' }}>
      <div className="form-container">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '20px 0px', gap: '20px' }}>
          <div className="profilePic-container" style={{ cursor: 'unset' }}>
            <img src={pobProfilePicUrl} alt="Profile" className="profilePic" />
          </div>
          <h2>Payment Not Completed</h2>
          <b>We Missed You!</b> It looks like your payment was canceled or incomplete. Try again to start working with Pob.
          <br/><br/><br/>
          <i>Need help? Contact us anytime at <a href="mailto:hello@pobbles.co">hello@pobbles.co</a></i>
        </div>
      </div>
    </div>
  );
}

export default PobCancel;

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import '../css/web.css';

const StarRating = ({ name, onReviewSubmit, onClose }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [review, setReview] = useState('');

  const handleSubmit = () => {
    if (rating === 0) {
      toast.error("Please select a rating before submitting.");
      return
    }
    onReviewSubmit({ rating, review });
    onClose();
  };

  useEffect(() => {
    setRating(0);
    setHover(0);
    setReview('');
  }, [name]);

  return (
    <div>
      {name &&
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div style={{
            position: 'relative',
            width: '80vw',
            height: '80vh',
            zIndex: 0,
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <h1>Review: {name}</h1>
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={index <= (hover || rating) ? 'star-on' : 'star-off'}
                    onClick={() => setRating(index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
            <textarea
              placeholder="Leave a review..."
              value={review}
              onChange={(e) => setReview(e.target.value)}
              style={{ width: '80%', marginTop: '10px' }}
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '20px', padding: '20px' }}>
              <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleSubmit}>Submit</button>
              <button className="btn diamond-btn diamond-btn--sm" onClick={onClose}>Close</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default StarRating;

import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

const ConfettiAnimation = ( { type } ) => {
  useEffect(() => {
    const end = Date.now() + 1000;
    const scalar = 2;

    function frame() {
      if (type === 'cash') {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          shapes: [
            confetti.shapeFromText({ text: '💰', scalar }),
            confetti.shapeFromText({ text: '💸', scalar }),
            confetti.shapeFromText({ text: '💲', scalar }),
            confetti.shapeFromText({ text: '🤑', scalar }),
            confetti.shapeFromText({ text: '💵', scalar }),
          ],
          scalar
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          shapes: [
            confetti.shapeFromText({ text: '💰', scalar }),
            confetti.shapeFromText({ text: '💸', scalar }),
            confetti.shapeFromText({ text: '💲', scalar }),
            confetti.shapeFromText({ text: '🤑', scalar }),
            confetti.shapeFromText({ text: '💵', scalar }),
          ],
          scalar
        });
      } else {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: ['#2a5750', '#edbb11']
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: ['#2a5750', '#edbb11']
        });
      }

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }

    frame();

    // Optionally, if you want to clear the animation when the component unmounts:
    return () => {
      cancelAnimationFrame(frame);
    };

  }, [type]);

  return <div style={{position: 'relative', width: '100%', height: '100%'}}></div>;
};

export default ConfettiAnimation;
import React, { useEffect, useState } from 'react';
import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { setDefaults } from 'react-geocode';

setDefaults({
  key: "AIzaSyCbv8jF4xffo3ypjqGz0N_Z4aXawtJyYIU",
  language: "en",
  region: "en",
});

const libraries = ["places"];

const MapLocationSelection = ({ existingLocation, onLocationChange }) => {
  const [location, setLocation] = useState('');
  const [searchBoxRef, setSearchBoxRef] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCbv8jF4xffo3ypjqGz0N_Z4aXawtJyYIU",
    libraries: libraries,
  });

  const onPlacesChanged = () => {
    const places = searchBoxRef.getPlaces();
    if (places) {
      const lat = places[0].geometry.location.lat();
      const lng = places[0].geometry.location.lng();
      onLocationChange({ lat, lng, address: places[0].formatted_address });
    }
  };

  useEffect(() => {
    if (existingLocation) {
      setLocation(existingLocation);
    }
  }, [existingLocation]);

  return isLoaded &&
    <StandaloneSearchBox
      onLoad={ref => setSearchBoxRef(ref)}
      onPlacesChanged={onPlacesChanged}
    >
      <input
        type="text"
        value={location}
        placeholder="123 Main St."
        className="input-field"
        style={{ width: "100%" }}
        onChange={(e) => setLocation(e.target.value)}
      />
    </StandaloneSearchBox>
  ;
}

export default MapLocationSelection;

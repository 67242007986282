export default function TransactionFactory(amount, currency="usd", business_uid, creator_name, creator_uid) {
  return {
    "amount_total": amount,
    "business_uid": business_uid,
    "created": Math.floor(Date.now() / 1000),
    "currency": currency,
    "id": `balance_${business_uid}_${Math.floor(Date.now() / 1000)}`,
    "line_items": [
      {
        "amount_discount": 0,
        "amount_subtotal": amount,
        "amount_tax": 0,
        "amount_total": amount,
        "currency": currency,
        "description": `Deposit - Creator Collab x ${creator_name}`,
        "id": "balance",
        "metadata": {
          "is_subscription": "true",
          "is_fee": "false",
          "uid": creator_uid,
        },
        "object": "item",
        "price": {
          "active": true,
          "billing_scheme": "per_unit",
          "created": Math.floor(Date.now() / 1000),
          "currency": currency,
          "id": "balance",
          "livemode": true,
          "object": "price",
          "product": "balance",
          "tax_behavior": "unspecified",
          "type": "one_time",
          "unit_amount": amount,
          "unit_amount_decimal": amount
        },
        "quantity": 1
      }
    ],
    "livemode": true,
    "payment_intent": "balance",
    "payment_status": "paid",
    "status": "complete"
  }
}

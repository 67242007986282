import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { database } from './lib/firebase';
import { ref, onValue, get, off } from "firebase/database";
import axios from 'axios';
import { ReachCalculator, TrendingCalculator } from './lib/ReachCalculator.jsx';
import InfluencerDisplay from './components/influencerDisplay';
import './css/web.css';

const Browse = ({user}) => {
  const [data, setData] = useState([]);

  const [campaignData, setCampaignData] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const businessUid = params.get('uid');
  const campaignId = params.get('id');

  const defaultProfilePicUrl = '../images/default-girl-pfp.png';

  const handleBannerDismiss = () => {
    navigate(location.pathname);
  }

  useEffect(() => {
    let subscriptions = [];
    const source = axios.CancelToken.source();
    var userData = null;
    var publicUsersData = null;
    var influencersData = null;

    const updateDatasource = () => {
      if (!userData || !influencersData || !publicUsersData) {
        return
      }
      const datasource = Object.entries(influencersData)
        .map(([uid, influencerData], index) => (
          {
            uid: uid,
            shouldHide: influencerData.should_hide || false,
            shouldShow: influencerData.should_show || false,
            publicName: `${publicUsersData[uid]}.` || '',
            displayName: influencerData.display_name || '',
            username: influencerData.username,
            profilePicture: influencerData.profile_pic_url || defaultProfilePicUrl,
            market: influencerData.market,
            contentTags: influencerData.tags,
            compensationTags: influencerData.compensation && influencerData.compensation.tags,
            compensationAmount: influencerData.compensation && influencerData.compensation.amount,
            reach: ReachCalculator(influencerData.cached, false),
            isTrending: TrendingCalculator(influencerData.cached?.ig, influencerData.cached?.tt), 
            instagramData: influencerData.cached?.ig,
            tiktokData: influencerData.cached?.tt,
            yelpData: influencerData.cached?.yelp,
            platforms: (influencerData.cached ? [influencerData.cached.yelp && influencerData.cached.yelp.data.eliteYears && 'Yelp', influencerData.cached.tt && 'TT', influencerData.cached.ig && 'IG'] : []),
            isPobblesPro: influencerData.is_pobbles_pro || false,
          }
        ))
        .filter(influencer => userData.market === influencer.market || influencer.market === 'Sacramento')  // TODO: fix to not always show sac creators
        .filter(influencer => !influencer.shouldHide)
        .filter(influencer => influencer.reach > 0 || influencer.shouldShow);
      setData(datasource);
    };

    const fetchData = async (uid) => {
      const userDbRef = ref(database, `users/${uid}`);
      const usersDbUnsubscribe = onValue(userDbRef, (snapshot) => {
        userData = snapshot.val();
        updateDatasource();
      });
      subscriptions.push({ ref: userDbRef, unsubscribe: usersDbUnsubscribe });

      get(ref(database, `public_users`)).then((snapshot) => {
        publicUsersData = snapshot.val();
        updateDatasource();
      });

      get(ref(database, `influencers`)).then((snapshot) => {
        influencersData = snapshot.val();
        updateDatasource();
      });

      if (campaignId) {
        const campaignsDbRef = ref(database, `campaigns/${businessUid}/${campaignId}`);
        const campaignsDbUnsubscribe = onValue(campaignsDbRef, (snapshot) => {
          setCampaignData(snapshot.val());
        });
        subscriptions.push({ ref: campaignsDbRef, unsubscribe: campaignsDbUnsubscribe });
      }
    };
    if (user) {
      fetchData(user.uid);
    }
    return () => {
      source.cancel();
      for (let { ref, unsubscribe } of subscriptions) {
        off(ref, 'value', unsubscribe);
      }
      subscriptions = []
    };
  }, [user, businessUid, campaignId]);

  // TODO: improve this error messaging
  if (businessUid && campaignId && !campaignData) {
    return (
      <div className="content-container" style={{ textAlign: "center", padding: '100px' }}>
        Oops. Looks like you have either stumbled upon an invalid campaign, or do not have permissions to browse creators for the campaign.
      </div>
    );
  }

  return (
    <div className="content-container">
      {campaignId &&
        <div style={{ backgroundColor: '#ffecb3', textAlign: 'center', padding: '10px', position: 'relative' }}>
          <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/campaign/${businessUid}/${campaignId}`)}>Browsing creators for: <u><b>{campaignData.campaign_name}</b></u></div>
          <button 
            style={{ color: '#000000', position: 'absolute', right: '25px', top: '10px', cursor: 'pointer' }} 
            onClick={handleBannerDismiss}
          >
            x
          </button>
        </div>
      }
      <InfluencerDisplay
        data={data}
        businessUid={businessUid}
        campaignId={campaignId}
        campaignData={campaignData}
      />
    </div>
  );
}

export default Browse;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { amplitude } from './lib/amplitude.jsx';
import { database, auth } from './lib/firebase';
import { ref, onValue } from "firebase/database"
import axios from 'axios';
import { signInWithPhoneNumber, RecaptchaVerifier } from '@firebase/auth';
import { toast } from 'react-toastify';
import VerifyPhone from './components/verifyPhone';
import VerifyEmail from './components/verifyEmail';
import * as constants from './constants.jsx';
import './css/web.css';

function SignUp() {
  const [type, setType] = useState(null);

  const [businessName, setBusinessName] = useState(''); // business only
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [preferredContactMethod, setPreferredContactMethod] = useState(type === 'business' ? 'email' : 'phone');
  const [compensationAmount, setCompensationAmount] = useState(''); // creator only
  const [selectedMarket, setSelectedMarket] = useState('');
  const [selectedMarketRegion, setSelectedMarketRegion] = useState(''); // creator only
  const [tocAgreement, setTocAgreementChecked] = useState(false);

  const [availableMarketData, setAvailableMarketData] = useState({});
  const [confirmation, setConfirmation] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const marketsRef = ref(database, 'markets')
    return onValue(marketsRef, (snapshot) => {
      const markets = snapshot.val();
      setAvailableMarketData(Object.keys(markets).reduce((acc, market) => {
        acc[market] = markets[market].regions;
        return acc;
      }, {}));
    });
  }, []);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'button-container', {
      'size': 'invisible',
      /*
      Motivation for the code:
      1. https://github.com/firebase/firebase-js-sdk/issues/3356
      2. https://github.com/jsardev/reaptcha/issues/218
      3. https://github.com/dozoisch/react-google-recaptcha/issues/129

      Cannot read property 'style' of null was the error message, and to get/test this error do the following steps:
      1. Enter a number, and an invalid number ideally
      2. Keep trying to submit till you see the captcha of image selector where it asks you to select buses, etc
      3. Once you click on Verify, it will show an error screen, and in some cases even when number was valid.
      
      Since the error was not due to any other code in this file, I decided to reset the captcha in each type of callback, and this seems to have solved the error
      */
      callback: () => {
        window.recaptchaVerifier.recaptcha.reset();
      },
      'expired-callback': () => {
        window.recaptchaVerifier.recaptcha.reset();
      },
      'error-callback': () => {
        window.recaptchaVerifier.recaptcha.reset();
      },
    });
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
      }
    }
  }, []);

  useEffect(() => {
    return auth.onAuthStateChanged(user => {
      if (user) {
        amplitude.setUserId(user.uid);
        navigate("/campaigns");
      }
    });
  }, [navigate, type]);

  const checkAccount = async () => {
    let data = {
      'first_name': firstName.trim(),
      'last_name': lastName.trim(),
      'email': email,
      'phone_number': sanitizePhoneNumber(phone),
      'preferred_contact_method': preferredContactMethod,
      'market': selectedMarket
    };
    let body = {};
    if (type === 'business') {
      data.business_name = businessName;
      body = { email, data };
    } else {
      data.compensation_amount = compensationAmount;
      if (selectedMarketRegion) {
        data.market_region = selectedMarketRegion;
      }
      body = { phone: sanitizePhoneNumber(phone), data };
    }
    try {
      await axios.post(`${constants.backendDomain}/user/auth`, body,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
      return true;
    } catch (error) {
      if (error.response?.status === 400 && error.response?.data === 'account-already-exists') {
        toast.error('Oops! Looks like an account already exists with this info. Try logging in using the credentials provided.');
      } else {
        toast.error(error.response?.data || 'Oops! Something went wrong. Please try again later.');
      }
      return false;
    }
  };

  const signInWithPhone = async (phone) => {
    signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        setConfirmation(confirmationResult);
      }).catch((error) => {
        toast.error("Could not send SMS to phone. Please reload the page and try again.");
        if (window.recaptchaVerifier) {
          window.recaptchaVerifier.render().then(function(widgetId) {
            window.grecaptcha.reset(widgetId);
          });
        }
      });
  };

  function sanitizePhoneNumber(input) {
    const sanitized = input.replace(/\D/g, '');
    if (sanitized.length === 10) {
      return '+1' + sanitized;
    } else if (sanitized.length === 11 && sanitized.startsWith('1')) {
      return '+' + sanitized;
    } else {
      return input;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (type === 'business') {
      if (!businessName) {
        toast.error("Business name is required!");
        return;
      }
    }

    // Simple validation for firstName and lastName
    if (!firstName.trim() || !lastName.trim()) {
        toast.error("First and Last names are required!");
        return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        toast.error("Please enter a valid email address!");
        return;
    }

    // Phone validation for US numbers
    const formatted = sanitizePhoneNumber(phone);
    const phoneRegex = /^(\+1|1)?\s?-?\.?\(?\d{3}\)?\s?-?\.?\d{3}\s?-?\.?\d{4}$/;
    if (!phoneRegex.test(formatted)) {
        toast.error("Please enter a valid phone number!");
        return;
    }
    setPhone(formatted)

    if (type === 'influencer' && !compensationAmount) {
      toast.error("Please set your preferred collab rate!");
      return;
    };

    if (!selectedMarket) {
      toast.error("Please select your location!");
      return;
    }

    if (type === 'influencer' && !selectedMarketRegion && selectedMarket !== 'Other' && availableMarketData[selectedMarket]) {
      toast.error("Please select the region closest to where you primarily create content!");
      return;
    };

    if (!tocAgreement) {
      toast.error("You must agree to the terms and conditions and privacy policy to continue.")
      return;
    }

    checkAccount()
      .then((didSucceed) => {
        if (didSucceed) {
          if (type === 'influencer') {
            signInWithPhone(formatted)
          } else {
            setConfirmation(email);
          }
        }
      });
  };

  return (
    <div className="content-container bg-beige" style={{ 'position': 'relative', 'zIndex': '0' }}>
      <div className="container" style={{ height: 'calc(100vh - 65px)' }}>
        <div className="splash" style={{ height: '100%' }}>
          <img src='../images/splash-business.png' alt="Splash" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '50%' }}>
          { confirmation
            ? type === 'influencer'
              ? <VerifyPhone
                  phone={phone}
                  confirmation={confirmation}
                />
              : <VerifyEmail
                  email={email}
                  routeToWelcome={true}
                />
            : <div className="form-container">
                <h2>Start collaborating today!</h2>
                <p>Already have an account? <a href="/#/login">Login</a></p>
                <br/>
                <span style={{ width: '100%', textAlign: 'center', fontSize: '1.2em' }}>I am a:</span>
                <div className="tag-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                  <div
                    className={`bundle ${type === 'business' ? 'selected' : ''}`}
                    onClick={() => setType('business')}
                    style={{ display: 'flex', flexDirection: 'column', width: '250px', alignItems: 'center', textAlign: 'center' }}
                  >
                    <h2>Business</h2>
                  </div>
                  <div
                    className={`bundle ${type === 'influencer' ? 'selected' : ''}`}
                    onClick={() => setType('influencer')}
                    style={{ display: 'flex', flexDirection: 'column', width: '250px', alignItems: 'center', textAlign: 'center' }}
                  >
                    <h2>Creator</h2>
                  </div>
                </div>
                <br/>
                <form>
                  {type === 'business' && 
                    <input
                      type="text"
                      name="business_name"
                      placeholder="Business Name"
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                      required
                    />
                  }
                  {type &&
                    <>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <input
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                          style={{ width: '50%' }}
                        />
                        <input
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                          style={{ width: '50%' }}
                        />
                      </div>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ width: '150px' }}>
                          Preferred Contact Method:
                        </label>
                        <select
                          name="preferred_contact_method"
                          value={preferredContactMethod}
                          onChange={(e) => setPreferredContactMethod(e.target.value)}
                          required
                        >
                          <option value="email">Email</option>
                          <option value="phone">Phone</option>
                        </select>
                      </div>
                      {type === 'influencer' &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <label style={{ width: '150px' }}>
                            Set your collab rate ($):
                          </label>
                          <input
                            type="number"
                            min="0"
                            max="1000000"
                            step="1"
                            value={compensationAmount}
                            placeholder="100"
                            onChange={(e) => setCompensationAmount(e.target.value)}
                            required
                          />
                        </div>
                      }
                      <select
                        name="location"
                        value={selectedMarket}
                        onChange={(e) => setSelectedMarket(e.target.value)}
                        required
                      >
                        <option value="" disabled>Select your location</option>
                        {Object.keys(availableMarketData).map((market, index) => (
                          <option key={index} value={market}>{market}</option>
                        ))}
                        <option value="Other">Other</option>
                      </select>
                      {type === 'influencer' && selectedMarket && selectedMarket !== 'Other' && availableMarketData[selectedMarket] &&
                        <select
                          name="region"
                          value={selectedMarketRegion}
                          onChange={(e) => setSelectedMarketRegion(e.target.value)}
                          required
                        >
                          <option value="" disabled>Select the region where you create the majority of your content:</option>
                          {availableMarketData[selectedMarket].map((region, index) => (
                            <option key={index} value={region}>{region}</option>
                          ))}
                        </select>
                      }
                      <label
                        style= {{ textAlign: 'center', width: 'revert' }}>
                        <input
                            type="checkbox"
                            checked={tocAgreement}
                            onChange={e => setTocAgreementChecked(e.target.checked)}
                            style={{ margin: '10px' }}
                        />
                        I agree to the <a href="https://pobbles.co/termsandconditions" target="_blank" rel="noopener noreferrer">terms and conditions</a> and <a href="https://pobbles.co/privacy" target="_blank" rel="noopener noreferrer">privacy policy</a>.
                      </label>
                    </>
                  }
                  <button
                    id="button-container"
                    className="btn diamond-btn diamond-btn--sm diamond-btn--yellow"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ display: type ? "inline-block" : "none" }}
                  >
                    Sign Up
                  </button>
                </form>
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default SignUp;

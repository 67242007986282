import ConfettiAnimation from './components/confetti';
import './css/web.css';

const PobSuccess = () => {
  const pobProfilePicUrl = '../images/default-faqbot-pfp.png';

  return (
    <div className="content-container bg-beige" style={{ height: '100dvh' }}>
      <div className="form-container">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '20px 0px', gap: '20px' }}>
          <div className="profilePic-container" style={{ cursor: 'unset' }}>
            <img src={pobProfilePicUrl} alt="Profile" className="profilePic" />
          </div>
          <h2>Payment Successful</h2>
          <b>You're all set</b> You can now start working with Pob. We can't wait to see what you create!
          <br/><br/><br/>
          <i>Need help? Contact us anytime at <a href="mailto:hello@pobbles.co">hello@pobbles.co</a> for live support.</i>
        </div>
      </div>
      <ConfettiAnimation />
    </div>
  );
}

export default PobSuccess;

import { NumberFormatter } from '../lib/NumberFormatter.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import * as constants from '../constants.jsx';
import '../css/web.css';

const CampaignCardDisplay = ({ campaigns, influencerData, businessData, shouldShowCreate, type, influencerUid, onClick }) => {
  return (
    <div className="campaign-container">
      {shouldShowCreate &&
        <div key={-1} className="campaign-card hoverable" style={{ textAlign: 'center', padding: '150px 0px', borderStyle: 'dashed' }} onClick={() => onClick()}>
          <span><FontAwesomeIcon icon={faPlus}/> Create New Campaign</span>
        </div>
      }
      {campaigns && campaigns.map(([id, campaign], index) => (
        <div key={index} className="campaign-card hoverable" onClick={() => onClick(campaign.business_id, id)}>
          <div className="card-header">
            <h3>{type && type === 'influencer' && businessData && businessData[campaign.business_id]?.business_name && `${businessData[campaign.business_id].business_name}: `} {campaign.campaign_name}</h3>
            <img src={campaign.campaign_picture || constants.defaultGrayPlaceholder} alt={campaign.campaign_name} />
          </div>
          <div className="card-body">
            {type && type === 'business' && campaign.status && <div><b>Status:</b> <span className='good-color'>{campaign.status[0].toUpperCase() + campaign.status.substring(1)}</span></div>}
            {type && type === 'influencer' && influencerUid && campaign.influencers && Object.keys(campaign.influencers).includes(influencerUid) && campaign.influencers[influencerUid].status && <div><b>Status:</b> <span className='good-color'>{campaign.influencers[influencerUid].status[0].toUpperCase() + campaign.influencers[influencerUid].status.substring(1)}</span></div>}
            <br />
            {(campaign.meet_date || campaign.post_date) &&
              <div>
                {campaign.meet_date && <div><b>Meet/Shoot By Date: </b>{new Date(campaign.meet_date).toLocaleDateString()}</div>}
                {campaign.post_date && <div><b>Post By Date: </b>{new Date(campaign.post_date).toLocaleDateString()}</div>}
              </div>
            }
            {campaign.location && <div><b>Location:</b> {campaign.location.address}</div>}
          </div>
          {campaign.influencers && influencerData && type && type === 'business' &&
            <div className="card-footer">
              <span>Creators: {Object.values(campaign.influencers).filter(influencer => influencer.status !== 'new' && influencer.status !== 'invited').length || 0} active / {Object.values(campaign.influencers).length || 0} total</span>
              <br/>
              <span style={{ fontSize: '1.2em' }}>Est. Project Reach: <b>{NumberFormatter(Object.entries(campaign.influencers).reduce((reach, influencer) => reach + (influencerData.find((element) => element.uid === influencer[0]) !== undefined ? influencerData.find((element) => element.uid === influencer[0]).reach : 0), 0))} viewers</b></span>
            </div>
          }
          {type && type === 'influencer' && influencerUid && campaign.influencers && Object.keys(campaign.influencers).includes(influencerUid) && campaign.influencers[influencerUid].rate &&
            <div className="card-footer">
              <div><b>Offer:</b></div>
              {campaign.targeted_compensation.includes('paid') && campaign.influencers[influencerUid]?.rate && <div>Payment: ${campaign.influencers[influencerUid].rate}</div>}
              {campaign.targeted_compensation.includes('freebies') && campaign.freebie_comp_description && <div>Freebies: {campaign.freebie_comp_description}</div>}
            </div>
          }
        </div>
      ))}
    </div>
  );
}

export default CampaignCardDisplay;

import React, { useRef, useState, useEffect } from 'react';
import { auth, storage } from '../lib/firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Datetime from 'react-datetime';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import MapLocationSelection from './mapLocationSelection';
import * as constants from '../constants.jsx';

const Step = ({ stepName, children, isActive }) => {
  return (
    <div style={{ display: isActive ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
      {children}
    </div>
  );
};

const ProgressBar = ({ steps, currentStep }) => {
  return (
    <div style={{ display: 'flex', width: '400px', margin: '0 auto', marginBottom: '20px', position: 'relative', borderRadius: '5px', overflow: 'hidden' }}>
      <div style={{ display: 'flex', width: '100%', position: 'absolute', zIndex: 1 }}>
        {steps.map((step, index) => (
          <div key={step.name} style={{ flex: 1, textAlign: 'center', borderRight: index !== steps.length - 1 ? '2px solid grey' : 'none' }}>
            <div
              style={{
                height: '10px',
                backgroundColor: index < currentStep ? 'green' : 'lightgrey',
                borderRadius: index === 0 ? '20px 0 0 20px' : index === steps.length - 1 ? '0 20px 20px 0' : '0',
              }}
            ></div>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', width: '100%', paddingTop: '20px', zIndex: 2 }}>
        {steps.map((step, index) => (
          <div key={step.name} style={{ flex: 1, textAlign: 'center' }}>
            {step.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const CampaignCreate = ( { existingBusinessData, businessUid, onError, onBusinessComplete, onCampaignComplete } ) => {
  const [currentStep, setCurrentStep] = useState(1);

  const fileInputRef = useRef(null);
  const defaultcampaign_picture = '../images/generic-image-placeholder.png';

  const [businessData, setBusinessData] = useState({
    business_name: '',
    location: null,
    yelp_url: '',
    website_url: '',
    market: '',
  });

  const [campaignData, setCampaignData] = useState({
    status: 'created',
    business_id: '',
    campaign_name: '',
    campaign_description: '',
    campaign_picture: '',
    location: null,
    meet_date: null,
    post_date: null,
    tt_expectations: '1 Tiktok video',
    ig_expectations: '1 video post (reel) and 2-3 stories',
    is_recurring: null,
    targeted_compensation: ['paid'],
    is_comped: null,
    subscription_type: 'standard',
    freebie_comp_description: '',
    creator_details: '',
  });

  const handleCampaignTypeClick = (type) => {
    setCampaignData((prevData) => ({
      ...prevData,
      is_recurring: type.isRecurring,
    }));
  };

  const handleCompedCheckboxClick = (checked) => {
    setCampaignData((prevData) => ({
      ...prevData,
      is_comped: checked,
      targeted_compensation: checked ? ['paid', 'freebies'] : ['paid'],
    }));
  };

  const handleSubscriptionTypeClick = (subscriptionType) => {
    setCampaignData((prevData) => ({
      ...prevData,
      subscription_type: subscriptionType.id,
    }));
  };

  const handleSubscriptionFrequencyClick = (subscriptionFrequency) => {
    setCampaignData((prevData) => ({
      ...prevData,
      subscription_frequency: subscriptionFrequency,
    }));
  };

  const handleCampaignPicClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImageToStorage = async (file, uid) => {
    const campaignsRef = ref(storage, `campaigns/${uid}/${file.name}`);
    await uploadBytes(campaignsRef, file);
    const downloadURL = await getDownloadURL(campaignsRef);
    return downloadURL;
  };

  const isValidUrl = ((url) => {
    return true;
    // const urlPattern = new RegExp('^(?:(?:https?)://)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,7})(?:/S*)?$');
    // return !!urlPattern.test(url);
  });

  const validateBusiness = (() => {
    let errors = [];
    const data = businessData;

    // Validate business name (non-empty, max length 100 characters)
    if (!businessData.business_name || businessData.business_name.trim() === '') {
      errors.push("Business name is required.");
    } else if (businessData.business_name.length > 100) {
      errors.push("Business name cannot exceed 100 characters.");
    }

    // Validate Website URL (proper URL format)
    if (businessData.website_url && !isValidUrl(businessData.website_url)) {
      errors.push("Invalid URL provided for Website URL.");
    }

    // Validate Yelp URL (proper URL format, contains "yelp.com")
    if (businessData.yelp_url && !isValidUrl(businessData.yelp_url)) {
      errors.push("Invalid URL provided for Yelp URL.");
    } else if (businessData.yelp_url && !businessData.yelp_url.includes('yelp.com')) {
      errors.push("Yelp URL must link to yelp.com.");
    }

    return { data, errors };
  });

  const validateCampaign = (() => {
    let errors = [];
    const data = campaignData;

    if (currentStep === 1) {
      // no op
    } else if (currentStep === 2) {
      // Validate Campaign Recurring
      if (data.is_recurring === null) {
        errors.push("Campaign type is required.");
      }
      // Validate Campaign Name
      if (!data.campaign_name.trim()) {
        errors.push("Campaign name is required.");
      }
      // Validate Campaign Description for recurring campaigns
      if (data.is_recurring && !data.campaign_description.trim()) {
        errors.push("Campaign description is required.");
      }
    } else if (currentStep === 3) {
      if (data.is_recurring) {
        // Validate Subscription Type
        if (!data.subscription_type || data.subscription_type === 'standard') {
          errors.push("Please select a paid subscription option.");
        }
        // Validate Subscription Frequency
        if (!data.subscription_frequency) {
          errors.push("Valid subscription frequency is required.");
        }
      } else {
        // Validate Expectations
        if (!data.tt_expectations.trim() || !data.ig_expectations.trim()) {
          errors.push("Expectations for Tiktok and Instagram are required.");
        }
        // Validate Meet and Post Dates
        if (data.meet_date && data.post_date && moment(data.meet_date).isAfter(data.post_date)) {
          errors.push("Post by date must be after the meet/shoot by date.");
        }
      }
      // Validate Reviewed Item Compensation
      if (data.is_comped === null) {
        errors.push("Please specify if the items being reviewed for content will be comped.");
      }
      // Validate Freebies
      if (data.targeted_compensation.includes('freebies') && !data.freebie_comp_description) {
        errors.push("Valid description of freebies is required for freebie compensation campaigns.");
      }
    }

    return { data, errors };
  });

  const handleBusinessChange = (e) => {
    const { name, value } = e.target;
    setBusinessData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCampaignChange = (e) => {
    const { name, value } = e.target;
    setCampaignData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // TODO: re-add this later when we have custom subscription offerings

  // const calculateCreatorsForBudget = (budget) => {
  //   if (budget < 50) {
  //     return '<1';
  //   }
  //   if (budget < 100) {
  //     return '1';
  //   }
  //   if (budget < 200) {
  //     return '1-2';
  //   }
  //   return `${Math.floor(parseInt(campaignData.budget)/200)}-${Math.floor(parseInt(campaignData.budget)/100)}`;
  // };

  const businessInfoComponent = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px', gap: '10px', width: '80%', minWidth: '375px' }}>
      <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
        <h3><b>Basic Information</b></h3>
        <br/>
        <label className="required" htmlFor="businessName" style={{ width: 'unset', textAlign: 'unset' }}>Business Name:</label>
        <div className="edit-field">
          <input
            id="businessName"
            name="business_name"
            value={businessData.business_name}
            placeholder="ACME Co"
            className="input-field"
            onChange={handleBusinessChange}
            style={{ width: '100%' }}
          />
        </div>

        <label htmlFor="businessAddress" style={{ width: 'unset', textAlign: 'unset' }}>Business Address:</label>
        <div className="edit-field" id="businessAddress">
          <MapLocationSelection
            existingLocation={businessData.location ? businessData.location.address : null}
            onLocationChange={(loc) => {
              setBusinessData((prevData) =>
                ({ ...prevData, location: loc })
              );
              setCampaignData((prevData) =>
                ({ ...prevData, location: loc })
              );
            }}
          />
        </div>
      </div>
      <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
        <h3><b>Links</b></h3>
        <br/>
        <label htmlFor="websiteUrl" style={{ width: 'unset', textAlign: 'unset' }}>Website:</label>
        <div className="edit-field">
          <input
            id="websiteUrl"
            name="website_url"
            value={businessData.website_url}
            placeholder="https://www.pobbles.co"
            className="input-field"
            onChange={handleBusinessChange}
            style={{ width: '100%' }}
          />
        </div>

        <label htmlFor="yelpUrl" style={{ width: 'unset', textAlign: 'unset' }}>Yelp Link:</label>
        <div className="edit-field">
          <input
            id="yelpUrl"
            name="yelp_url"
            value={businessData.yelp_url}
            placeholder="https://www.yelp.com/biz/acme-co"
            className="input-field"
            onChange={handleBusinessChange}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </div>
  );

  const campaignInfoComponent = (
    <div className="bordered-edit-fields" style={{ display: 'flex', flexDirection: 'column', margin: '20px', width: '80%', minWidth: '375px' }}>
      <label className="required" htmlFor="tag-container" style={{ width: 'unset', textAlign: 'left' }}>What kind of campaign do you want to create?</label>
      <br />
      <div className="tag-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
        {constants.availableCampaignTypes.map((type, index) => (
          <div
            key={index}
            className={`bundle ${campaignData.is_recurring === type.isRecurring ? 'selected' : ''}`}
            onClick={() => handleCampaignTypeClick(type)}
            style={{ display: 'flex', flexDirection: 'column', width: '300px', height: '350px', alignItems: 'center', textAlign: 'center' }}
          >
            <img src={type.image} alt="Bundle" style={{ paddingTop: '10px', width: '100%', height: '225px', objectFit: 'contain' }}/>
            <h2>{type.name}</h2>
            <br/>
            {type.description}
          </div>
        ))}
      </div>
      {campaignData.is_recurring !== null &&
        <>
          <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
            <label className="required" htmlFor="campaign_name" style={{ width: 'unset', textAlign: 'unset' }}>Create a name for your campaign:</label>
            <div className="edit-field">
              <input
                id="campaign_name"
                name="campaign_name"
                value={campaignData.campaign_name}
                placeholder="ACME's Happy Hour"
                className="input-field"
                onChange={handleCampaignChange}
                style={{ width: '100%' }}
              />
            </div>
            {campaignData.is_recurring &&
              <>
                <label className="required" htmlFor="campaign_description" style={{ width: 'unset', textAlign: 'unset' }}>What do you want to achieve with this campaign?</label>
                <div className="edit-field">
                  <input
                    id="campaign_description"
                    name="campaign_description"
                    value={campaignData.campaign_description}
                    placeholder="Showcase our new happy hour menu items"
                    className="input-field"
                    onChange={handleCampaignChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </>
            }
          </div>
          <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
            <label htmlFor="campaign_picture" style={{ width: 'unset', textAlign: 'unset', paddingBottom: '10px' }}>Upload a campaign image:</label>
            <div className="edit-field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className="profilePic-container" onClick={handleCampaignPicClick} style={{ marginRight: 'unset', width: '100%', height: '200px' }}>
                <img src={campaignData.campaign_picture || defaultcampaign_picture} className="profilePic" alt="Campaign Profile" style={{ borderRadius: '10px' }}/>
                <div className="edit-overlay" style={{ borderRadius: '10px' }}>
                  <FontAwesomeIcon icon={faEdit} />
                </div>
                <input
                  type="file"
                  id="campaign_picture"
                  ref={fileInputRef}
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const downloadURL = await uploadImageToStorage(file, auth.currentUser.uid);
                      setCampaignData((prevData) =>
                        ({ ...prevData, campaign_picture: downloadURL })
                      );
                    }
                  }}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );

  const expectationsAndCompensationComponent = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px', width: '80%', minWidth: '375px' }}>
      {campaignData.is_recurring
        ? <>
            <label className="required" htmlFor="tag-container" style={{ width: 'unset', textAlign: 'unset' }}>Select a Recurring Subscription Plan:</label>
            <div className="tag-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
              {constants.availableRecurringSubscriptions.map((type, index) => (
                <div
                  key={index}
                  className={`bundle ${campaignData.subscription_type === type.id ? 'selected' : ''}`}
                  onClick={() => handleSubscriptionTypeClick(type)}
                  style={{ display: 'flex', flexDirection: 'column', width: '250px', height: '350px', alignItems: 'center', textAlign: 'center' }}
                >
                  <img src={type.image} alt="Bundle" style={{ paddingTop: '10px', width: '100%', height: '225px', objectFit: 'contain' }}/>
                  <h2>{type.name}</h2>
                  ${type.price}
                  <br/>
                  <br/>
                  {type.description}
                </div>
              ))}
            </div>
            <label className="required" htmlFor="tag-container" style={{ width: 'unset', textAlign: 'unset' }}>Subscription Frequency:</label>
            <div className="edit-field">
              <div className="tag-container" style={{ display: 'contents', width: '90%' }}>
                {constants.availableSubscriptionFrequencies.map((tag, index) => (
                  <div
                    key={index}
                    className={`tag ${campaignData.subscription_frequency === tag.id ? 'selected' : ''}`}
                    onClick={() => handleSubscriptionFrequencyClick(tag.id)}
                  >
                    {tag.name} ({tag.description})
                  </div>
                ))}
              </div>
            </div>
          </>
        : <>
            <div className="edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
              <label className="required" htmlFor="tt_expectations" style={{ width: 'unset', textAlign: 'unset' }}>Tiktok Video Expectations:</label>
              <div className="edit-field">
                <input
                  id="tt_expectations"
                  name="tt_expectations"
                  value={campaignData.tt_expectations}
                  placeholder="1 Tiktok video"
                  className="input-field"
                  onChange={handleCampaignChange}
                  style={{ width: '100%' }}
                />
              </div>

              <label className="required" htmlFor="ig_expectations" style={{ width: 'unset', textAlign: 'unset' }}>Instagram Reel & Story Expectations:</label>
              <div className="edit-field">
                <input
                  id="ig_expectations"
                  name="ig_expectations"
                  value={campaignData.ig_expectations}
                  placeholder="1 video post (reel) and 2-3 stories"
                  className="input-field"
                  onChange={handleCampaignChange}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <div className="edit-fields bordered-edit-fields" style={{ flexDirection: 'row', maxWidth: 'unset', justifyContent: 'space-between' }}>
              <div style={{ flexDirection: 'column', width: '40%' }}>
                <label style={{ width: 'unset', textAlign: 'unset' }}>When should the creator shoot the content by?</label>
                <div className="edit-field">
                  <Datetime
                    inputProps={{ className: 'datetime-picker', placeholder: new Date().toLocaleDateString(), readOnly: true }}
                    value={campaignData.meet_date ? moment(campaignData.meet_date) : null}
                    onChange={(momentDate) => {
                      setCampaignData((prevData) =>
                        ({
                          ...prevData, meet_date: momentDate.toISOString(),
                          ...prevData, post_date: momentDate.add(7, 'days').toISOString(),
                        })
                      );
                    }}
                    isValidDate={currentDate => currentDate.isAfter(Datetime.moment())}
                    dateFormat={true}
                    timeFormat={false}
                  />
                </div>
              </div>
              <div style={{ flexDirection: 'column', width: '40%' }}>
                <label style={{ width: 'unset', textAlign: 'unset' }}>When should the content be posted by?</label>
                <div className="edit-field">
                  <Datetime 
                    inputProps={{ className: 'datetime-picker', placeholder: new Date().toLocaleDateString(), readOnly: true }}
                    value={campaignData.post_date ? moment(campaignData.post_date) : null}
                    onChange={(momentDate) => {
                      setCampaignData((prevData) =>
                        ({ ...prevData, post_date: momentDate.toISOString() })
                      );
                    }}
                    isValidDate={currentDate => currentDate.isAfter((moment(Date(campaignData.meet_date)) || Datetime.moment()).add(1, 'days'))}
                    dateFormat={true}
                    timeFormat={false}
                  />
                </div>
              </div>
            </div>
          </>
      }
      <div className="large-edit-fields bordered-edit-fields" style={{ maxWidth: 'unset' }}>
        <label className="required" htmlFor="isComped" style={{ width: 'unset', textAlign: 'unset' }}>Are you providing comped items as part of your collab? (or free items)</label>
        <div className="edit-field" style={{ justifyContent: 'space-evenly' }}>
          <div>
            <input
              type="checkbox"
              name="isComped"
              checked={campaignData.is_comped !== null ? campaignData.is_comped : false }
              onChange={() => handleCompedCheckboxClick(true)}
            />
            &ensp;Yes
          </div>
          <div>
            <input
              type="checkbox"
              name="isComped"
              checked={campaignData.is_comped !== null ? !campaignData.is_comped : false }
              onChange={() => handleCompedCheckboxClick(false)}
            />
            &ensp;No (or not applicable)
          </div>
        </div>
        {campaignData.targeted_compensation.includes('freebies') &&
          <>
            <label className="required" htmlFor="freebie_comp_description" style={{ width: 'unset', textAlign: 'unset' }}>Description of the free / comped goods:</label>
            <div className="edit-field">
              <input
                id="freebie_comp_description"
                name="freebie_comp_description"
                value={campaignData.freebie_comp_description}
                placeholder="Comped meal for content creation."
                className="input-field"
                onChange={handleCampaignChange}
                style={{ width: '100%' }}
                disabled={campaignData.status && campaignData.status !== 'created'}
              />
            </div>
          </>
        }
        <label htmlFor="creator_details" style={{ width: 'unset', textAlign: 'unset' }}>Anything you want to share with the creators?</label>
        <div className="edit-field">
          <textarea
            id="creator_details"
            name="creator_details"
            value={campaignData.creator_details}
            placeholder="Looking forward to working with you!"
            className="input-field textarea-field"
            onChange={handleCampaignChange}
            style={{ width: '100%', height: '100px' }}
          />
        </div>
      </div>
    </div>
  );

  const steps = [
    { name: 'Business Information', component: businessInfoComponent },
    { name: 'Campaign Info', component: campaignInfoComponent },
    { name: 'Expectations & Compensation', component: expectationsAndCompensationComponent },
  ];

  const handleNext = () => {
    if (currentStep === 1) {
      const {data, errors} = validateBusiness();
      if (errors.length) {
        onError(errors)
        return
      } else {
        onBusinessComplete({data, errors});
      }
    } else {
      const {data, errors} = validateCampaign();
      if (errors.length) {
        onError(errors)
        return
      }
      if (currentStep === 3) {
        onCampaignComplete({data, errors});
        return
      }
    }
    setCurrentStep((prev) => (prev < steps.length ? prev + 1 : prev));
  };

  const handleBack = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  useEffect(() => {
    if (existingBusinessData) {
      setBusinessData((prevData) => ({
        ...prevData,
        ...existingBusinessData,
      }));
    }
    const existingLocation = existingBusinessData?.location;
    if (businessUid) {
      setCampaignData((prevData) => ({
        ...prevData,
        business_id: businessUid,
        location: existingLocation || null,
      }));
    }
  }, [existingBusinessData, businessUid]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px' }}>
        <h1>Create a new campaign</h1>
        <span className="required" style={{ width: 'unset', textAlign: 'center' }}><i>Required fields are marked with an</i></span>
      </div>
      <ProgressBar steps={steps} currentStep={currentStep} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="splash" style={{ width: '30%', alignItems: 'flex-start' }}>
          <img src={`../images/campaign-creation-cta.png`} alt="Splash" style={{ maxWidth: '90%', objectFit: 'contain' }}/>
        </div>
        <div style={{ width: '70%' }}>
          {steps.map((step, index) => (
            <Step
              key={step.name}
              stepName={step.name}
              isActive={currentStep === index + 1}
            >
              {step.component}
            </Step>
          ))}
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '40px' }}>
            {currentStep > 1 && <button className='btn diamond-btn' onClick={handleBack}>Back</button>}
            {currentStep <= steps.length && <button className='btn diamond-btn diamond-btn--yellow' onClick={handleNext}>{currentStep === steps.length ? 'Done' : 'Next'}</button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCreate;

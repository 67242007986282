import React, { useRef, useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import { StatsigProvider } from 'statsig-react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { amplitude } from './lib/amplitude.jsx';
import { auth } from './lib/firebase';
import AuthenticatedRoute from './components/authenticatedRoute.jsx';
import NavBar from './components/navBar';
import { ToastContainer } from 'react-toastify';
import SignUp from './signup';
import Login from './login';
import EmailVerification from './emailVerification';
import Welcome from './welcome';
import Profile from './profile';
import Hire from './hire';
import HireSuccess from './hire-success';
import PobSuccess from './pob-success';
import PobCancel from './pob-cancel';
import Admin from './admin';
import Browse from './browse';
import SocialMediaManager from './socialMediaManager';
import Campaigns from './campaigns';
import CreateCampaign from './createCampaign';
import ViewCampaign from './viewCampaign';
import Payments from './payments';
import ChatMessenger from './chatMessenger';
import Settings from './settings';
import * as constants from './constants';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const type = useRef(window.localStorage.getItem('type'));

  const StatsigOptions = {
    environment: { tier: 'production' },
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
      if (user) {
        amplitude.setUserId(user.uid);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <Router>
        <NavBar />
      </Router>
    );
  };

  return (
    <StatsigProvider sdkKey={constants.keys.statsig} waitForInitialization={false} user={user ? { userID: user.uid } : {}} options={StatsigOptions}>
      <Router>
        <NavBar />
        <ToastContainer />
        <SentryRoutes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/business/signup" element={<Navigate to="/signup"/>} />
          <Route path="/business/login" element={<Navigate to="/login"/>} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/welcome" element={<AuthenticatedRoute user={user} children={<Welcome />} />} />
          <Route path="/profile" element={<AuthenticatedRoute user={user} children={<Profile />} />} />
          <Route path="/user/:username" element={<Profile user={user} />} />
          <Route path="/hire/:username" element={<Hire />} />
          <Route path="/hire/:username/success" element={<HireSuccess />} />
          <Route path="/hire-pob/success" element={<PobSuccess />} />
          <Route path="/hire-pob/canceled" element={<PobCancel />} />
          <Route path="/admin" element={<AuthenticatedRoute user={user} children={<Admin />} />} />
          <Route path="/browse" element={<AuthenticatedRoute user={user} children={<Browse />} />} />
          <Route path="/social-media-manager" element={<AuthenticatedRoute user={user} children={<SocialMediaManager />} />} />
          <Route path="/campaigns" element={<AuthenticatedRoute user={user} children={<Campaigns type={type.current} />} />} />
          <Route path="/campaign/create" element={<AuthenticatedRoute user={user} children={<CreateCampaign />} />} />
          <Route path="/campaign/:uid/:id" element={<AuthenticatedRoute user={user} children={<ViewCampaign />} />} />
          <Route path="/payments" element={<AuthenticatedRoute user={user} children={<Payments />} />} />
          <Route path="/messages" element={<AuthenticatedRoute user={user} children={<ChatMessenger />} />} />
          <Route path="/message/:uid" element={<AuthenticatedRoute user={user} children={<ChatMessenger />} />} />
          <Route path="/settings" element={<AuthenticatedRoute user={user} children={<Settings />} />} />
        </SentryRoutes>
      </Router>
    </StatsigProvider>
  );
};

export default App;
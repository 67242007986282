import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFeatureFlag, FeatureFlag } from './lib/FeatureFlags.jsx';
import { database } from './lib/firebase';
import { ref, onValue, get, update, push, child, remove, off } from "firebase/database";
import 'react-datetime/css/react-datetime.css';
import { ReachCalculator } from './lib/ReachCalculator.jsx';
import { NumberFormatter } from './lib/NumberFormatter.jsx';
import { ContentCostCalculator } from './lib/ContentCostCalculator.jsx';
import { SanitizeKey } from './lib/sanitizeKeys.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faMoneyBillTrendUp, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import CampaignEdit from './components/campaignEdit';
import CampaignRateSetter from './components/campaignRateSetter';
import URLReviewer from './components/urlReviewer';
import StarRating from './components/starRating';
import ProgressTimeline from './components/progressTimeline';
import StatsCard from './components/statsCard';
import AvailabilityMessageModal from './components/availabilityMessageModal';
import FileUpload from './components/fileUpload';
import ConfettiAnimation from './components/confetti';
import * as constants from './constants.jsx';
import './css/web.css';

const ViewCampaign = ({user}) => {
  const isInternalUser = useFeatureFlag(FeatureFlag.isInternalUser);

  const location = useLocation();
  const navigate = useNavigate();

  const { uid, id } = useParams();
  const [isCampaignOwner, setIsCampaignOwner] = useState(false);

  const params = new URLSearchParams(location.search);
  const cid = params.get('cid');
  const uids = params.get('uids');
  const didSucceed = params.get('success');
  const didUseBalance = params.get('balance');
  const addBalance = params.get('add_balance');
  const sessionId = params.get('session_id');
  const [didCheckQueryParams, setDidCheckQueryParams] = useState(false);

  const [status, setStatus] = useState('');

  const [businessData, setBusinessData] = useState({
    business_name: '',
    location: null,
    yelp_url: '',
    website_url: '',
    market: '',
  });

  const [campaignData, setCampaignData] = useState({
    status: '',
    campaign_name: '',
    campaign_description: '',
    campaign_picture: '',
    meet_date: null,
    post_date: null,
    tt_expectations: '',
    ig_expectations: '',
    targeted_compensation: [],
    freebie_comp_description: '',
    creator_details: '',
    influencers: [],
    did_pay_subscription: false,
    session_id: '',
    one_time_payment: null,
    add_ai_video: false,
  });

  const [userBalance, setUserBalance] = useState(null);

  const [recommendedCreators, setRecommendedCreators] = useState([]);

  const [allInfluencers, setAllInfluencers] = useState({});
  const [publicUsers, setPublicUsers] = useState([]);

  const defaultProfilePicUrl = '../images/pfp.png';

  const [fundedCreatorsStatsData, setFundedCreatorsStatsData] = useState([]);
  const [allCreatorsStatsData, setAllCreatorsStatsData] = useState([]);

  const [tabValue, setTabValue] = useState(0);
  const [reviewData, setReviewData] = useState([null, null, null]);
  const [ratingData, setRatingData] = useState([null, null, null]);
  const [ratesData, setRatesData] = useState([null, null, null, null, null]);
  const [showAvailabilityMessageModal, setShowAvailabilityMessageModal] = useState(false);
  const [shouldShowEdit, setShouldShowEdit] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [instagramSubmissionURL, setInstagramSubmissionURL] = useState('');
  const [tiktokSubmissionURL, setTiktokSubmissionURL] = useState('');

  const handleChangeTab = ((newValue) => {
    if (newValue === tabValue) {
      setTabValue(-1);
    } else {
      setTabValue(newValue);
    }
  });

  const handleSave = ((data, errors) => {
    if (errors.length) {
      toast.error(errors[0]);
      return;
    }
    update(ref(database, `campaigns/${uid}/${id}`), data);
    toast.success('Successfully updated!');
    setIsEditing(false);
  });

  const handleCancel = ((data, errors) => {
    setIsEditing(false);
  });

  const getStatusOrder = (status) => {
    const order = {
      'pending review': 1,
      'countered': 2,
      'invited': 3,
      'accepted': 4,
      'new': 5,
      'completed': 6,
      'paid': 7
    };
    return order[status] || 0;
  };

  const handlePayOneTimePayment = ((e) => {
    e.stopPropagation();
    if (!isCampaignOwner) { return }
    const redirect_uri = encodeURIComponent(window.location.href.replace(location.search, ""));
    const newWindow = window.open(`${constants.backendDomain}/checkout-subscription-balance?business_uid=${uid}&campaign_id=${id}&redirect_uri=${redirect_uri}?add_balance=${campaignData.one_time_payment}`, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  });

  const handlePaySubscription = ((e, customSubscriptionPriceId, subscriptionType, subscriptionFrequency, addAIVideo) => {
    e.stopPropagation();
    if (!isCampaignOwner) { return }
    const redirect_uri = encodeURIComponent(window.location.href.replace(location.search, ""));
    let subscriptionUrl = '';
    if (customSubscriptionPriceId) {
      subscriptionUrl = `${constants.backendDomain}/checkout-subscription?business_uid=${uid}&campaign_id=${id}&custom_price_id=${customSubscriptionPriceId}`;
    } else {
      subscriptionUrl = `${constants.backendDomain}/checkout-subscription?business_uid=${uid}&campaign_id=${id}&type=${subscriptionType}&frequency=${subscriptionFrequency}`;
    }
    if (addAIVideo) {
      subscriptionUrl += '&add_ai_video=true';
    }
    subscriptionUrl += `&redirect_uri=${redirect_uri}?cid=${id}`;
    const newWindow = window.open(subscriptionUrl, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  });

  const handleManageSubscription = ((e, sessionId) => {
    e.stopPropagation();
    if (!isCampaignOwner) { return }
    const redirect_uri = encodeURIComponent(window.location.href.replace(location.search, ""));
    const newWindow = window.open(`${constants.backendDomain}/create-portal-session?session_id=${sessionId}&redirect_uri=${redirect_uri}`, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  });

  const handleAddCreator = ((e, creatorUid = null) => {
    e.stopPropagation();
    if (creatorUid) {
      updateInfluencerStatus(creatorUid, 'new');
      if (publicUsers[creatorUid]) {
        toast.success(`${publicUsers[creatorUid]} has been added to your campaign!`);
      }
    } else {
      recommendedCreators.forEach((creatorUid) => {
        updateInfluencerStatus(creatorUid, 'new');
      });
      toast.success(`All recommended creators have been added to your campaign!`);
    }
    setRecommendedCreators([]);
  });

  const handleInviteCreators = ((e, creatorUid = null) => {
    e.stopPropagation();
    if (!isCampaignOwner) { return }
    if (creatorUid) {
      const submitHandler = ((price, freebies) => {
        setRatesData([null, null, null, null, null, null]);
        updateInfluencerData(creatorUid, 'rate', price);
        if (freebies) {
          updateInfluencerData(creatorUid, 'freebie_comp_description', freebies);
        }
        handleFundCreators(e, creatorUid);
      });
      const cancelHandler = (() => {
        setRatesData([null, null, null, null, null, null]);
      });
      setRatesData([
        (allInfluencers[creatorUid].compensation && allInfluencers[creatorUid].compensation.amount),
        (allInfluencers[creatorUid].compensation && allInfluencers[creatorUid].compensation.amount) || 0,
        campaignData.targeted_compensation.includes('freebies') ? campaignData.freebie_comp_description : null,
        isCampaignOwner,
        submitHandler,
        cancelHandler
      ]);
    } else {
      handleFundCreators(e, null);
    }
  });

  const handleRemoveCreator = ((e, creatorUid) => {
    e.stopPropagation();
    if (!isCampaignOwner) { return }
    if (creatorUid) {
      if (!campaignData.is_recurring && campaignData.influencers[creatorUid].status !== 'new' && campaignData.influencers[creatorUid].status !== 'countered') {
        setUserBalance(userBalance + (parseInt(campaignData.influencers[creatorUid].rate) || 0));
      }
      remove(ref(database, `campaigns/${uid}/${id}/influencers/${creatorUid}`));
      toast.success(`${publicUsers[creatorUid]} has been removed from your campaign!`);
    }
  });

  const handleBusinessAcceptOffer = ((e, creatorUid) => {
    e.stopPropagation();
    handleFundCreators(e, creatorUid);
  });

  const handleBusinessCounterOffer = ((e, uid) => {
    e.stopPropagation();
    const submitHandler = ((price, freebies) => {
      setRatesData([null, null, null, null, null, null]);
      updateInfluencerData(uid, 'rate', price);
      if (freebies) {
        updateInfluencerData(uid, 'freebie_comp_description', freebies);
      }
      handleFundCreators(e, uid, true);
    });
    const cancelHandler = (() => {
      setRatesData([null, null, null, null, null, null]);
    });
    setRatesData([
      (allInfluencers[uid].compensation && allInfluencers[uid].compensation.amount),
      campaignData.influencers[uid].rate,
      campaignData.influencers[uid].freebie_comp_description,
      isCampaignOwner,
      submitHandler,
      cancelHandler]);
  });

  const handleFundCreators = ((e, creatorUid = null, isCounter = false) => {
    e.stopPropagation();
    if (!isCampaignOwner) { return }
    let uids = [];
    if (creatorUid) {
      if (campaignData.influencers[creatorUid].rate <= userBalance) {
        setUserBalance(userBalance - campaignData.influencers[creatorUid].rate);
        if (campaignData.influencers[creatorUid].status === 'countered') {
          if (isCounter) {
            updateInfluencerStatus(creatorUid, 'invited');
          } else {
            updateInfluencerStatus(creatorUid, 'accepted');
          }
          toast.success(`${publicUsers[creatorUid]} has been funded via your existing balance!`);
        } else {
          updateInfluencerStatus(creatorUid, 'invited');
          toast.success(`${publicUsers[creatorUid]} has been invited to your campaign via your existing balance!`);
        }
        update(ref(database, `campaigns/${uid}/${id}`),
          {
            status: 'matched'
          });
        return; // short circuit due to funding from balance
      }
      uids.push(creatorUid);
    } else {
      const newCreators = Object.entries(campaignData.influencers)
        .filter(influencer => influencer[1].status === 'new')
        .map(([id, opportunity], index) => [id, allInfluencers[id]]);

      newCreators.forEach((newCreator) => {
        uids.push(newCreator[0]);
      });
    }

    if (uids.length > 0) {
      const redirect_uri = encodeURIComponent(window.location.href.replace(location.search, ""));
      const newWindow = window.open(`${constants.backendDomain}/checkout-influencer?uid=${uids.toString()}&business_uid=${uid}&campaign_id=${id}&redirect_uri=${redirect_uri}?uids=${uids.toString()}`, "_blank");
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  });

  const handleReviewContent = ((e, reviewUid = null) => {
    e.stopPropagation();
    if (!isCampaignOwner) { return }
    let creatorUid = null;
    if (reviewUid) {
      creatorUid = reviewUid;
    } else {
      const pendingReviewCreators = Object.entries(campaignData.influencers)
        .filter(influencer => influencer[1].status === 'pending review')
        .map(([id, opportunity], index) => id);
      if (pendingReviewCreators) {
        creatorUid = pendingReviewCreators[0];
      }
    }
    if (creatorUid && (campaignData.influencers[creatorUid].instagram_content_url || campaignData.influencers[creatorUid].tiktok_content_url || campaignData.influencers[creatorUid].review_url)) {
      const approvalHandler = (() => {
        setReviewData([null, null, null, null, null]);
        updateInfluencerStatus(creatorUid, 'completed');
        const ratingSubmitHandler = (({rating, review}) => {
          update(ref(database, `reviews/${creatorUid}/${id}/`),
            {
              reviewer: uid,
              rating: rating,
              review: review,
            });
        });
        const closeHandler = (() => {
          setRatingData([null, null, null]);
        });
        setRatingData([publicUsers[creatorUid], ratingSubmitHandler, closeHandler]);
      });
      const rejectionHandler = (() => {
        setReviewData([null, null, null, null, null]);
        updateInfluencerStatus(id, 'accepted');
      });
      setReviewData([campaignData.influencers[creatorUid].instagram_content_url, campaignData.influencers[creatorUid].tiktok_content_url, campaignData.influencers[creatorUid].review_url, approvalHandler, rejectionHandler]);
    } else {
      setReviewData([null, null, null, null, null]);
      toast.error("Oops! Something went wrong. Please try again later.");
    }
  });

  const handleMessageCreator = ((e, creatorUid) => {
    e.stopPropagation();
    if (uid !== user.uid) { return }
    if (creatorUid === user.uid) { return }
    navigate(`/message/${creatorUid}`);
  });

  const handleViewCreator = ((e, username) => {
    e.stopPropagation();
    if (!isCampaignOwner) { return }
    let url = `${window.location.origin}/#/user/${username}`;
    if (uid && id) {
      url += `?uid=${uid}&id=${id}`;
    }
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  });

  const handleBrowseInfluencers = (() => {
    if (!isCampaignOwner) { return }
    navigate(`/browse?uid=${uid}&id=${id}`);
  });

  const updateInfluencerStatus = ((creatorUid, status) => {
    updateInfluencerData(creatorUid, 'status', status);
    if (status === 'invited' || status === 'accepted' || status === 'countered' || status === 'declined') {
      updateInfluencerData(creatorUid, 'status_updated_time', Math.floor(Date.now() / 1000));
    }
    if (status === 'pending review') {
      updateInfluencerData(creatorUid, 'content_submitted_time', Math.floor(Date.now() / 1000));
    }
  });

  const updateInfluencerData = ((creatorUid, param, value) => {
    if (campaignData.influencers) {
      if (campaignData.influencers[creatorUid]) {
        campaignData.influencers[creatorUid][param] = value;
      } else {
        campaignData.influencers[creatorUid] = { [param]: value };
      }
    }
    update(ref(database, `campaigns/${uid}/${id}/influencers/${creatorUid}`),
      {
        [param]: value
      });
  });

  const handleCreatorAcceptOffer = (() => {
    toast.success(`Congratulations on your new collaboration with ${businessData.business_name}!`);
    updateInfluencerStatus(user.uid, 'accepted');
    setShowAvailabilityMessageModal(true);
  });

  const handleCreatorDeclineOffer = (() => {
    toast.success(`Got it! You've declined the offer to collaborate with ${businessData.business_name}.`);
    updateInfluencerStatus(user.uid, 'declined');
  });

  const handleCreatorCounterOffer = (() => {
    const submitHandler = ((price, freebies) => {
      setRatesData([null, null, null, null, null, null]);
      if (!campaignData.is_recurring && campaignData.influencers[user.uid].status !== 'new') {
        setUserBalance(userBalance + (parseInt(campaignData.influencers[user.uid].rate) || 0));
      }
      updateInfluencerData(user.uid, 'rate', price);
      if (freebies) {
        updateInfluencerData(user.uid, 'freebie_comp_description', freebies);
      }
      updateInfluencerStatus(user.uid, 'countered');
      toast.success("Your counter offer has been sent to the business for approval and funding!");
    });
    const cancelHandler = (() => {
      setRatesData([null, null, null, null, null, null]);
    });
    setRatesData([
      (allInfluencers[user.uid].compensation && allInfluencers[user.uid].compensation.amount),
      campaignData.influencers[user.uid].rate,
      campaignData.influencers[user.uid].freebie_comp_description,
      isCampaignOwner,
      submitHandler,
      cancelHandler]);
  });

  const handleCreatorSubmitContent = (() => {
    if (!instagramSubmissionURL && !tiktokSubmissionURL) {
      toast.error("Please add at least one url to submit for review.");
      return
    }
    toast.success("Your content has been submitted for review by the business!");
    if (instagramSubmissionURL) {
      updateInfluencerData(user.uid, 'instagram_content_url', instagramSubmissionURL);
    }
    if (tiktokSubmissionURL) {
      updateInfluencerData(user.uid, 'tiktok_content_url', tiktokSubmissionURL);
    }
    updateInfluencerStatus(user.uid, 'pending review');
    setInstagramSubmissionURL('');
    setTiktokSubmissionURL('');
  });

  const handleCreatorMessageBusiness = (() => {
    navigate(`/message/${uid}`);
  });

  const handleCreatorReview = (() => {
    const ratingSubmitHandler = (({rating, review}) => {
      update(ref(database, `reviews/${uid}/${id}/`),
        {
          reviewer: user.uid,
          rating: rating,
          review: review,
        });
      updateInfluencerData(user.uid, 'reviewed', true);
      toast.success(`Thanks for taking the time to review your experiences with ${businessData.businessName}!`);
    });
    const closeHandler = (() => {
      setRatingData([null, null, null]);
    });
    setRatingData([publicUsers[uid], ratingSubmitHandler, closeHandler]);
  });

  useEffect(() => {
    if (!allInfluencers) { return }
    if (!campaignData) { return }
    const calculateCreatorStats = () => {
      if (Object.keys(campaignData.influencers || {}).length === 0) {
        setFundedCreatorsStatsData(null);
        setAllCreatorsStatsData(null);
        return;
      }
      const calculateCreatorsStatsData = ((uids) => {
        const reach = uids.reduce((reach, uid) => reach + (allInfluencers[uid] ? ReachCalculator(allInfluencers[uid].cached, false) : 0), 0);
        const cost = uids.reduce((cost, uid) => cost + parseInt(campaignData.influencers[uid].rate || (allInfluencers[uid]?.compensation?.amount || 0)), 0);
        const costPer100Reach = reach === 0 ? '-' : `$${(Math.round((cost/reach) * 100 * 100) / 100).toFixed(2)}`;
        return [
          { icon: <FontAwesomeIcon icon={faPeopleGroup} />, label: "COUNT", value: uids.length },
          { icon: <FontAwesomeIcon icon={faEye} />, label: "EST REACH", value: reach },
          { icon: <FontAwesomeIcon icon={faMoneyBillTrendUp} />, label: "EST COST / 100 REACH", value: costPer100Reach },
        ];
      });
      const fundedCreators = Object.entries(campaignData.influencers).filter(([uid, influencer]) => influencer.status !== 'new' && influencer.status !== 'countered').map(([uid, influencer]) => uid);
      setFundedCreatorsStatsData(calculateCreatorsStatsData(fundedCreators));
      if (fundedCreators.length !== Object.keys(campaignData.influencers).length) {
        setAllCreatorsStatsData(calculateCreatorsStatsData(Object.keys(campaignData.influencers)));
      } else {
        setAllCreatorsStatsData(null);
      }
    };
    const generateRecommendedCreators = () => {
      if (!isCampaignOwner) { return }
      if (recommendedCreators.length !== 0) { return }
      function recommend(creators) {
        let sortedCreators = creators.sort((a, b) => a.cost - b.cost);

        // Function to randomly select creators with bias for more affordable creators
        function selectCreators() {
          let selectedUIDs = [];

          while (selectedUIDs.length < 3 && sortedCreators.length > 0) {
            let randomCreator = sortedCreators[Math.floor(Math.random() * sortedCreators.length)];
            selectedUIDs.push(randomCreator.uid);
            sortedCreators = sortedCreators.filter(creator => creator.uid !== randomCreator.uid);
          }
          return selectedUIDs;
        }
        return selectCreators();
      }
      const unusedInfluencers = Object.entries(allInfluencers).filter(([uid, influencer]) => !influencer.should_hide && influencer.market === businessData.market && ReachCalculator(influencer.cached, false) > 0 && !Object.keys(campaignData.influencers || {}).includes(uid));
      const possibleCreators = unusedInfluencers.filter(([uid, influencer]) => influencer.compensation?.tags && influencer.compensation?.tags.includes('paid')).map(([uid, influencer]) => ({ uid, cost: influencer.compensation?.amount }));
      setRecommendedCreators(recommend(possibleCreators));
    };
    calculateCreatorStats();
    if (!campaignData.is_recurring) {
      generateRecommendedCreators();
    }
  }, [campaignData, allInfluencers, isCampaignOwner, recommendedCreators, businessData.market]);

  useEffect(() => {
    const influencersUids = Object.keys(campaignData.influencers || {});
    const filteredRecommendedCreators = recommendedCreators.filter(uid => !influencersUids.includes(uid));

    if (filteredRecommendedCreators.length !== recommendedCreators.length) {
      setRecommendedCreators(filteredRecommendedCreators);
    }
  }, [campaignData.influencers, recommendedCreators]);

  useEffect(() => {
    if (isCampaignOwner) {
      setTabValue(0);
      return
    }
    if (status !== 'invited' && status !== 'accepted') {
      setTabValue(-1);
    } else {
      setTabValue(0);
    }
  }, [isCampaignOwner, status]);

  useEffect(() => {
    if (userBalance !== null) {
      update(ref(database, `balances`), {
        [uid]: userBalance
      });
    }
  }, [uid, userBalance]);

  useEffect(() => {
    if (!campaignData) { return }
    if (uid && id && isCampaignOwner) {
      if (didCheckQueryParams) { return }
      setDidCheckQueryParams(true);
      if (didSucceed) {
        if (cid && cid === id) {
          const campaignDbRef = ref(database, `campaigns/${uid}/${cid}`);
          if (sessionId) {
            update(campaignDbRef, {
              status: 'subscribed',
              did_pay_subscription: true,
              session_id: sessionId,
            });
          }
        }
        if (uids) {
          uids.split(',').forEach((creatorUid) => {
            if (creatorUid) {
              if (campaignData.influencers[creatorUid] && campaignData.influencers[creatorUid].status === 'countered') {
                updateInfluencerStatus(creatorUid, 'accepted');
                toast.success(`${publicUsers[creatorUid]} has been funded!`);
              } else {
                updateInfluencerStatus(creatorUid, 'invited');
                if (publicUsers[creatorUid]) {
                  toast.success(`${publicUsers[creatorUid]} has been invited to your campaign!`);
                }
              }
            }
          });
          update(ref(database, `campaigns/${uid}/${id}`),
            {
              status: 'matched'
            });
        }
        if (addBalance) {
          setUserBalance(userBalance + (parseInt(addBalance) || 0));
          remove(ref(database, `campaigns/${uid}/${id}/one_time_payment`));
          toast.success(`You have successfully added $${addBalance} to your account.`);
        }
        if (didUseBalance) {
          setUserBalance(0);
        }
      }
    }
  }, [cid, uids, didSucceed, didUseBalance, addBalance, uid, id, isCampaignOwner, campaignData]);

  useEffect(() => {
    let subscriptions = [];
    const fetchData = async (uid, id) => {
      const businessesDbRef = ref(database, `businesses/${uid}`);
      const businessesDbUnsubscribe = onValue(businessesDbRef, (snapshot) => {
        const business = snapshot.val();
        if (business) {
          setBusinessData(business);
        }
      });
      subscriptions.push({ ref: businessesDbRef, unsubscribe: businessesDbUnsubscribe });

      const campaignDbRef = ref(database, `campaigns/${uid}/${id}`);
      const campaignDbUnsubscribe = onValue(campaignDbRef, (snapshot) => {
        let campaign = snapshot.val();
        if (campaign) {
          if (!isCampaignOwner && campaign.influencers && campaign.influencers[user.uid]?.override) { // handle any overrides for specific influencer
            const overrideCampaign = campaign.influencers[user.uid]?.override;
            Object.keys(overrideCampaign).forEach(key => {
              campaign[key] = overrideCampaign[key];
            });
          }
          setCampaignData(campaign);
          if (campaign.influencers && campaign.influencers[user.uid]?.status) {
            setStatus(campaign.influencers[user.uid].status);
          } else {
            setStatus(campaign.status);
          }
        }
      });
      subscriptions.push({ ref: campaignDbRef, unsubscribe: campaignDbUnsubscribe });

      const balancesDbRef = ref(database, `balances/${uid}`);
      const balancesDbUnsubscribe = onValue(balancesDbRef, (snapshot) => {
        const balance = snapshot.val();
        if (balance) {
          setUserBalance(balance);
        } else {
          setUserBalance(0);
        }
      });
      subscriptions.push({ ref: balancesDbRef, unsubscribe: balancesDbUnsubscribe });

      get(ref(database, 'influencers')).then((snapshot) => {
        setAllInfluencers(snapshot.val());
      });

      get(ref(database, 'public_users')).then((snapshot) => {
        const publicUsers = snapshot.val();
        setPublicUsers(publicUsers);
      });

      get(ref(database, `businesses/${user.uid}`)).then((snapshot) => {
        const business = snapshot.val();
        if (business?.associated_businesses?.includes(uid)) {
          setIsCampaignOwner(true);
          setShouldShowEdit(false);
        }
      });
    };
    if (user) {
      setIsCampaignOwner(user.uid === uid);
      if (isInternalUser) {
        setIsCampaignOwner(true);
      }
      fetchData(uid, id);
    }
    return () => {
      for (let { ref, unsubscribe } of subscriptions) {
        off(ref, 'value', unsubscribe);
      }
      subscriptions = []
    };
  }, [uid, id, isInternalUser, navigate, user, isCampaignOwner]);

  if (!user || (!isCampaignOwner && !Object.keys(campaignData.influencers || {}).includes(user.uid) && !isInternalUser)) {
    return <></>
  }

  return (
    <div className="content-container" style={{ justifyContent: 'center', alignItems: 'center' }}>
      <URLReviewer
        igURL={reviewData[0]}
        ttURL={reviewData[1]}
        yelpURL={reviewData[2]}
        approvalHandler={reviewData[3]}
        rejectionHandler={reviewData[4]}
      />
      <StarRating
        name={ratingData[0]}
        onReviewSubmit={ratingData[1]}
        onClose={ratingData[2]}
      />
      <CampaignRateSetter
        creatorRate={ratesData[0]}
        proposedRate={ratesData[1]}
        proposedFreebies={ratesData[2]}
        isBusiness={ratesData[3]}
        submitHandler={ratesData[4]}
        cancelHandler={ratesData[5]}
      />
      <AvailabilityMessageModal
        shouldShow={showAvailabilityMessageModal}
        businessName={businessData.business_name}
        onSuccess={(data) => {
          if (isCampaignOwner) { return };  // feature only for creators
          const chatId = [user.uid, uid].sort().join('');
          get(ref(database, 'public_users')).then((snapshot) => {
            const publicUsers = snapshot.val();
            const messagesRef = ref(database, `messages/${chatId}`);
            update(child(messagesRef, 'senders'), {
              [user.uid]: publicUsers[user.uid],
              [uid]: publicUsers[uid]
            });
            push(messagesRef, {
              content: `${data.message}`,
              displayName: publicUsers[user.uid],
              senderId: user.uid,
              timestamp: Math.floor(Date.now() / 1000),
              type: 'message',
            });
            push(messagesRef, {
              content: `My upcoming availability include: ${Object.values(data.availability).filter(avail => avail.trim()).join(', ')}`,
              displayName: publicUsers[user.uid],
              senderId: user.uid,
              timestamp: Math.floor(Date.now() / 1000),
              type: 'message',
            });
          });
          toast.success("We've sent your message to the business. You can view your conversation in the messages page.");
        }}
        onError={(error) =>
          toast.error(error)
        }
      />
      {status === 'accepted' && <ConfettiAnimation />}
      {status === 'paid' && <ConfettiAnimation type='cash' />}
      {isEditing
        ? <CampaignEdit
            existingData={campaignData}
            ctas={{
              'Save': {
                isPrimary: true,
                handler: (props) => handleSave(props.data, props.errors),
              },
              'Cancel': {
                isPrimary: false,
                handler: (props) => handleCancel(props.data, props.errors),
              },
            }}
          />
        : <div className="campaign-card" style={{ margin: '20px', width: '80vw', maxWidth: '600px' }}>
            <div className="card-header">
              <h3>{!isCampaignOwner && `${businessData.business_name}: `} {campaignData.campaign_name}</h3>
              <img src={campaignData.campaign_picture || constants.defaultGrayPlaceholder} alt={campaignData.campaign_name} />
            </div>
            <div className="card-body">
              {isCampaignOwner
                ? 
                  shouldShowEdit &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={() => setIsEditing(true)}><FontAwesomeIcon icon={faEdit}/>&ensp;Edit</button>
                    </div>
                : 
                  <ProgressTimeline
                    status={status}
                    states={[
                      'invited',
                      'accepted',
                      'pending review',
                      'completed',
                      'paid'
                    ]}
                  />
              }
              {status && <div><b>Status:</b> <span className='good-color'>{status[0].toUpperCase() + status.substring(1)}</span></div>}
              <br/>
              {(campaignData.meet_date || campaignData.post_date) &&
                <div>
                  {campaignData.meet_date && <div><b>Meet/Shoot By Date: </b>{new Date(campaignData.meet_date).toLocaleDateString()}</div>}
                  {campaignData.post_date && <div><b>Post By Date: </b>{new Date(campaignData.post_date).toLocaleDateString()}</div>}
                </div>
              }
              {campaignData.location && <div><b>Location:</b> {campaignData.location.address}</div>}
              <br/>
              {isCampaignOwner && campaignData.targeted_compensation.includes('freebies') && campaignData.freebie_comp_description && <div><b>Freebies:</b> {campaignData.freebie_comp_description}</div>}
            </div>
            {isCampaignOwner
              ? <div className="card-footer">
                  {campaignData.influencers && campaignData.influencers.length !== 0 && allInfluencers &&
                    <span style={{ fontSize: '1.2em' }}>Est. Project Reach: <b>{NumberFormatter(Object.entries(campaignData.influencers).reduce((reach, influencer) => reach + (allInfluencers[influencer[0]] ? ReachCalculator(allInfluencers[influencer[0]].cached, false) : 0), 0))} viewers</b></span>
                  }
                </div>
              : <div className="card-footer">
                  <div><b>Offer:</b></div>
                  {campaignData.influencers[user.uid] && campaignData.influencers[user.uid].special && <div>Special Offer: {campaignData.influencers[user.uid].special}</div>}
                  {campaignData.targeted_compensation.includes('paid') && campaignData.influencers[user.uid]?.rate && <div>Payment: ${campaignData.influencers[user.uid].rate}</div>}
                  {campaignData.targeted_compensation.includes('freebies') && (campaignData.influencers[user.uid]?.freebie_comp_description || campaignData.freebie_comp_description) && <div>Freebies: {campaignData.influencers[user.uid]?.freebie_comp_description ? campaignData.influencers[user.uid]?.freebie_comp_description : campaignData.freebie_comp_description}</div>}
                </div>
            }
          </div>
      }

      {user && !isCampaignOwner && campaignData.influencers[user.uid] &&
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingBottom: '40px' }}>
          {campaignData.influencers[user.uid].status === 'new' &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', maxWidth: '500px' }}>
              <img src='../images/creator-flow-unfunded-img.png' alt="Unfunded Campaign Notice CTA" style={{ padding: '20px', width: '200px', height: '200px', objectFit: 'contain' }}/>
              <span>This campaign is not yet funded. Once the campaign is funded, you will be notified to accept the offer.</span>
            </div>
          }
          {campaignData.influencers[user.uid].status === 'countered' &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', maxWidth: '500px' }}>
              <img src='../images/creator-flow-unfunded-img.png' alt="Counter Offer Campaign Notice CTA" style={{ padding: '20px', width: '200px', height: '200px', objectFit: 'contain' }}/>
              <span>The business is reviewing your counter offer. You will be notified once the counter offer is approved and funded.</span>
            </div>
          }
          {campaignData.influencers[user.uid].status === 'invited' &&
            <>
              <span>You have been invited to collaborate with {businessData.business_name}!</span>
              <button className="btn diamond-btn diamond-btn--yellow" onClick={() => handleCreatorAcceptOffer()}>Accept Offer</button>
              <button className="btn diamond-btn" onClick={() => handleCreatorDeclineOffer()}>Decline Offer</button>
              {campaignData.influencers[user.uid].rate && <a style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => handleCreatorCounterOffer()}><u><b>Counter Offer</b></u></a>}
            </>
          }
          {campaignData.influencers[user.uid].status === 'accepted' &&
            <div style={{ display: 'flex', flexDirection: 'column', gap: '18px', alignItems: 'center' }}>
              <p>It's official! Your collaboration is funded and ready to go!</p>
              <p style={{ textAlign: 'center', maxWidth: '600px', width: '100%' }}>Message the business to coordinate, then submit your content for review below after it is posted.</p>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
                  <label htmlFor="tiktokSubmissionURL" style={{ maxWidth: '400px', width: '100%', textAlign: 'left' }}>Tiktok Content URL: (if any)</label>
                  <p style={{ maxWidth: '400px', width: '100%' }}><i>Tag <a href="https://www.tiktok.com/@pobbles_local" target="_blank" rel="noopener noreferrer">@pobbles_local</a></i></p>
                  <input
                    id="tiktokSubmissionURL"
                    placeholder="https://www.tiktok.com/@pobbles_local/video/1234567890"
                    value={tiktokSubmissionURL}
                    onChange={e => setTiktokSubmissionURL(e.target.value)}
                    style={{ margin: '0px 0', padding: '10px', maxWidth: '400px', width: '100%' }}
                  />
                  <br />
                  <label htmlFor="instagramSubmissionURL" style={{ maxWidth: '400px', width: '100%', textAlign: 'left' }}>Instagram Content URL: (if any)</label>
                  <p style={{ maxWidth: '400px', width: '100%' }}><i>Add <a href="https://www.instagram.com/pobbles_local/" target="_blank" rel="noopener noreferrer">@pobbles_local</a> as a collaborator</i></p>
                  <input
                    id="instagramSubmissionURL"
                    placeholder="https://www.instagram.com/p/asdfghjkl/"
                    value={instagramSubmissionURL}
                    onChange={e => setInstagramSubmissionURL(e.target.value)}
                    style={{ margin: '0px 0', padding: '10px', maxWidth: '400px', width: '100%' }}
                  />
                  <br />
                  <p style={{ textAlign: 'center', maxWidth: '400px', width: '100%' }}><i>Tagging us allows our bots to auto-update your business partner as views roll in.</i></p>
                </div>
                {campaignData.add_ai_video &&
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <br/>
                    <span>Have raw content you want to provide the business?</span>
                    <FileUpload
                      uid={user.uid}
                      businessUid={uid}
                      campaignId={id}
                      onSuccess={(message, fileName, fileDuration) => {
                        toast.success(message);
                        updateInfluencerData(user.uid, 'uploaded_raw_content', {
                          ...campaignData.influencers[user.uid].uploaded_raw_content,
                          [SanitizeKey(fileName)]: {
                            uploaded_time: Math.floor(Date.now() / 1000),
                            duration: fileDuration,
                            is_reviewed: false,
                          },
                        });
                      }}
                      onError={(message) => {
                        toast.error(message);
                      }}
                    />
                    {campaignData.influencers[user.uid].uploaded_raw_content &&
                      <>
                        {/* <span><b>Potential Earnings</b>: ${ContentCostCalculator(Object.values(campaignData.influencers[user.uid].uploaded_raw_content).map((content) => content.duration))}</span>
                        {ContentCostCalculator(Object.values(campaignData.influencers[user.uid].uploaded_raw_content).map((content) => content.duration)) === 50 && <span style={{ textAlign: 'center' }}><i>Max earnings potential for this campaign reached.<br/>You can continue providing more content to ensure that you have a better chance of receiving the full payout.</i></span>}
                        <br/> */}
                        <span><b>Uploaded Content:</b></span>
                        {Object.entries(campaignData.influencers[user.uid].uploaded_raw_content).map(([fileName, fileData]) => (
                          <div key={fileName} style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                            <span>{fileName}</span>
                            <button style={{ color: 'red' }} onClick={() => {
                              delete campaignData.influencers[user.uid].uploaded_raw_content[fileName];
                              updateInfluencerData(user.uid, 'uploaded_raw_content', campaignData.influencers[user.uid].uploaded_raw_content);
                            }}>
                              x
                            </button>
                          </div>
                        ))}
                      </>
                    }
                  </div>
                }
              </div>
              <button className="btn diamond-btn diamond-btn--yellow" onClick={() => handleCreatorSubmitContent()}>Submit Content</button>
              <a style={{ cursor: 'pointer', textAlign: 'center' }} onClick={(e) => handleCreatorMessageBusiness(e, id)}><u><b>Message Business</b></u></a>
            </div>
          }
          {campaignData.influencers[user.uid].status === 'pending review' &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', maxWidth: '500px' }}>
              <img src='../images/creator-flow-pending-img.png' alt="Review Pending Campaign Notice CTA" style={{ padding: '20px', width: '200px', height: '200px', objectFit: 'contain' }}/>
              <span>The business is currently reviewing your submission. Once they have confirmed your content within the review period, you will be notified of payment.</span>
              {!campaignData.influencers[user.uid].reviewed &&
                <>
                  <br/>
                  <span>In the meantime, review your interactions with the business.</span>
                  <button className="btn diamond-btn diamond-btn--yellow" onClick={() => handleCreatorReview()}>Review</button>
                </>
              }
            </div>
          }
          {campaignData.influencers[user.uid].status === 'completed' &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', maxWidth: '500px' }}>
              <img src='../images/creator-flow-paid-img.png' alt="Payment Campaign Notice CTA" style={{ padding: '20px', width: '200px', height: '200px', objectFit: 'contain' }}/>
              {campaignData.targeted_compensation.includes('paid') && <span>Success! The campaign has been completed and your payment is on the way. If this is your first campaign, you may need to submit your financial information for payout. Be on the lookout for an email from Mercury, our partnered financial institution.</span>}
              {campaignData.targeted_compensation.includes('freebies') && !campaignData.targeted_compensation.includes('paid') && <span>And that's a wrap! Hope you enjoyed your freebies from ${businessData.business_name}!</span>}
              {!campaignData.influencers[user.uid].reviewed &&
                <>
                  <span>In the meantime, review your interactions with the business.</span>
                  <button className="btn diamond-btn diamond-btn--yellow" onClick={() => handleCreatorReview()}>Review</button>
                </>
              }
            </div>
          }
          {campaignData.influencers[user.uid].status === 'paid' &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', maxWidth: '500px' }}>
              <img src='../images/creator-flow-paid-img.png' alt="Payment Campaign Notice CTA" style={{ padding: '20px', width: '200px', height: '200px', objectFit: 'contain' }}/>
              <span>You've been paid! Your payment will be sent to your financial institution within 3-5 business days.</span>
              {!campaignData.influencers[user.uid].reviewed &&
                <>
                  <span>Please review your interactions with the business.</span>
                  <button className="btn diamond-btn diamond-btn--yellow" onClick={() => handleCreatorReview()}>Review</button>
                </>
              }
            </div>
          }
        </div>
      }
      {!isEditing &&
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
            <div 
              style={{ padding: '10px 20px', cursor: 'pointer', borderBottom: tabValue === 0 ? '2px solid blue' : 'none' }}
              onClick={() => handleChangeTab(0)}
            >
              {isCampaignOwner ? 'Creators' : 'Expectations & Deliverables'}
            </div>
            <div 
              style={{ padding: '10px 20px', cursor: 'pointer', borderBottom: tabValue === 1 ? '2px solid blue' : 'none' }}
              onClick={() => handleChangeTab(1)}
            >
              {isCampaignOwner ? 'Performance & Data' : 'Business Details'}
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            {isCampaignOwner
              ? <>
                  {tabValue === 0 &&
                    // Creators Tab
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                      {!campaignData.is_recurring &&
                        <div style={{ flexDirection: 'row', paddingBottom: '40px' }}>
                          <button className="btn diamond-btn" onClick={() => handleBrowseInfluencers()}>Browse Creators</button>
                        </div>
                      }
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                        {campaignData.one_time_payment &&
                          <>
                            You have a pending payment of ${campaignData.one_time_payment} for this campaign. Click to fund now:
                            <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={(e) => handlePayOneTimePayment(e)}>Add Funds</button>
                            <br/>
                          </>
                        }
                        {!campaignData.is_recurring &&
                          <>
                            {campaignData.influencers && allInfluencers && Object.values(campaignData.influencers).filter(influencer => influencer.status === 'new').length > 0 &&
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '90vw', color: 'red' }}>
                                <span><b>You have {Object.values(campaignData.influencers).filter(influencer => influencer.status === 'new').length} new creators - invite them individually below, or <a style={{ cursor: 'pointer' }} onClick={(e) => handleInviteCreators(e)}><u>invite them all</u></a>!</b></span>
                              </div>
                            }
                            {campaignData.influencers && allInfluencers && Object.values(campaignData.influencers).filter(influencer => influencer.status === 'countered').length > 0 &&
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '90vw', color: 'red' }}>
                                <span><b>You have {Object.values(campaignData.influencers).filter(influencer => influencer.status === 'countered').length} counter offer! Check below to review and accept or counter.</b></span>
                              </div>
                            }
                            {campaignData.influencers && allInfluencers && Object.values(campaignData.influencers).filter(influencer => influencer.status === 'pending review').length > 0 &&
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '90vw', color: 'red' }}>
                                <span><b>You have {Object.values(campaignData.influencers).filter(influencer => influencer.status === 'pending review').length} creators with content needing review - <a style={{ cursor: 'pointer' }} onClick={(e) => handleReviewContent(e)}><u>review them now!</u></a></b></span>
                              </div>
                            }
                          </>
                        }
                        {campaignData.is_recurring && campaignData.subscription_type && campaignData.subscription_frequency &&
                          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: '10px' }}>
                            <div>This is a managed, recurring campaign.{!campaignData.influencers && <i> You will be matched with your custom curated creators shortly.</i>}</div>
                            {campaignData.did_pay_subscription && campaignData.session_id
                              ? <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                                  Click to manage the subscription:
                                  <button className="btn diamond-btn diamond-btn--sm" onClick={(e) => handleManageSubscription(e, campaignData.session_id)}>Manage</button>
                                </div>
                              : <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px', color: 'red' }}>
                                  Click to fund the subscription:
                                  <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={(e) => handlePaySubscription(e, campaignData.custom_subscription_price_id, campaignData.subscription_type, campaignData.subscription_frequency, campaignData.add_ai_video)}>Fund</button>
                                </div>
                            }
                          </div>
                        }
                        {!campaignData.influencers && recommendedCreators && recommendedCreators.length > 0 &&
                          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: '10px' }}>
                            <div>We have recommended some creators for your campaign below.</div>
                            <div>Check them out and <a style={{ cursor: 'pointer' }} onClick={(e) => handleAddCreator(e)}><u><b>add them to your campaign</b></u></a>.</div>
                          </div>
                        }
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90vw', textAlign: 'center' }}>
                          {campaignData.influencers && campaignData.influencers.length !== 0 &&
                            `${Object.values(campaignData.influencers).filter(influencer => influencer.status === 'new').length || 0} new →`
                            + ` ${Object.values(campaignData.influencers).filter(influencer => influencer.status === 'countered').length || 0} countered →`
                            + ` ${Object.values(campaignData.influencers).filter(influencer => influencer.status === 'invited').length || 0} invited →`
                            + ` ${Object.values(campaignData.influencers).filter(influencer => influencer.status === 'accepted').length || 0} accepted →`
                            + ` ${Object.values(campaignData.influencers).filter(influencer => influencer.status === 'pending review').length || 0} pending review →`
                            + ` ${Object.values(campaignData.influencers).filter(influencer => influencer.status === 'completed' || influencer.status === 'paid').length || 0} completed`
                          }
                        </div>
                      </div>
                      {campaignData && allInfluencers && (Object.keys(campaignData.influencers || {}).length > 0 || recommendedCreators.length > 0) &&
                        <div className="list-container">
                          {Object.entries(campaignData.influencers || {}).sort((a, b) => {
                              const statusA = a[1].status ? getStatusOrder(a[1].status.toLowerCase()) : 8;
                              const statusB = b[1].status ? getStatusOrder(b[1].status.toLowerCase()) : 8;
                              return statusA - statusB;
                            }).filter((creator) => !campaignData.is_recurring || creator[1].status !== 'declined').map(([id, opportunity], index) => allInfluencers[id] &&
                              <div key={index} className="profile-container" style={{ margin: '20px', padding: '20px' }}>
                                <div className="header-section" onClick={(e) => handleViewCreator(e, allInfluencers[id].username)}>
                                  <div className="profile-section" style={{ alignItems: 'center' }}>
                                    <div className="profilePic-container" style={{ height: '80px', width: '80px' }}>
                                      <img src={allInfluencers[id].profile_pic_url || defaultProfilePicUrl} alt="Profile" className="profilePic" loading="lazy" />
                                    </div>
                                    <div className="name-container" style={{ whiteSpace: 'nowrap', minWidth: '250px' }}>
                                      {publicUsers[id] && <div className="name">{publicUsers[id]}.</div>}
                                      {allInfluencers[id].display_name && <div className="display-name">({allInfluencers[id].display_name})</div>}
                                      {opportunity.status && <div className="market"><b>Status:</b> {opportunity.status}</div>}
                                      {opportunity.status && opportunity.status === 'new' && !campaignData.is_recurring && <div className="market" style={{ color: 'red', cursor: 'pointer' }} onClick={(e) => handleInviteCreators(e, id)}><b><i>Invite them to collab now!</i></b></div>}
                                    </div>
                                    <div style={{ whiteSpace: 'nowrap', padding: '0px 40px' }}>
                                      {!campaignData.is_recurring && !campaignData.influencers[id].is_comped &&
                                        <>
                                          {campaignData.influencers[id].special && <div className="market"><b>Special Offer:</b> <span>{campaignData.influencers[id].special}</span></div>}
                                          {opportunity.status === 'invited' && campaignData.influencers[id].rate && <div className="market"><b>Offered Price:</b> <span>${campaignData.influencers[id].rate}</span></div>}
                                          {opportunity.status === 'countered' && campaignData.influencers[id].rate && <div className="market"><b>Counter Offer:</b> <span>${campaignData.influencers[id].rate}</span></div>}
                                          {opportunity.status === 'countered' && campaignData.influencers[id].freebie_comp_description && <div className="market"><b>Freebies Offer:</b> <span>{campaignData.influencers[id].freebie_comp_description}</span></div>}
                                          {opportunity.status !== 'invited' && opportunity.status !== 'countered' && campaignData.influencers[id].rate && <div className="market"><b>Rate:</b> <span>${campaignData.influencers[id].rate}</span></div>}
                                          {opportunity.status !== 'new' && opportunity.status !== 'invited' && opportunity.status !== 'countered' && campaignData.influencers[id].freebie_comp_description && <div className="market"><b>Freebies:</b> <span>{campaignData.influencers[id].freebie_comp_description}</span></div>}
                                          {(opportunity.status === 'new' || opportunity.status === 'invited' ) && allInfluencers[id]?.compensation?.amount && <div className="market"><b>Creator Rate:</b> <span>${allInfluencers[id].compensation.amount}</span></div>}
                                          {(opportunity.status === 'new' || opportunity.status === 'invited' ) && !allInfluencers[id]?.compensation?.amount && <div className="market"><b>Suggested Rate:</b> <span>${constants.defaultPobblesSuggestedRate}</span></div>}
                                        </>
                                      }
                                      {campaignData.influencers[id].is_comped && <div className="market"><i>Comped Creator</i></div>
                                      }
                                      {allInfluencers[id].cached && <div className="market"><b>Est. Reach:</b> {ReachCalculator(allInfluencers[id].cached, true)}</div>}
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '20px', width: '150px' }}>
                                  {opportunity.status === 'new' && !campaignData.is_recurring &&
                                    <>
                                      <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={(e) => handleInviteCreators(e, id)}>Invite</button>
                                      <button className="btn diamond-btn diamond-btn--sm" onClick={(e) => handleRemoveCreator(e, id)}>Remove</button>
                                    </>
                                  }
                                  {opportunity.status === 'countered' && !campaignData.is_recurring &&
                                    <>
                                      <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={(e) => handleBusinessAcceptOffer(e, id)}>Accept Offer</button>
                                      <button className="btn diamond-btn diamond-btn--sm" onClick={(e) => handleBusinessCounterOffer(e, id)}>Counter Offer</button>
                                      <a style={{ cursor: 'pointer', textAlign: 'center' }} onClick={(e) => handleRemoveCreator(e, id)}><u><b>Reject</b></u></a>
                                    </>
                                  }
                                  {opportunity.status === 'invited' && !campaignData.is_recurring &&
                                    <button className="btn diamond-btn diamond-btn--sm" onClick={(e) => handleRemoveCreator(e, id)}>Uninvite</button>
                                  }
                                  {opportunity.status === 'accepted' &&
                                    <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={(e) => handleMessageCreator(e, id)}>Message</button>
                                  }
                                  {opportunity.status === 'declined' && !campaignData.is_recurring &&
                                    <button className="btn diamond-btn diamond-btn--sm" onClick={(e) => handleRemoveCreator(e, id)}>Remove</button>
                                  }
                                  {opportunity.status === 'pending review' &&
                                    <>
                                      <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={(e) => handleReviewContent(e, id)}>Review</button>
                                      <button className="btn diamond-btn diamond-btn--sm" onClick={(e) => handleMessageCreator(e, id)}>Message</button>
                                    </>
                                  }
                                  {opportunity.status === 'completed' &&
                                    <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={(e) => handleMessageCreator(e, id)}>Message</button>
                                  }
                                  {opportunity.status !== 'pending review' && campaignData.is_recurring &&
                                    <button className="btn diamond-btn diamond-btn--sm" onClick={(e) => handleViewCreator(e, allInfluencers[id].username)}>View</button>
                                  }
                                  </div>
                                </div>
                              </div>
                          )}
                          {recommendedCreators && recommendedCreators.map((id, index) => allInfluencers[id] &&
                            <div key={index} className="profile-container" style={{ margin: '20px', padding: '20px', backgroundColor: '#eeeeee' }}>
                              <div className="header-section" onClick={(e) => handleViewCreator(e, allInfluencers[id].username)}>
                                <div className="profile-section" style={{ alignItems: 'center' }}>
                                  <div className="profilePic-container" style={{ height: '80px', width: '80px' }}>
                                    <img src={allInfluencers[id].profile_pic_url || defaultProfilePicUrl} alt="Profile" className="profilePic" />
                                  </div>
                                  <div className="name-container" style={{ whiteSpace: 'nowrap', minWidth: '250px' }}>
                                    <div className="market"><i>pobbles recommends:</i></div>
                                    {publicUsers[id] && (<div className="name">{publicUsers[id]}.</div>)}
                                    {allInfluencers[id].display_name && <div className="display-name">({allInfluencers[id].display_name})</div>}
                                  </div>
                                  <div style={{ whiteSpace: 'nowrap', padding: '0px 40px' }}>
                                    {allInfluencers[id]?.compensation?.amount ? <div className="market"><b>Creator Rate:</b> <span>${allInfluencers[id].compensation.amount}</span></div> : <div className="market"><b>Suggested Rate:</b> <span>${constants.defaultPobblesSuggestedRate}</span></div>}
                                    {allInfluencers[id].cached && <div className="market"><b>Est. Reach:</b> {ReachCalculator(allInfluencers[id].cached, true)}</div>}
                                  </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '20px', width: '150px' }}>
                                  <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={(e) => handleAddCreator(e, id)}>Add</button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </div>
                  }
                  {tabValue === 1 &&
                    // Performance & Data Tab
                    <div>
                      {fundedCreatorsStatsData &&
                        <StatsCard
                          title={"Funded Creators"}
                          refreshDate={new Date().toLocaleDateString()}
                          statCardItems={fundedCreatorsStatsData}
                        />
                      }
                      {allCreatorsStatsData &&
                        <StatsCard
                          title={"All Creators"}
                          refreshDate={new Date().toLocaleDateString()}
                          statCardItems={allCreatorsStatsData}
                        />
                      }
                    </div>
                  }
                </>
              : <>
                  {tabValue === 0 &&
                    // Expectations & Deliverables Tab
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {campaignData.tt_expectations && <span style={{ width: '50vw' }}>For TikTok: {campaignData.tt_expectations}</span>}
                      {campaignData.ig_expectations && <span style={{ display: 'inline-block', whiteSpace: 'pre-line', width: '50vw' }}>For Instagram: {campaignData.ig_expectations}</span>}
                      {campaignData.creator_details && <span style={{ display: 'inline-block', whiteSpace: 'pre-line', width: '50vw' }}><b>Note from business:</b> {campaignData.creator_details.split("<br/>").join("\n")}</span>}
                    </div>
                  }
                  {tabValue === 1 &&
                    // Brand Notes Tab
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {businessData.business_name && <span style={{ display: 'inline-block', whiteSpace: 'pre-line', width: '50vw' }}><b>Business Name:</b> {businessData.business_name}</span>}
                      {businessData.location?.address && <span style={{ display: 'inline-block', whiteSpace: 'pre-line', width: '50vw' }}><b>Business Location:</b> {businessData.location?.address}</span>}
                      {businessData.website_url && <span style={{ display: 'inline-block', whiteSpace: 'pre-line', width: '50vw' }}><b>Business Website:</b> {businessData.website_url}</span>}
                      {businessData.yelp_url && <span style={{ display: 'inline-block', whiteSpace: 'pre-line', width: '50vw' }}><b>Business Yelp:</b> {businessData.yelp_url}</span>}
                    </div>
                  }
                </>
            }
          </div>
        </>
      }
    </div>
  );
}

export default ViewCampaign;

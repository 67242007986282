import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as constants from '../constants.jsx';
import '../css/web.css';

const CampaignRateSetter = ({ creatorRate, proposedRate, proposedFreebies, isBusiness, submitHandler, cancelHandler }) => {
  const [showing, setShowing] = useState(true);
  const [rate, setRate] = useState(0);
  const [freebies, setFreebies] = useState(null);

  const handleSubmit = (() => {
    if (!rate || !rate.trim()) {
      toast.error("Please enter a rate value before submitting.");
      return
    }
    if (proposedFreebies && (!freebies || !freebies.trim())) {
      toast.error("Please enter a description of the freebies before submitting.");
      return
    }
    submitHandler(rate.trim(), freebies ? freebies.trim() : "");
    setShowing(false);
  });

  const handleCancel = (() => {
    cancelHandler();
    setShowing(false);
  });

  useEffect(() => {
    setRate(proposedRate || constants.defaultPobblesSuggestedRate);
    setFreebies(proposedFreebies);
    setShowing(true);
  }, [proposedRate, proposedFreebies]);

  return (
    <div>
      {submitHandler && cancelHandler && showing &&
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div style={{
            position: 'relative',
            width: '40vw',
            minWidth: '300px',
            minHeight: '200px',
            zIndex: 0,
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              {isBusiness && (creatorRate ? <p>Creator Preset Rate: ${creatorRate}</p> : <p>Pobbles Suggested Rate: ${constants.defaultPobblesSuggestedRate}</p>)}
              <div>
                Set a price ($):&ensp;
                <input
                  type="number"
                  placeholder="Set rate"
                  className="input-field"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>
              {freebies &&
                <div>
                  Freebies:&ensp;
                  <input
                    value={freebies}
                    placeholder="3 free widgets for review."
                    className="input-field"
                    onChange={(e) => setFreebies(e.target.value)}
                    style={{ width: '100%' }}
                  />
                </div>
              }
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '20px' }}>
                <button className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" onClick={handleSubmit}>Submit</button>
                <button className="btn diamond-btn diamond-btn--sm" onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default CampaignRateSetter;

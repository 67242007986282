import { faBowlFood, faCalendarCheck, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTiktok, faYelp } from '@fortawesome/free-brands-svg-icons';

export const backendDomain = process.env.NODE_ENV === 'production' ? "https://pobbles-backend-v6diyrelva-uc.a.run.app" : "https://smashing-usefully-urchin.ngrok-free.app";

export const keys = {
  statsig: 'client-LVQw5JoLbTPRZrSoQZySStWbqAEgsWcsvLZJcy1l09V'
}

export const availableContentTags = [
  {
    name: 'Food',
    description: 'Food',
    color: '#d2c497',
    icon: faBowlFood,
  }, {
    name: 'Events',
    description: 'Event',
    color: '#97d2c4',
    icon: faCalendarCheck,
  }, {
    name: 'Local',
    description: 'Local',
    color: '#c497d2',
    icon: faLocationDot,
  },
];

export const availablePlatforms = [
  {
    name: 'IG',
    description: 'Instagram',
    logo: faInstagram,
  }, {
    name: 'TT',
    description: 'TikTok',
    logo: faTiktok,
  }, {
    name: 'Yelp',
    description: 'Yelp',
    logo: faYelp,
  }
]

export const availableCompensations = [
  {
    name: 'freebies',
    description: 'Freebies / Non-monetary',
  }, {
    name: 'paid',
    description: 'Paid'
  },
];

export const defaultGrayPlaceholder = '../images/gray-image-placeholder.png';

export const availableCampaignTypes = [
  {
    id: 'managed',
    name: 'Recurring (Managed)',
    description: "Let us know what you want and how often you want it. We'll handle the rest.",
    image: '../images/managed.png',
    isRecurring: true,
  }, {
    id: 'diy',
    name: 'One-time (DIY)',
    description: 'Browse through our creators and pick who you want to collab with.',
    image: '../images/diy.png',
    isRecurring: false,
  },
];

export const availableRecurringSubscriptions = [
  {
    id: 'starter',
    name: 'Starter',
    price: 200,
    description: '1-2 smaller creators to help boost your business',
    image: '../images/subscription-image-1.png',
  }, {
    id: 'pro',
    name: 'Pro',
    price: 500,
    description: 'A group of smaller creators or one large creator',
    image: '../images/subscription-image-2.png',
  }, {
    id: 'viral',
    name: 'Viral',
    price: 1000,
    description: 'The absolute best local creators, delivered to your business',
    image: '../images/subscription-image-3.png',
  },
];

export const availableSubscriptionFrequencies = [
  {
    id: 1,
    name: 'Monthly',
    description: 'every month',
  }, {
    id: 3,
    name: 'Quarterly',
    description: 'every 3 months',
  }, {
    id: 6,
    name: 'Semi-annually',
    description: 'every 6 months',
  },
];

export const defaultPobblesSuggestedRate = '100';

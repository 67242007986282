export function SanitizeKey(key) {
  const sanitizedKey = key.replace(/[.#$/[\]]/g, '');
  if (sanitizedKey.trim() === '') {
    return 'EMPTY_KEY';
  }
  return sanitizedKey;
}

export function SanitizeObjectKeys(obj) {
  const sanitizedObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    const sanitizedKey = SanitizeKey(key);
    if (typeof value === 'object' && value !== null) {
      sanitizedObj[sanitizedKey] = SanitizeObjectKeys(value);
    } else {
      sanitizedObj[sanitizedKey] = value;
    }
  }
  return sanitizedObj;
}

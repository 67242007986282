import React, { useState, useEffect } from 'react';
import { database } from '../lib/firebase';
import { ref, child, get, update } from "firebase/database";
import Select from 'react-select';
import { NumberFormatter } from '../lib/NumberFormatter.jsx';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYelp } from '@fortawesome/free-brands-svg-icons';
import BadgesContainer from './badgesContainer';
import * as constants from '../constants.jsx';
import '../css/web.css';

const InfluencerDisplay = ({ data, businessUid, campaignId, campaignData }) => {
  const [allData, setAllData] = useState([]);
  const [shownData, setShownData] = useState([]);

  const compensationLimit = 1000;

  const [isHoveringReach, setIsHoveringReach] = useState(false);

  const [sortAttribute, setSortAttribute] = useState('reach');
  const [sortOrder, setSortOrder] = useState('desc');
  const [markets, setMarkets] = useState([]);
  const [compensationAmount, setCompensationAmount] = useState(compensationLimit);
  const [searchFilter, setSearchFilter] = useState("");
  const [trendingToggle, setTrendingToggle] = useState(false);

  const availableMarkets = [
    "San Francisco Bay Area",
    "Sacramento",
  ];

  const sort = (datasource, attribute) => {
    var updatedSortAttribute = sortAttribute;
    var updatedSortOrder = sortOrder;
    if (attribute && attribute !== 'undefined') {
      if (attribute === sortAttribute) {
        updatedSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(updatedSortOrder);
      } else {
        updatedSortAttribute = attribute;
        setSortAttribute(attribute)
      }
    }
    const sortedData = [...datasource].sort((a, b) => {
      const order = updatedSortOrder === 'asc' ? 1 : -1;
      return a[updatedSortAttribute] > b[updatedSortAttribute] ? order : -order;
    });
    return sortedData
  };

  const handleSearchFilter = event => {
    const value = event.target.value;
    setSearchFilter(value);
    updateShownData(value, undefined, undefined, undefined, undefined);
  };

  const handleTrendingToggle = event => {
    const value = event.target.checked;
    setTrendingToggle(value);
    updateShownData(undefined, value, undefined, undefined, undefined);
  };

  const handleCompensationAmountChange = (event) => {
    const value = event.target.value;
    setCompensationAmount(value);
    updateShownData(undefined, undefined, undefined, value, undefined);
  };

  const updateShownData = (updatedSearchFilter = searchFilter, updatedTrendingToggle = trendingToggle, updatedMarkets = markets, updatedCompensationAmount = compensationAmount, updatedSortAttribute) => {
    var datasource = allData;
    if (updatedSearchFilter) {
      datasource = datasource.filter(influencer => influencer.publicName.concat((influencer.displayName || '')).toLowerCase().includes(updatedSearchFilter.toLowerCase()));
    }
    if (updatedTrendingToggle) {
      datasource = datasource.filter(influencer => influencer.isTrending);
    }
    if (updatedMarkets && updatedMarkets.length > 0) {
      datasource = datasource.filter(influencer => updatedMarkets.includes(influencer.market));
    }
    if (updatedCompensationAmount && parseInt(updatedCompensationAmount) < compensationLimit) {
      datasource = datasource.filter(influencer => (influencer.compensationAmount === undefined && parseInt(updatedCompensationAmount) >= constants.defaultPobblesSuggestedRate) || (influencer.compensationAmount && parseInt(influencer.compensationAmount) <= updatedCompensationAmount));
    }
    datasource = sort(datasource, updatedSortAttribute);
    setShownData(datasource);
  };

  const handleViewCreator = (e, username) => {
    e.stopPropagation();
    let url = `${window.location.origin}/#/user/${username}`;
    if (businessUid && campaignId) {
      url += `?uid=${businessUid}&id=${campaignId}`;
    }
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const handleHireCreator = (e, creatorUid, creatorPublicName) => {
    e.stopPropagation();
    if (businessUid && campaignId) {
      const campaignDbRef = ref(database, `campaigns/${businessUid}/${campaignId}`);

      get(campaignDbRef)
        .then((snapshot) => {
          if (!snapshot.exists()) {
            toast.error('Oops! Your campaign was not found. Please visit your campaign dashboard and select a campaign to browse with.');
            return
          }
          const campaignName = snapshot.val().campaign_name;
          const influencers = snapshot.val().influencers;
          if (influencers && creatorUid in influencers) {
            toast.error(`Oops! ${creatorPublicName} is already a part of ${campaignName}! Visit your campaign dashboard to view the collaboration.`);
            return
          }
          update(child(campaignDbRef, `/influencers/${creatorUid}`), {
            'status': 'new'
          });
          toast.success(`Successfully added ${creatorPublicName} to your campaign! Visit the campaign page to fund the collaboration now.`);
        }).catch((error) => {
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    setAllData(data || []);
  }, [data]);

  useEffect(() => {
    updateShownData();
  }, [allData]);

  return (
    <div style={{ width: '100%' }}>
      <div className="list-controls">
        <div className="select-container">
          <label style={{ width: '200px', textAlign: 'left' }}>Filter by market:</label>
          <Select
            isMulti
            isClearable
            value={markets.map(market => ({ value: market, label: market }))}
            onChange={selectedOptions => {
              const selectedMarkets = selectedOptions.map(option => option.value);
              setMarkets(selectedMarkets);
              updateShownData(undefined, undefined, selectedMarkets, undefined, undefined);
            }}
            options={availableMarkets.map(market => ({ value: market, label: market }))}
            styles={{
              control: (base, state) => ({
                ...base,
                borderColor: state.isFocused ? '#d2c497' : base.borderColor,
                boxShadow: state.isFocused ? '0 0 0 2px #d2c497' : base.boxShadow,
              }),
              width: '100px'
            }}
          />
        </div>
        <div className="slider-container">
          <label style={{ width: '200px', textAlign: 'left' }}>Filter by Suggested Rate:</label>
          <input 
            type="range" 
            min="0" 
            max={compensationLimit}
            value={compensationAmount}
            onChange={handleCompensationAmountChange} 
            style={{ width: '150px', padding: 0 }}
          /> 
          <span style={{ paddingLeft: '10px' }}>{compensationAmount < compensationLimit ? `$${compensationAmount}` : 'Max' }</span>
        </div>
        <div>
          <label style={{ width: '150px', textAlign: 'left' }}>Filter by name:</label>
          <input
            type="text"
            placeholder="name"
            value={searchFilter}
            onChange={handleSearchFilter}
            style={{ margin: '0px', width: '200px' }}
          />
        </div>
        <div>
          <label style={{ width: '150px', textAlign: 'left' }}>Show trending creators only:</label>
          <input
            type="checkbox"
            checked={trendingToggle}
            onChange={handleTrendingToggle}
            style={{ margin: '10px' }}
          />
        </div>
      </div>
      <div className="list-container">
        {shownData.map((userData, index) => (
          <div key={index} className="profile-container" style={{ margin: '20px' }}>
            <div className="header-section" onClick={(e) => handleViewCreator(e, userData.username)}>
              <div className="profile-section" style={{ width: '400px' }}>
                <div className="profilePic-container" style={{ height: '120px', width: '120px' }}>
                  <img src={userData.profilePicture} alt="Profile" className="profilePic" loading="lazy" />
                </div>
                <div>
                  <div className="name-container">
                    {userData.publicName && <div className="name">{userData.publicName}</div>}
                    {userData.displayName && <div className="display-name">({userData.displayName})</div>}
                    {userData.market && <div className="market">{userData.market}</div>}
                    {userData.compensationTags && userData.compensationTags.includes("paid") && userData.compensationAmount ? <div className="market">Rate: ${userData.compensationAmount}</div> : <div className="market">Suggested Rate: {constants.defaultPobblesSuggestedRate}</div>}
                  </div>
                  <BadgesContainer
                    instagramData={userData.instagramData}
                    tiktokData={userData.tiktokData}
                    yelpData={null} // hide yelp elite badge from browse
                    isPobblesPro={userData.isPobblesPro}
                  />
                </div>
              </div>
              <div className="big-data-and-badges-container">
                <div className="big-data-item">
                  <span
                    className="big-data-label hover-container"
                    onMouseEnter={() => setIsHoveringReach(true)}
                    onMouseLeave={() => setIsHoveringReach(false)}
                  >
                    Est. Reach:
                    {isHoveringReach && <div className="hover-text">This is Pobbles' best estimate of {userData.publicName.slice(0, -3)}'s audience size, calculated from the follower sizes and engagement levels on each platform.</div>}
                  </span>
                  <span className="big-data-number" style={{ fontWeight: 'bold' }}>{NumberFormatter(userData.reach)}</span><span className="big-data-number" style={{ fontSize: '20px' }}>views</span>
                </div>
                <div className="badges-container">
                  {[
                    {
                      condition: userData.yelpData?.data?.eliteYears,
                      component: (
                        <div className="badge-container" key="yelp">
                          <div className="badge-icon-container" style={{backgroundColor: '#c41200'}}>
                            <FontAwesomeIcon icon={faYelp} className="badge-icon"/>
                          </div>
                          <span className="badge-text">Elite</span>
                        </div>
                      ),
                      followers: Number.MAX_SAFE_INTEGER
                    },
                    {
                      condition: userData.instagramData?.data?.followers,
                      component: (
                        <div className="badge-container" key="instagram">
                          <div className="badge-icon-container" style={{backgroundColor: '#f1437a'}}>
                            <FontAwesomeIcon icon={faInstagram} className="badge-icon"/>
                          </div>
                          <span className="badge-text">{NumberFormatter(userData?.instagramData?.data?.followers)}</span>
                        </div>
                      ),
                      followers: userData.instagramData?.data?.followers || 0
                    },
                    {
                      condition: userData.tiktokData?.userData?.follower_count,
                      component: (
                        <div className="badge-container" key="tiktok">
                          <div className="badge-icon-container" style={{backgroundColor: '#000000'}}>
                            <FontAwesomeIcon icon={faTiktok} className="badge-icon"/>
                          </div>
                          <span className="badge-text">{NumberFormatter(userData?.tiktokData?.userData?.follower_count)}</span>
                        </div>
                      ),
                      followers: userData.tiktokData?.userData?.follower_count || 0
                    }
                  ]
                  .sort((a, b) => b.followers - a.followers)
                  .map((platform) => platform.condition && platform.followers > 0 ? platform.component : <></>)}
                </div>
              </div>
              <div className="edit-profile-button-container">
                {campaignId && campaignData && 
                  <>
                    {!Object.keys(campaignData.influencers || []).includes(userData.uid) &&
                      <button className="btn diamond-btn diamond-btn--yellow" onClick={(e) => handleHireCreator(e, userData.uid, userData.publicName)}>Add to Campaign</button>
                    }
                    <button className="btn diamond-btn" onClick={(e) => handleViewCreator(e, userData.username)}>View Profile</button>
                  </>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InfluencerDisplay;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag, FeatureFlag } from './lib/FeatureFlags.jsx';
import { database } from './lib/firebase';
import { ref, onValue, push, update, remove, off } from "firebase/database";
import axios from 'axios';
import { ReachCalculator, TrendingCalculator } from './lib/ReachCalculator.jsx';
import InfluencerDisplay from './components/influencerDisplay';
import CampaignCardDisplay from './components/campaignCardDisplay';
import ChatWindow from './components/chatWindow';
import TransactionFactory from './lib/TransactionFactory';
import PaymentsListItem from './components/paymentsListItem';
import { toast } from 'react-toastify';
import './css/web.css';

const Admin = ({user}) => {
  const isInternalUser = useFeatureFlag(FeatureFlag.isInternalUser);

  const [influencerData, setInfluencerData] = useState([]);
  const [allInfluencerData, setAllInfluencerData] = useState([]);
  const [allBusinessData, setAllBusinessData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [campaignData, setCampaignData] = useState({});
  const [campaignTransferToolData, setCampaignTransferToolData] = useState({
    campaignUrl: '',
    newBusinessUid: '',
    businessUid: null,
    businessData: null,
    campaignId: null,
    campaignData: null,
    newBusinessData: null,
  });
  const [subscriptionToolData, setSubscriptionToolData] = useState({
    campaignUrl: '',
    creatorUid: '',
    rate: '',
    businessUid: null,
    businessData: null,
    campaignId: null,
    campaignData: null,
    creatorData: null, 
  });
  const [messageData, setMessageData] = useState({
    allMessages: [],
    shownMessages: [],
    businessUid: '',
    businessData: null,
    searchFilter: '',
  });
  const [paymentsData, setPaymentsData] = useState({
    subscriptions: null,
    transactions: null,
  });
  const [tokensData, setTokensData] = useState({});

  const navigate = useNavigate();

  const handleChangeTab = ((newValue) => {
    if (newValue === tabValue) {
      setTabValue("");
    } else {
      setTabValue(newValue);
    }
  });

  const handleCampaignClick = ((uid, id) => {
    if (uid && id) {
      navigate(`/campaign/${uid}/${id}`);
    }
  });

  const handleTransferCampaignOwnership = () => {
    // validation
    if (!campaignTransferToolData.campaignUrl) {
      toast.error('Please enter a campaign URL');
      return;
    }
    if (!campaignTransferToolData.businessData || !campaignTransferToolData.campaignData) {
      toast.error('Please enter a valid campaign URL');
      return;
    }
    if (!campaignTransferToolData.businessUid === user.uid) {
      toast.error('Error - you do not have permission to transfer this campaign');
      return;
    }
    if (!campaignTransferToolData.newBusinessUid) {
      toast.error('Please enter the new business UID');
      return;
    }
    if (!campaignTransferToolData.newBusinessData) {
      toast.error('Please enter a valid new business UID');
      return;
    }
    if (campaignTransferToolData.businessUid === campaignTransferToolData.newBusinessUid) {
      toast.error('Error - the new business UID is the same as the old business UID');
      return;
    }
    if (campaignTransferToolData.campaignData.influencers && Object.keys(campaignTransferToolData.campaignData.influencers).length > 0) {
      toast.error('Error - this campaign has already invited influencers');
      return;
    }
    update(ref(database, `campaigns/${campaignTransferToolData.newBusinessUid}/${campaignTransferToolData.campaignId}`), {
      ...campaignTransferToolData.campaignData,
      business_id: campaignTransferToolData.newBusinessUid,
    });
    remove(ref(database, `campaigns/${campaignTransferToolData.businessUid}/${campaignTransferToolData.campaignId}`));
    navigate(`/campaign/${campaignTransferToolData.newBusinessUid}/${campaignTransferToolData.campaignId}`);
  };

  const handleInviteCreatorToSubscription = () => {
    // validation
    if (!subscriptionToolData.campaignUrl) {
      toast.error('Please enter a campaign URL');
      return;
    }
    if (!subscriptionToolData.businessData || !subscriptionToolData.campaignData) {
      toast.error('Please enter a valid campaign URL');
      return;
    }
    if (!subscriptionToolData.creatorUid) {
      toast.error('Please enter a creator UID');
      return;
    }
    if (!subscriptionToolData.creatorData) {
      toast.error('Please enter a valid creator UID');
      return;
    }
    if (!subscriptionToolData.rate || parseInt(subscriptionToolData.rate) === 0) {
      toast.error('Please enter a valid rate');
      return;
    }
    if (!subscriptionToolData.campaignData.is_recurring) {
      toast.error('Error - this is not a recurring subscription campaign.');
      return;
    }
    if (subscriptionToolData.campaignData.influencers && Object.keys(subscriptionToolData.campaignData.influencers).includes(subscriptionToolData.creatorUid) && subscriptionToolData.campaignData.influencers[subscriptionToolData.creatorUid]?.status !== 'new') {
      toast.error(`Error - this campaign has already invited ${subscriptionToolData.creatorData.publicName}`);
      return
    }

    const transaction = TransactionFactory(
      parseInt(subscriptionToolData.rate)*100,
      "usd",
      subscriptionToolData.businessUid,
      subscriptionToolData.creatorData.publicName,
      subscriptionToolData.creatorUid
    );
    const transactionsRef = ref(database, 'transactions');
    push(transactionsRef, transaction)
      .then(() => {
        update(ref(database, `campaigns/${subscriptionToolData.businessUid}/${subscriptionToolData.campaignId}/influencers`), {
          [subscriptionToolData.creatorUid]: {
            status: 'invited',
            rate: parseInt(subscriptionToolData.rate),
            status_updated_time: Math.floor(Date.now() / 1000),
          },
        });
      })
      .catch(function(error) {
        toast.error(error.message);
        return
      });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setMessageData({
      ...messageData,
      searchFilter: value,
    });
  };

  const dataOverviewTab = (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', padding: '10px' }}>
      <div><b>Total Creators:</b> {influencerData.length}</div>
      <div><b>Yelp Elites:</b> {influencerData.filter(userData => userData.yelpData && userData.yelpData.data && userData.yelpData.data.eliteYears).length}</div>
      <br/>
      <div><b>Total Est. Reach: {influencerData.reduce((reach, creator) => reach + creator.reach, 0).toLocaleString()}</b></div>
      <div><b>Total IG Following:</b> {influencerData.filter(userData => userData.instagramData && userData.instagramData.data && userData.instagramData.data.followers).reduce((following, creator) => following + creator.instagramData.data.followers, 0).toLocaleString()}</div>
      <div><b>Total TT Following:</b> {influencerData.filter(userData => userData.tiktokData && userData.tiktokData.userData).reduce((following, creator) => following + creator.tiktokData.userData.follower_count, 0).toLocaleString()}</div>
      <br/>
      <div><b>Total Businesses:</b> {businessData.length}</div>
      <br/>
      <div><b>Total Campaigns:</b> {Object.values(campaignData).reduce((result, obj) => result.concat(Object.values(obj)), []).length}</div>
    </div>
  );

  const creatorsTab = (
    <InfluencerDisplay
      data={influencerData}
    />
  );

  const businessAndCampaignsTab = (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {businessData && businessData.map((business, index) => 
        <div key={index}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
            <b>{business.name} [{business.uid}] ({business.market}):</b>
          </div>
          <CampaignCardDisplay
            campaigns={Object.entries(business.campaigns)}
            influencerData={influencerData}
            businessData={allBusinessData}
            shouldShowCreate={false}
            type='business'
            onClick={handleCampaignClick}
          />
        </div>
      )}
    </div>
  );

  const campaignTransferToolTab= (
    <div style={{ height: 'calc(100dvh - 100px)', width: '100%' }}>
      <h1 style={{ padding: '20px', textAlign: 'center' }}>Campaign Transfer Tool</h1>
      <div style={{ padding: '20px' }}>
        <div className="edit-field">
          <input
            id="campaignUrl"
            name="campaignUrl"
            value={campaignTransferToolData.campaignUrl}
            placeholder="https://app.pobbles.co/#/campaign/<business_uid>/<campaign_id>"
            className="input-field"
            onChange={(e) => setCampaignTransferToolData({
              ...campaignTransferToolData,
              campaignUrl: e.target.value
            })}
          />
        </div>
        Business Name: <b>{campaignTransferToolData.businessData ? campaignTransferToolData.businessData.business_name || "BUSINESS NAME NOT FOUND" : "N/A"}</b>
        &ensp;
        Campaign Name: <b>{campaignTransferToolData.campaignData ? campaignTransferToolData.campaignData.campaign_name || "CAMPAIGN NAME NOT FOUND" : "N/A"}</b>
      </div>
      <div style={{ padding: '20px' }}>
        <div className="edit-field">
          <input
            id="newBusinessUid"
            name="newBusinessUid"
            value={campaignTransferToolData.newBusinessUid}
            placeholder="Business UID to transfer campaign ownership to"
            className="input-field"
            onChange={(e) => setCampaignTransferToolData({
              ...campaignTransferToolData,
              newBusinessUid: e.target.value
            })}
          />
        </div>
        New Business Name: <b>{campaignTransferToolData.newBusinessData ? campaignTransferToolData.newBusinessData.business_name || "BUSINESS NAME NOT FOUND" : "N/A"}</b>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button className='btn diamond-btn diamond-btn--yellow' onClick={handleTransferCampaignOwnership}>Transfer</button>
      </div>
    </div>
  );

  const subscriptionInviteToolTab = (
    <div style={{ height: 'calc(100dvh - 100px)', width: '100%' }}>
      <h1 style={{ padding: '20px', textAlign: 'center' }}>Invite Creator to Subscription</h1>
      <div style={{ padding: '20px' }}>
        <div className="edit-field">
          <input
            id="campaignUrl"
            name="campaignUrl"
            value={subscriptionToolData.campaignUrl}
            placeholder="https://app.pobbles.co/#/campaign/<business_uid>/<campaign_id>"
            className="input-field"
            onChange={(e) => setSubscriptionToolData({
              ...subscriptionToolData,
              campaignUrl: e.target.value
            })}
          />
        </div>
        Business Name: <b>{subscriptionToolData.businessData ? subscriptionToolData.businessData.business_name || "BUSINESS NAME NOT FOUND" : "N/A"}</b>
        &ensp;
        Campaign Name: <b>{subscriptionToolData.campaignData ? subscriptionToolData.campaignData.campaign_name || "CAMPAIGN NAME NOT FOUND" : "N/A"}</b>
      </div>
      <div style={{ padding: '20px' }}>
        <div className="edit-field">
          <input
            id="creatorUid"
            name="creatorUid"
            value={subscriptionToolData.creatorUid}
            placeholder="Creator UID to invite"
            className="input-field"
            onChange={(e) => setSubscriptionToolData({
              ...subscriptionToolData,
              creatorUid: e.target.value
            })}
          />
        </div>
        Creator to invite: <b>{subscriptionToolData.creatorData ? `${subscriptionToolData.creatorData.publicName} (${subscriptionToolData.creatorData.username})` : "N/A"}</b>
      </div>
      <div style={{ padding: '20px' }}>
        Rate ($)
        <div className="edit-field">
          <input
            id="rate"
            name="rate"
            type="number"
            min="0"
            max="1000000"
            step="1"
            value={subscriptionToolData.rate}
            placeholder="150"
            className="input-field"
            onChange={(e) => setSubscriptionToolData({
              ...subscriptionToolData,
              rate: e.target.value
            })}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button className='btn diamond-btn diamond-btn--yellow' onClick={handleInviteCreatorToSubscription}>Invite</button>
      </div>
    </div>
  );

  const messagesTab = (
    <div style={{ height: 'calc(100dvh - 65px)', width: '100%' }}>
      <div style={{ padding: '20px' }}>
        <div className="edit-field">
          <input
            id="business_uid"
            name="business_uid"
            value={messageData.businessUid}
            placeholder="Business UID to message as"
            className="input-field"
            onChange={(e) => setMessageData({
              ...messageData,
              businessUid: e.target.value
            })}
          />
        </div>
        Viewing as: <b>{messageData.businessData ? messageData.businessData?.business_name || "BUSINESS NAME NOT FOUND" : "Admin"}</b>
        <div style={{ paddingTop: '20px' }}>
          <label style={{ width: '150px', textAlign: 'left' }}>Search:</label>
          <input
            type="text"
            value={messageData.searchFilter}
            onChange={handleSearchFilter}
            style={{ margin: '0px', width: '100%' }}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100dvh - 185px)'}}>
        <ChatWindow
          messageData={messageData.shownMessages}
          influencerData={allInfluencerData}
          businessesData={allBusinessData}
          uidToMessage={null}
          userUid={messageData.businessData ? messageData.businessUid : null}
        />
      </div>
    </div>
  );

  const paymentsTab = (
    <>
      {paymentsData.subscriptions && paymentsData.subscriptions.length > 0 &&
        <div className="list-container">
          <h3>Recurring Subscriptions</h3>
          {paymentsData.subscriptions.map((subscription, index) => {
            return Object.values(subscription).reverse().filter((subscription) => subscription.livemode).map((data, dataIndex) => {
              return (
                <PaymentsListItem
                  key={(index * 10) + dataIndex}
                  date={new Date(data.created * 1000).toLocaleDateString()}
                  title={`[${allBusinessData[data.business_uid]?.business_name}] Subscription ${data.subscription.slice(-5)}`}
                  total={data.total}
                  currency={data.currency}
                  status={data.status}
                  detailedContent={
                    <a href={data.hosted_invoice_url} target="_blank" rel="noopener noreferrer" className="payments-link">
                      View Invoice
                    </a>
                  }
                />
              );
            });
          })}
        </div>
      }
      {paymentsData.transactions && paymentsData.transactions.length > 0 &&
        <div className="list-container">
          <h3>All Transactions</h3>
          {paymentsData.transactions.filter((transaction) => transaction.livemode).sort((a,b) => b.created - a.created).map((transaction, index) => {
            return (
              <PaymentsListItem
                key={index}
                date={new Date(transaction.created * 1000).toLocaleDateString()}
                title={`[${allBusinessData[transaction.business_uid]?.business_name}] ${transaction.line_items[0]?.price.type === 'recurring' ? 'New Subscription Creation' : 'Campaign Payment'}`}
                total={transaction.amount_total}
                currency={transaction.currency}
                status={transaction.status}
                detailedContent={transaction.line_items.map((lineItem, lineItemIndex) => (
                  <div key={lineItemIndex}>
                    {'>'} {lineItem.description} - ${lineItem.amount_total/100}
                  </div>
                ))}
              />
            );
          })}
        </div>
      }
    </>
  );

  const failedTokensTab = (
    <>
      {tokensData &&
        <div className="list-container">
          <h3>Failed Tokens</h3>
          {Object.entries(tokensData).map(([uid, tokens]) => {
            let failures = [];
            const username = allInfluencerData[uid]?.username || "";
            if (tokens?.ig?.did_fail) {
              failures.push(`${uid} (${username}): [IG] - last failed: ${new Date(tokens?.ig?.did_fail).toLocaleDateString()}`);
            }
            if (tokens?.tt?.did_fail) {
              failures.push(`${uid} (${username}): [TT] - last failed: ${new Date(tokens?.tt?.did_fail).toLocaleDateString()}`);
            }
            return (
              failures.map((failure, index) => {
                return (
                  <div key={uid + index}>
                    {failure}
                  </div>
                );
              })
            )
          })}
        </div>
      }
    </>
  );

  const tabs = {
    "Data Overview": dataOverviewTab,
    "Creators": creatorsTab,
    "Business & Campaigns": businessAndCampaignsTab,
    "Campaign Transfer Tool": campaignTransferToolTab,
    "Subscription Invite Tool": subscriptionInviteToolTab,
    "Messages": messagesTab,
    "Payments": paymentsTab,
    "Failed Tokens": failedTokensTab,
  };

  const [tabValue, setTabValue] = useState("Data Overview");

  const defaultProfilePicUrl = '../images/pfp.png';

  useEffect(() => {
    var shownMessages = messageData.allMessages;
    var businessData = null;
    if (allBusinessData[messageData.businessUid]) {
      shownMessages = messageData.allMessages
        .filter(([chatId, _]) => { return chatId.includes(messageData.businessUid) });
      businessData = allBusinessData[messageData.businessUid];
    }
    if (messageData.searchFilter) {
      shownMessages = shownMessages
        .filter(([_, messages]) => {
          return Object.values(messages).some((message) => message.content?.toLowerCase().includes(messageData.searchFilter.toLowerCase()) || message.sender?.toLowerCase().includes(messageData.searchFilter.toLowerCase()));
        }
      );
    }
    setMessageData(m => ({
      ...m,
      shownMessages,
      businessData,
    }));
  }, [allBusinessData, allInfluencerData, messageData.allMessages, messageData.businessUid, messageData.searchFilter]);

  useEffect(() => {
    const hash = subscriptionToolData.campaignUrl.includes('#') ? subscriptionToolData.campaignUrl.split('#')[1] : '';
    const match = hash.match(/campaign\/([^/]+)\/([^/?]+)/);
    const businessUid = match?.[1];
    const campaignId = match?.[2];

    const businessData = businessUid ? allBusinessData[businessUid] : null;
    const campaign = businessUid && campaignId ? campaignData[businessUid][campaignId] : null;
    const creatorData = subscriptionToolData.creatorUid ? influencerData.filter((influencer) => influencer.uid === subscriptionToolData.creatorUid)[0] : null;

    setSubscriptionToolData(d => ({
      ...d,
      businessUid,
      businessData,
      campaignId,
      campaignData: campaign,
      creatorData,
    }));
  }, [allBusinessData, campaignData, influencerData, subscriptionToolData.campaignUrl, subscriptionToolData.creatorUid]);

  useEffect(() => {
    const hash = campaignTransferToolData.campaignUrl.includes('#') ? campaignTransferToolData.campaignUrl.split('#')[1] : '';
    const match = hash.match(/campaign\/([^/]+)\/([^/?]+)/);
    const businessUid = match?.[1];
    const campaignId = match?.[2];

    const businessData = businessUid ? allBusinessData[businessUid] : null;
    const campaign = businessUid && campaignId ? campaignData[businessUid][campaignId] : null;
    const newBusinessData = campaignTransferToolData.newBusinessUid ? allBusinessData[campaignTransferToolData.newBusinessUid] : null;

    setCampaignTransferToolData(d => ({
      ...d,
      businessUid,
      businessData,
      campaignId,
      campaignData: campaign,
      newBusinessData,
    }));
  }, [allBusinessData, campaignData, campaignTransferToolData.campaignUrl, campaignTransferToolData.newBusinessUid]);

  useEffect(() => {
    let subscriptions = [];
    const source = axios.CancelToken.source();
    var publicUsersData = null;
    var influencersData = null;
    var businessesData = null;
    var campaignsData = null;
    var messagesData = null;
    var subscriptionsData = null;
    var transactionsData = null;

    const updateInfluencerDatasource = () => {
      if (!influencersData || !publicUsersData) {
        return;
      }
      const datasource = Object.entries(influencersData)
        .map(([uid, userData], index) => (
          {
            uid: uid,
            publicName: `${publicUsersData[uid]}.` || '',
            displayName: userData.display_name || '',
            username: userData.username,
            profilePicture: userData.profile_pic_url || defaultProfilePicUrl,
            market: userData.market,
            contentTags: userData.tags,
            compensationTags: userData.compensation && userData.compensation.tags,
            compensationAmount: userData.compensation && userData.compensation.amount,
            reach: ReachCalculator(userData.cached, false),
            isTrending: TrendingCalculator(userData.cached?.ig, userData.cached?.tt), 
            instagramData: userData.cached?.ig,
            tiktokData: userData.cached?.tt,
            yelpData: userData.cached?.yelp,
            platforms: (userData.cached ? [userData.cached.yelp && userData.cached.yelp.data.eliteYears && 'Yelp', userData.cached.tt && 'TT', userData.cached.ig && 'IG'] : []),
            isPobblesPro: userData.is_pobbles_pro || false,
          }
        ));
      setInfluencerData(datasource);
    };

    const updateBusinessDatasource = () => {
      if (!businessesData || !campaignsData) {
        return;
      }
      const datasource = Object.entries(businessesData).map(([uid, userData], index) => ({
        uid: uid,
        name: userData.business_name,
        market: userData.market,
        campaigns: campaignsData[uid] || [],
      }));
      setBusinessData(datasource);
      setCampaignData(campaignsData);
    };

    const updateMessageDatasource = () => {
      if (!messagesData || !influencersData || !businessesData) {
        return;
      }
      setMessageData(m => ({
        ...m,
        allMessages: Object.entries(messagesData),
      }));
    };

    const updatePaymentsDatasource = () => {
      if (!subscriptionsData || !transactionsData) {
        return;
      }
      const subscriptions = Object.values(subscriptionsData).reverse();
      const transactions = Object.values(transactionsData).reverse();
      setPaymentsData({
        subscriptions: subscriptions,
        transactions: transactions,
      });
    };

    const fetchData = async () => {
      const publicUsersDbRef = ref(database, 'public_users');
      const publicUsersDbUnsubscribe = onValue(publicUsersDbRef, (snapshot) => {
        publicUsersData = snapshot.val();
        updateInfluencerDatasource();
      });
      subscriptions.push({ ref: publicUsersDbRef, unsubscribe: publicUsersDbUnsubscribe });

      const influencersDbRef = ref(database, 'influencers');
      const influencersDbUnsubscribe = onValue(influencersDbRef, (snapshot) => {
        influencersData = snapshot.val();
        setAllInfluencerData(influencersData);
        updateInfluencerDatasource();
        updateMessageDatasource();
      });
      subscriptions.push({ ref: influencersDbRef, unsubscribe: influencersDbUnsubscribe });

      const businessesDbRef = ref(database, 'businesses');
      const businessesDbUnsubscribe = onValue(businessesDbRef, (snapshot) => {
        businessesData = snapshot.val();
        setAllBusinessData(businessesData);
        updateBusinessDatasource();
        updateMessageDatasource();
      });
      subscriptions.push({ ref: businessesDbRef, unsubscribe: businessesDbUnsubscribe });

      const campaignsDbRef = ref(database, 'campaigns');
      const campaignsDbUnsubscribe = onValue(campaignsDbRef, (snapshot) => {
        campaignsData = snapshot.val();
        updateBusinessDatasource();
      });
      subscriptions.push({ ref: campaignsDbRef, unsubscribe: campaignsDbUnsubscribe });

      const messagesRef = ref(database, 'messages');
      const messagesRefDbUnsubscribe = onValue(messagesRef, (snapshot) => {
        messagesData = snapshot.val();
        updateMessageDatasource();
      });
      subscriptions.push({ ref: messagesRef, unsubscribe: messagesRefDbUnsubscribe });

      const subscriptionsRef = ref(database, 'subscriptions');
      const subscriptionsRefDbUnsubscribe = onValue(subscriptionsRef, (snapshot) => {
        subscriptionsData = snapshot.val();
        updatePaymentsDatasource();
      });
      subscriptions.push({ ref: subscriptionsRef, unsubscribe: subscriptionsRefDbUnsubscribe });

      const transactionsRef = ref(database, 'transactions');
      const transactionsRefDbUnsubscribe = onValue(transactionsRef, (snapshot) => {
        transactionsData = snapshot.val();
        updatePaymentsDatasource();
      });
      subscriptions.push({ ref: transactionsRef, unsubscribe: transactionsRefDbUnsubscribe });

      const tokensRef = ref(database, 'tokens');
      const tokensRefDbUnsubscribe = onValue(tokensRef, (snapshot) => {
        setTokensData(snapshot.val());
      });
      subscriptions.push({ ref: tokensRef, unsubscribe: tokensRefDbUnsubscribe });
    };

    if (user) {
      fetchData();
    }

    return () => {
      source.cancel();
      for (let { ref, unsubscribe } of subscriptions) {
        off(ref, 'value', unsubscribe);
      }
      subscriptions = [];
    };
  }, [navigate, user]);

  if (!user || !isInternalUser) {
    console.log(isInternalUser);
    return (
      <div className="content-container"></div>
    );
  }

  return (
    <div className="content-container">
      <div style={{ display: 'flex', height: 'calc(100vh - 65px)' }}>
        <div className="bg-beige" style={{
          display: 'flex',
          flexDirection: 'column',
          gap:'20px',
          width: '250px',
          borderRight: '1px solid #ccc',
          overflowY: 'auto',
          position: 'sticky',
          top: '0'
        }}>
          {Object.keys(tabs).map((tabName, index) => {
            return (
              <div 
                key={index}
                style={{ margin: '0px 20px', padding: '20px 0px', cursor: 'pointer', borderBottom: tabValue === tabName ? '2px solid blue' : 'none' }}
                onClick={() => handleChangeTab(tabName)}
              >
                {tabName}
              </div>
            );
          })}
        </div>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {tabs[tabValue] || (<></>)}
        </div>
      </div>
    </div>
  );
}

export default Admin;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { database } from './lib/firebase';
import { ref, onValue, get, off } from "firebase/database";
import ChatWindow from './components/chatWindow';
import './css/web.css';

const ChatMessenger = ({user}) => {
  const { uid } = useParams();

  const [uidToMessage, setUidToMessage] = useState(null);

  const [selfUid, setSelfUid] = useState(null);

  const [datasource, setDatasource] = useState({
    messageData: null,
    influencerData: null,
    businessData: null,
  });

  const subscriptions = useRef([]);

  useEffect(() => {
    if (uid && selfUid && uid !== selfUid) {
      setUidToMessage(uid);
      window.history.replaceState(null, "pobbles", "/#/messages");
    }
  }, [uid, selfUid]);

  useEffect(() => {
    var allMessages = null;
    var influencerData = null;
    var businessesData = null;
    var associatedBusinessesData = null;

    const updateDatasource = (uid) => {
      if (!allMessages || !influencerData || !businessesData) {
        return
      }
      const relevantMessages = Object.entries(allMessages)
        .filter(([chatId, messages]) => {
          if (chatId.includes(uid)) {
            return true;
          } else if (associatedBusinessesData) {
            return associatedBusinessesData.some((businessUid) => chatId.includes(businessUid));
          } else {
            return false;
          }
        });
      setDatasource({
        messageData: relevantMessages,
        influencerData: influencerData,
        businessData: businessesData,
      });
    };
    const fetchData = ((uid) => {
      const messagesRef = ref(database, 'messages');
      const messagesRefDbUnsubscribe = onValue(messagesRef, (snapshot) => {
        allMessages = snapshot.val();
        updateDatasource(uid);
      });
      subscriptions.current.push({ ref: messagesRef, unsubscribe: messagesRefDbUnsubscribe });

      get(ref(database, 'influencers')).then((snapshot) => {
        influencerData = snapshot.val();
        updateDatasource(uid);
      });

      get(ref(database, 'businesses')).then((snapshot) => {
        businessesData = snapshot.val();
        if (businessesData[uid]?.associated_businesses) {
          associatedBusinessesData = businessesData[uid].associated_businesses;
        }
        updateDatasource(uid);
      });
    });
    if (user) {
      setSelfUid(user.uid);
      fetchData(user.uid);
    }
    return () => {
      subscriptions.current.forEach(({ ref, unsubscribe }) => off(ref, 'value', unsubscribe));
      subscriptions.current = [];
    };
  }, [user]);

  return (
    <div className="content-container" style={{ height: '100dvh', width: '100%' }}>
      <ChatWindow
        messageData={datasource.messageData}
        influencerData={datasource.influencerData}
        businessesData={datasource.businessData}
        uidToMessage={uidToMessage}
        userUid={selfUid}
      />
    </div>
  );
};

export default ChatMessenger;

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import * as constants from '../constants';
import '../css/fileUpload.css';

const FileUpload = ({ uid, businessUid, campaignId, onSuccess, onError }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const uploadPromises = acceptedFiles.map(async (file) => {
      if (!file.type.startsWith('video/') || file.size > 1073741824) { // 1GB limit
        onError('File must be a video and less than 1GB.');
        return;
      }
      try {
        const { data } = await axios.post(`${constants.backendDomain}/creator/v1/upload-video`, {
          uid,
          businessUid,
          campaignId,
          contentType: file.type,
          fileSize: file.size
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        const { url } = data;
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
            'Access-Control-Allow-Origin': '*',
          },
          body: file
        });
        if (response.ok) {
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          });
          const video = document.createElement("video");
          video.src = file.preview;
          video.addEventListener("loadedmetadata", () => {
            onSuccess('Upload successful!', file.name, video.duration);
          });
        } else {
          onError('Upload failed. Please try again.');
        }
      } catch (error) {
        onError(`Error - ${error.message}`);
      }
    });

    await Promise.all(uploadPromises);
  }, [businessUid, campaignId, uid, onSuccess, onError]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="upload-container" {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p className="drag-active">Drag and drop video files here, or click to select files</p> :
          <p>Drag and drop video files here, or click to select files</p>
      }
    </div>
  );
};

export default FileUpload;

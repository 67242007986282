import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function VerifyPhone(props) {
  const phone = props.phone;
  const confirmation = props.confirmation;

  const [code, setCode] = useState(Array(6).fill(''));
  const [focusIndex, setFocusIndex] = useState(0);
  const inputs = useRef([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (focusIndex !== null && inputs.current[focusIndex]) {
      inputs.current[focusIndex].focus();
    }
  }, [focusIndex]);

  useEffect(() => {
    const handleSubmit = async () => {
      const fullCode = code.join('');
      try {
        const result = await confirmation.confirm(fullCode);
        if (result.user) {
          window.localStorage.setItem('type', 'influencer');
          navigate('/profile');
        }
      } catch (error) {
        toast.error("Invalid code. Please try again.");
      }
    };

    if (code.every(val => val !== '')) {
      handleSubmit();
    }
  }, [code, navigate, confirmation]);

  const handleInputChange = (position, e) => {
    const value = e.target.value;

    if (value === '' && code[position] === '' && position > 0) {
      // If current input is empty and backspace is pressed,
      // set the previous input to empty and move focus to it.
      setCode(prevCode => {
        const newCode = [...prevCode];
        newCode[position - 1] = '';
        return newCode;
      });
      setFocusIndex(position - 1);
    } else if (value && value.length === 1 && /^\d$/.test(value)) {
      // Proceed if a valid digit is entered
      setCode(prevCode => {
        const newCode = [...prevCode];
        newCode[position] = value;
        return newCode;
      });
      if (position < 5 && value && /^\d$/.test(value)) {
          setFocusIndex(position + 1);
      }
    }

    // If any other non-digit character is entered, remove it
    if (value && !/^\d$/.test(value)) {
      setCode(prevCode => {
        const newCode = [...prevCode];
        newCode[position] = '';
        return newCode;
      });
    }
  };

  const handleKeyDown = (position, e) => {
    if (e.key === "Backspace") {
      if (code[position] && position === 5) {
        setCode(prevCode => {
          const newCode = [...prevCode];
          newCode[position] = '';
          return newCode;
        });
      } else if (position > 0) {
        // Clear the previous input and focus on it
        setCode(prevCode => {
          const newCode = [...prevCode];
          newCode[position - 1] = '';
          return newCode;
        });
        setFocusIndex(position - 1);
      }
    }
  };

  return (
    <div className="form-container">
      <h2>Phone verification</h2>
      <p>Please enter the 6-digit code sent to {phone} to proceed</p>
      <br />
      <form onSubmit={e => e.preventDefault()}>
        <div>
          {code.map((_, index) => (
            <input
              key={index}
              maxLength={1}
              type='number'
              className='bg-beige'
              style={{ 
                width: '30px', 
                margin: '0 2px',
                border: 'none',
                borderBottom: '1px solid #000',
                borderRadius: 0,
                outline: 'none',
                textAlign: 'center'
              }}
              value={code[index]}
              onChange={e => handleInputChange(index, e)}
              onKeyDown={e => handleKeyDown(index, e)}
              ref={ref => inputs.current[index] = ref}
            />
          ))}
        </div>
      </form>
    </div>
  );
}

export default VerifyPhone;
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { database } from './lib/firebase';
import { ref, get } from "firebase/database";
import { toast } from 'react-toastify';
import * as constants from './constants.jsx';
import './css/web.css';

const Hire = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const didSucceed = params.get('success');

  const { username } = useParams();
  const [publicName, setPublicName] = useState('');
  const [influencerData, setInfluencerData] = useState(null);
  const [reviewsData, setReviewsData] = useState(null);

  const [email, setEmail] = useState('');
  const [compensationAmount, setCompensationAmount] = useState('');
  const [tocAgreement, setTocAgreementChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [uid, setUid] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!compensationAmount) {
      toast.error("Please enter a valid compensation amount!");
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address!");
      return;
    }

    if (!tocAgreement) {
      toast.error("You must agree to the terms and conditions and privacy policy to continue.")
      return;
    }

    const redirect_uri = encodeURIComponent(window.location.href);
    const newWindow = window.open(`${constants.backendDomain}/hire-influencer?uid=${uid}&compensation=${compensationAmount}&email=${email}&redirect_uri=${redirect_uri}`, "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  useEffect(() => {
    if (username && didSucceed && didSucceed === 'true') {
      navigate(`/hire/${username}/success`)
    }
  }, [navigate, username, didSucceed]);

  useEffect(() => {
    const fetchData = (uid) => {
      const publicUsersDbRef = ref(database, `public_users/${uid}`);
      const influencersDbRef = ref(database, `influencers/${uid}`);
      const reviewsDbRef = ref(database, `reviews/${uid}`);

      Promise.all([
        get(publicUsersDbRef),
        get(influencersDbRef),
        get(reviewsDbRef)
      ]).then(([publicUserSnapshot, influencerSnapshot, reviewsSnapshot]) => {
        if (publicUserSnapshot.exists()) {
          setPublicName(publicUserSnapshot.val());
        } else {
          setPublicName('');
        }

        if (influencerSnapshot.exists()) {
          setInfluencerData(influencerSnapshot.val());
        }

        if (reviewsSnapshot.exists()) {
          setReviewsData(reviewsSnapshot.val());
        }

        setIsLoading(false);
      });
    };

    if (username) {
      const influencerUsernamesDbRef = ref(database, `influencer_usernames/${username}`);
      get(influencerUsernamesDbRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const uid = snapshot.val();
            setUid(uid);
            fetchData(uid);
          } else {
            setIsLoading(false);
          }
        });
    }
    // return auth.onAuthStateChanged(user => {
    //   if (user?.uid) {
    //     // TODO: show user as logged in
    //   }
    // });
  }, [username]);

  if (!isLoading && !uid) {
    return (
      <div className="content-container" style={{ paddingTop: '100px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src='../images/cta-image-3.png' alt="No Creator Found" style={{ padding: '20px', height: '250px', objectFit: 'cover' }}/>
          Oops! This creator does not exist.
        </div>
      </div>
    );
  }

  return (
    <div className="content-container bg-beige">
      <div className="hire-container">
        <div className="hire-splash-container">
          <div style={{ fontSize: '1.2em' }}>
            <h1 style={{ fontSize: '1.8em' }}>Welcome to Pobbles</h1>
            <span>The <i><u>preferred</u></i> platform for faciliating collaborations between local creators and local businesses.</span>
          </div>
          <div className="tag-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              className="bundle"
              style={{ display: 'flex', flexDirection: 'column', width: '30%', minWidth: '150px', maxWidth: '250px', height: '350px', alignItems: 'center', justifyContent: 'space-between', padding: '20px 0px', textAlign: 'center', cursor: 'unset' }}
            >
              <img src='../images/hire-support.png' alt="Bundle" style={{ paddingTop: '10px', width: '100%', height: '225px', objectFit: 'contain' }}/>
              <h2>24/7 Support</h2>
              <br/>
              Our support team is live and ready to assist you with any issues that may come up in your collab.
            </div>
            <div
              className="bundle"
              style={{ display: 'flex', flexDirection: 'column', width: '35%', minWidth: '200px', maxWidth: '300px', height: '400px', alignItems: 'center', justifyContent: 'space-between', padding: '20px 0px', textAlign: 'center', cursor: 'unset' }}
            >
              <img src='../images/hire-protected-payments.png' alt="Bundle" style={{ paddingTop: '10px', width: '100%', height: '225px', objectFit: 'contain' }}/>
              <h2>Protected Payments</h2>
              <br/>
              We securely hold onto the compensation until the collaboration is completed. No more payment headaches.
            </div>
            <div
              className="bundle"
              style={{ display: 'flex', flexDirection: 'column', width: '30%', minWidth: '150px', maxWidth: '250px', height: '350px', alignItems: 'center', justifyContent: 'space-between', padding: '20px 0px', textAlign: 'center', cursor: 'unset' }}
            >
              <img src='../images/hire-trusted.png' alt="Bundle" style={{ paddingTop: '10px', width: '100%', height: '225px', objectFit: 'contain' }}/>
              <h2>Locally Trusted</h2>
              <br/>
              We have supported hundreds of local businesses, just like you.
            </div>
          </div>
        </div>
        <div className="form-container" style={{ backgroundColor: '#fefbf6' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '40px 0px', gap: '20px' }}>
            {influencerData?.profile_pic_url && 
              <div className="profilePic-container" onClick={() => navigate(`/user/${username}`)}>
                <img src={influencerData.profile_pic_url} alt="Profile" className="profilePic" />
              </div>
            }
            {publicName && <h2>Hire <a href={`/#/user/${username}`} target="_blank" rel="noopener noreferrer">{publicName}.</a> today!</h2>}
            {publicName && reviewsData &&
              <>
                <i>"{Object.values(reviewsData)[0].review}"</i> - Local Restaurant, San Francisco
                <span style={{ fontSize: '0.9em' }}>For more details, <a href={`/#/user/${username}`} target="_blank" rel="noopener noreferrer">visit their profile</a>.</span>
              </>
            }
          </div>
          <form>
            <label className="required" htmlFor="compensationAmount" style={{ width: 'unset', textAlign: 'unset' }}>Compensation Amount ($):</label>
            <input
              id="compensationAmount"
              type="number"
              min="1"
              max="1000000"
              step="1"
              value={compensationAmount}
              placeholder={influencerData?.compensation?.amount || '100'}
              onChange={(e) => setCompensationAmount(e.target.value)}
              style={{ margin: 'unset' }}
              required
            />
            <label className="required" htmlFor="email" style={{ width: 'unset', textAlign: 'unset' }}>Enter your email for collaboration updates:</label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="john.doe@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ margin: 'unset' }}
              required
            />
            <label
              style= {{ textAlign: 'center', width: 'revert' }}>
              <input
                  type="checkbox"
                  checked={tocAgreement}
                  onChange={e => setTocAgreementChecked(e.target.checked)}
                  style={{ margin: '10px' }}
              />
              I agree to the <a href="https://pobbles.co/termsandconditions" target="_blank" rel="noopener noreferrer">terms and conditions</a> and <a href="https://pobbles.co/privacy" target="_blank" rel="noopener noreferrer">privacy policy</a>.
            </label>
            <button id="button-container" className="btn diamond-btn diamond-btn--sm diamond-btn--yellow" type="submit" onClick={handleSubmit}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Hire;

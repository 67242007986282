import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from './lib/firebase';
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { toast } from 'react-toastify';

function EmailVerification() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let email = window.localStorage.getItem('emailForSignIn');

    const params = new URLSearchParams(location.search);
    var redirectParam = '';

    if (params.has('redirect')) {
      redirectParam = params.get('redirect');
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          if (redirectParam) {
            if (redirectParam === 'campaigns') {
              navigate("/campaigns");
            } else if (redirectParam === 'welcome') {
              navigate("/welcome");
            }
          } else {
            navigate("/campaigns");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }, [location, navigate]);

  return (
    <div></div>
  );
}

export default EmailVerification;
import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthenticatedRoute = ({ user, children }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return React.Children.map(children, child => {
    return React.cloneElement(child, { user });
  });
};

export default AuthenticatedRoute;

import React, { useState, useEffect, useRef } from 'react';
import { database } from '../lib/firebase';
import { ref, onValue, get, child, push, update, off } from "firebase/database";
import { MainContainer, Sidebar, ChatContainer, ConversationList, Conversation, ConversationHeader, MessageList, MessageGroup, Message, MessageInput, AvatarGroup, Avatar } from "@chatscope/chat-ui-kit-react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import ReactTimeAgo from 'react-time-ago'
import '../css/web.css';

const ChatWindow = ({ messageData, influencerData, businessData, uidToMessage, userUid }) => {
  const [isViewOnlyMode, setIsViewOnlyMode] = useState(false);

  const [selfUid, setSelfUid] = useState(null);
  const [otherUid, setOtherUid] = useState(null);
  const [chatId, setChatId] = useState(null);

  const [datasource, setDatasource] = useState([]);

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const [sendDisabled, setSendDisabled] = useState(true);

  const [publicUsers, setPublicUsers] = useState([]);
  const [avatarImages, setAvatarImages] = useState({});

  const [sidebarStyle, setSidebarStyle] = useState({});
  const [chatContainerStyle, setChatContainerStyle] = useState({});
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});

  const defaultProfilePicUrl = '../images/pfp.png';

  const isMounted = useRef(true);

  useEffect(() => {
    if (isViewOnlyMode) return;
    if (uidToMessage) {
      handleConversationClick([userUid, uidToMessage].sort().join(''));
    }
  }, [uidToMessage, userUid, isViewOnlyMode]);

  useEffect(() => {
    const markMessageAsRead = async (messages) => {
      if (isViewOnlyMode || !chatId.includes(userUid)) return;
      if (!isMounted.current) return;
      for (const [messageId, messageObject] of Object.entries(messages)) {
        if (messageObject.senderId === otherUid && !messageObject.isRead) {
          const messageRef = ref(database, `messages/${chatId}/${messageId}`);
          await update(messageRef, { isRead: true });
        }
      }
    };

    if (window.screen.width < 500) {
      if (chatId) {
        setSidebarStyle({});
        setConversationContentStyle({});
        setConversationAvatarStyle({});
        setChatContainerStyle({});
      } else {
        setSidebarStyle({
          display: "flex",
          flexBasis: "auto",
          width: "100%",
          maxWidth: "100%"
        });
        setConversationContentStyle({
          display: "flex"
        });
        setConversationAvatarStyle({
          marginRight: "1em"
        });
        setChatContainerStyle({
          display: "none"
        });
      }
    } else {
      setSidebarStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({});
      setChatContainerStyle({});
    }
    
    isMounted.current = true;
    let subscriptions = [];
    if (chatId) {
      const messagesRef = ref(database, `messages/${chatId}`);
      const messagesRefDbUnsubscribe = onValue(messagesRef, (snapshot) => {
        const allMessages = snapshot.val();
        if (allMessages) {
          const { senders, ...messagesObj } = allMessages;
          const messagesArray = Object.values(messagesObj);
          setMessages(messagesArray);
          if (!isViewOnlyMode && chatId.includes(userUid)) {
            markMessageAsRead(messagesObj);
          }
        }
      });
      subscriptions.push({ ref: messagesRef, unsubscribe: messagesRefDbUnsubscribe });

      get(ref(database, 'influencers')).then((snapshot) => {
        const influencersData = snapshot.val();
        const selfAvatarUrl = (influencersData[selfUid] && influencersData[selfUid].profile_pic_url) || defaultProfilePicUrl;
        const chatAvatarUrl = (influencersData[otherUid] && influencersData[otherUid].profile_pic_url) || defaultProfilePicUrl;
        setAvatarImages({
          [selfUid]: selfAvatarUrl,
          [otherUid]: chatAvatarUrl,
        });
      });

      get(ref(database, 'public_users')).then((snapshot) => {
        const publicUsers = snapshot.val();
        setPublicUsers(publicUsers);
        if (!isViewOnlyMode && chatId.includes(userUid)) {
          update(child(messagesRef, 'senders'), {
            [selfUid]: publicUsers[selfUid],
            [otherUid]: publicUsers[otherUid]
          });
        }
      });
    } else {
      setMessages([]);
    }
    return () => {
      isMounted.current = false;
      for (let { ref, unsubscribe } of subscriptions) {
        off(ref, 'value', unsubscribe);
      }
      subscriptions = []
    };
  }, [chatId, selfUid, otherUid, isViewOnlyMode, userUid]);

  useEffect(() => {
    if (messageData) {
      setDatasource(
        messageData.map(([chatId, messages]) => {
          const messagesArray = Object.values(messages);
          const lastMessage = (messagesArray.length > 1 && [...messagesArray].reverse()[1]) || {};
          if (chatId.includes(userUid)) {
            const otherPartyUid = chatId.replace(userUid, '');
            const otherPartyBusinessName = businessData?.[otherPartyUid]?.business_name ? `(${businessData[otherPartyUid].business_name})` : "";
            const unreadCount = Object.entries(messages).filter(([id, msg]) => id !== 'senders' && !msg.isRead && msg.senderId !== userUid).length;
            return {
              id: chatId,
              avatar: [(influencerData && influencerData[otherPartyUid] && influencerData[otherPartyUid].profile_pic_url) || defaultProfilePicUrl],
              name: `${messages['senders'][otherPartyUid]} ${otherPartyBusinessName}`,
              lastMessage: lastMessage,
              date: lastMessage.timestamp ? new Date(lastMessage.timestamp * 1000) : new Date(),
              unread: unreadCount,
            }
          } else {
            const unreadCount = Object.entries(messages).filter(([id, msg]) => id !== 'senders' && !msg.isRead).length;
            return {
              id: chatId,
              avatar: Object.keys(messages['senders']).map((uid) => influencerData[uid]?.profile_pic_url || defaultProfilePicUrl),
              name: Object.values(messages['senders']).join(' x '),
              lastMessage: lastMessage,
              date: lastMessage.timestamp ? new Date(lastMessage.timestamp * 1000) : new Date(),
              unread: unreadCount,
            };
          }
        })
        .sort((a, b) => b.date - a.date)
      );
    }
  }, [messageData]);

  useEffect(() => {
    if (chatId) {
      setIsViewOnlyMode(!chatId.includes(userUid));
    } else {
      setIsViewOnlyMode(false);
    }
  }, [chatId, userUid]);

  useEffect(() => {
    setChatId(null);
    setOtherUid(null);
    setSelfUid(userUid);
  }, [userUid]);

  useEffect(() => {
    setSendDisabled(message.length===0);
  },[message]);

  function removeTags(str) {
    if ((str === null) || (str === ''))
        return false;
    else
        str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  const groupMessages = (messages) => {
    const grouped = [];
    let lastSenderId = null;

    messages.forEach(msg => {
      if (msg.senderId !== lastSenderId) {
        lastSenderId = msg.senderId;
        grouped.push({ senderId: msg.senderId, messages: [msg] });
      } else {
        grouped[grouped.length - 1].messages.push(msg);
      }
    });
    return grouped;
  };

  const renderMessageGroups = (groupedMessages) => {
    return groupedMessages.map((group, idx) => (
      <MessageGroup
        key={idx}
        sender={publicUsers[group.senderId]}
        direction={group.senderId === selfUid ? 'outgoing' : 'incoming'}
      >
        <Avatar src={avatarImages[group.senderId]} name={`${publicUsers[group.senderId]}.`} />
        <MessageGroup.Header style={{ display: 'flex', justifyContent: `${group.senderId === selfUid ? 'right' : 'left'}` }}>
          {group.senderId !== selfUid && `${publicUsers[group.senderId]}.`}
        </MessageGroup.Header>
        <MessageGroup.Messages>
          {group.messages.map((msg, msgIdx) => (
            <Message key={msgIdx} model={{
              message: msg.content,
            }} />
          ))}
        </MessageGroup.Messages>
        <MessageGroup.Footer style={{ display: 'flex', justifyContent: `${group.senderId === selfUid ? 'right' : 'left'}` }}>
          {[...group.messages].reverse()[0].isRead ? <span>Seen ·&ensp;</span> : <span>Delivered ·&ensp;</span>}
          {group.messages.length > 0 && <ReactTimeAgo date={new Date([...group.messages].reverse()[0].timestamp * 1000)} locale="en-US" timeStyle="mini-minute-now" />}
        </MessageGroup.Footer>
      </MessageGroup>
    ));
  };

  const handleConversationClick = (id) => {
    if (id.includes(userUid)) {
      setIsViewOnlyMode(false);
      setSelfUid(userUid);
      setOtherUid(id.replace(selfUid, ''));
    } else {
      setIsViewOnlyMode(true);
      // TODO: this will break if we ever move away from current uids
      setSelfUid(id.slice(0, id.length / 2));
      setOtherUid(id.slice(id.length / 2, id.length));
    }
    setChatId(id);
  };

  const handleBackButtonClick = () => {
    setSelfUid(userUid);
    setOtherUid(null);
    setChatId(null);
  };

  const sendMessage = () => {
    if (isViewOnlyMode) return;
    if (!message) return;
    const messagesRef = ref(database, `messages/${chatId}`);
    push(messagesRef, {
      type: 'message',
      content: message,
      displayName: publicUsers[selfUid],
      senderId: selfUid,
      timestamp: Math.floor(Date.now() / 1000),
    });
    setMessage('');
  };

  if (!datasource || !datasource.length) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', paddingTop: '40px' }}>
        <img src='../images/cta-image-3.png' alt="No Messages CTA" style={{ padding: '20px', width: '500px', height: '300px', objectFit: 'contain' }}/>
        <span>
          You don't have any messages yet.
        </span>
      </div>
    );
  }

  return (
    <MainContainer responsive>
      <Sidebar position="left" style={sidebarStyle}>
        <ConversationList>
          {datasource && datasource.map((data) => (
            <Conversation
              key={data.id}
              lastActivityTime={data.date && <ReactTimeAgo date={data.date} locale="en-US" timeStyle="mini-minute-now" />}
              unreadCnt={data.unread}
              onClick={() => handleConversationClick(data.id)}
              active={data.id === otherUid}
            >
              {data.avatar.length > 1
                ? <AvatarGroup size="sm">
                    {data.avatar.map((avatar, index) => {
                      return (<Avatar key={index} src={avatar} style={conversationAvatarStyle} />);
                    })}
                  </AvatarGroup>
                : <Avatar src={data.avatar[0]} name={data.name} style={conversationAvatarStyle} />
              }
              {data.lastMessage && <Conversation.Content name={data.name} lastSenderName={data.lastMessage.sender} info={removeTags(data.lastMessage.content || "")} style={conversationContentStyle} />}
            </Conversation>
          ))}
        </ConversationList>
      </Sidebar>
      <ChatContainer style={chatContainerStyle}>
        {!chatId?.includes(userUid)
          ? chatId &&
            <ConversationHeader>
              <ConversationHeader.Back onClick={handleBackButtonClick} />
              <AvatarGroup size="sm">
                {avatarImages[selfUid] && <Avatar src={avatarImages[selfUid]} />}
                {avatarImages[otherUid] && <Avatar src={avatarImages[otherUid]} />}
              </AvatarGroup>
              <ConversationHeader.Content userName={`${publicUsers[selfUid]} X ${publicUsers[otherUid]}`} /> 
            </ConversationHeader>
          : otherUid &&
            <ConversationHeader>
              <ConversationHeader.Back onClick={handleBackButtonClick} />
              {avatarImages[otherUid] && <Avatar src={avatarImages[otherUid]} name={publicUsers[otherUid]} />}
              <ConversationHeader.Content userName={publicUsers[otherUid]} /> 
            </ConversationHeader>
        }
        <MessageList>
          {messages.length || otherUid
            ? renderMessageGroups(groupMessages(messages))
            :
              <MessageList.Content style={{
                display: "flex",
                "flexDirection": "column",
                "justifyContent": "center",
                height: "100%",
                textAlign: "center",
                fontSize: "1.2em"
              }}>
                Select a conversation from the left sidebar.
              </MessageList.Content>
          }
        </MessageList>
        {!isViewOnlyMode &&
          <MessageInput
            attachButton={false}
            disabled={otherUid === null}
            sendDisabled={sendDisabled}
            placeholder="Type message here"
            value={message}
            onChange={val => setMessage(val)}
            onSend={sendMessage}
            onPaste={(evt) => {
              evt.preventDefault();
              setMessage(message + evt.clipboardData.getData("text"));
            }}
            autoFocus
          />
        }
      </ChatContainer>
    </MainContainer>
  );
};

export default ChatWindow;

import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFeatureFlag, FeatureFlag } from '../lib/FeatureFlags';
import { auth, database } from '../lib/firebase';
import { ref, onValue, get, off } from "firebase/database";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faUser, faUsers, faUsersBetweenLines, faMessage, faCog } from '@fortawesome/free-solid-svg-icons';

const AppNavBar = ( { isAuthenticated, type, shouldShowCampaigns, hasInvitedCampaigns, hasUnreadMessages } ) => {
  const isBusinessSMMEnabled = useFeatureFlag(FeatureFlag.businessSMM);

  const [isActive, setIsActive] = useState(false);
  const [isBodyOverflowHidden, setBodyOverflow] = useState(false);

  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (isAuthenticated) {
      if (type === 'business') {
        navigate("/campaigns");
      } else {
        navigate("/profile");
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      if (type === 'business') {
        window.location.replace("https://pobbles.co/businesses/");
      } else {
        window.location.replace("https://pobbles.co/creators/");
      }
    }
  };

  const handleMenuClick = () => {
    setIsActive(!isActive);
    setBodyOverflow(!isActive);
  };

  const handleOverlayClick = () => {
    setIsActive(!isActive);
    setBodyOverflow(!isActive);
  };

  useEffect(() => {
    document.body.style.overflow = isBodyOverflowHidden ? "hidden" : "auto";
  }, [isBodyOverflowHidden]);

  return (
    <div>
      <header className="header bg-green">
        <div className="header__wrap">
          <div className="nav__container">
              <div className="header__inner">
                <img src="../images/logo.png" className="header__logo" alt="Logo" onClick={handleLogoClick} />
                <div className={`header__body ${isActive ? "_active" : ""}`}>
                  {isAuthenticated &&
                    <>
                      {shouldShowCampaigns && <Link to="/campaigns" className="header__link text-md text-white-2"><FontAwesomeIcon icon={faUsersBetweenLines} />&ensp;Campaigns{hasInvitedCampaigns && <span className="notification-dot"></span>}</Link>}
                      {type === 'influencer' && <Link to="/profile" className="header__link text-md text-white-2"><FontAwesomeIcon icon={faUser} />&ensp;Profile</Link>}
                      {isBusinessSMMEnabled && <Link to="/social-media-manager" className="header__link text-md text-white-2"><FontAwesomeIcon icon={faUsers} />&ensp;Social Media Manager</Link>}
                      <Link to="/payments" className="header__link text-md text-white-2"><FontAwesomeIcon icon={faMoneyBill} />&ensp;Payments</Link>
                      <Link to="/messages" className="header__link text-md text-white-2"><FontAwesomeIcon icon={faMessage} />&ensp;Messages{hasUnreadMessages && <span className="notification-dot"></span>}</Link>
                      <Link to="/settings" className="header__link text-md text-white-2"><FontAwesomeIcon icon={faCog} />&ensp;Settings</Link>
                    </>
                  }
                </div>
                {isAuthenticated &&
                  <div className={`menu__icon__container ${isActive ? "_active" : ""}`}>
                    <div
                      className={`menu__icon ${isActive ? "_active" : ""}`}
                      onClick={handleMenuClick}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    {hasUnreadMessages && <div className={`notification-dot ${isActive ? "_active" : ""}`}></div>}
                  </div>
                }
              </div>
          </div>
        </div>
      </header>
      <div
        className={`overlay ${isActive ? "active" : ""}`}
        onClick={handleOverlayClick}
      >
      </div>
    </div>
  )
};

const NavBar = () => {
  const location = useLocation();

  const type = useRef(window.localStorage.getItem('type'));

  const [uid, setUid] = useState(null);

  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [shouldShowCampaigns, setShouldShowCampaigns] = useState(false);
  const [hasInvitedCampaigns, setHasInvitedCampaigns] = useState(false);

  const fetchUnreadMessages = (uid, callback) => {
    const messagesRef = ref(database, 'messages');
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const allMessages = snapshot.val();
      let unreadCount = 0;

      if (allMessages) {
        Object.entries(allMessages).forEach(([chatId, messages]) => {
          if (chatId.includes(uid)) {
            Object.entries(messages).forEach(([messageId, message]) => {
              if (messageId === 'senders') return;
              if (message.senderId !== uid && !message.isRead) {
                unreadCount++;
              }
            });
          }
        });
      }

      callback(unreadCount);
    });

    return () => off(messagesRef, 'value', unsubscribe);
  };

  useEffect(() => {
    if (uid) {
      const unsubscribe = fetchUnreadMessages(uid, (unreadCount) => {
        setHasUnreadMessages(unreadCount > 0);
      });
      return unsubscribe;
    }
  }, [uid]);

  const checkIfShouldShowCampaigns = (uid, callback) => {
    if (type.current === 'business') {
      callback(true, false);  // always show campaigns for business type accounts, do not show red dot
      return
    } else {
      get(ref(database, 'campaigns'), 'value').then((snapshot) => {
        const allCampaigns = snapshot.val();
        let shouldShow = false;
        let isInvited = false;
        if (allCampaigns) {
          Object.values(allCampaigns).forEach((campaigns) => {
            Object.values(campaigns).forEach((campaign) => {
              if (campaign.influencers && Object.keys(campaign.influencers).includes(uid) && campaign.influencers[uid].status !== 'new') {
                shouldShow = true;
                if (campaign.influencers[uid].status === 'invited') {
                  isInvited = true;
                }
              }
            });
          });
        }
        callback(shouldShow, isInvited);
      });
    }
  };

  useEffect(() => {
    return auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid);
        type.current = window.localStorage.getItem('type');
        checkIfShouldShowCampaigns(user.uid, (shouldShow, isInvited) => {
          setShouldShowCampaigns(shouldShow);
          setHasInvitedCampaigns(isInvited);
        });
      } else {
        setUid(null);
      }
    });
  }, []);

  if (location.pathname.startsWith("/business")) {
    return (
      <AppNavBar
        isAuthenticated={uid !== null}
        type='business'
        shouldShowCampaigns={shouldShowCampaigns}
        hasInvitedCampaigns={hasInvitedCampaigns}
        hasUnreadMessages={hasUnreadMessages}
      />
    );
  }

  if (location.pathname.startsWith("/signup")) {
    return (
      <AppNavBar
        isAuthenticated={uid !== null}
        type='influencer'
        shouldShowCampaigns={shouldShowCampaigns}
        hasInvitedCampaigns={hasInvitedCampaigns}
        hasUnreadMessages={hasUnreadMessages}
      />
    );
  }

  if (location.pathname.startsWith("/login")) {
    return (
      <AppNavBar
        isAuthenticated={uid !== null}
        type='influencer'
        shouldShowCampaigns={shouldShowCampaigns}
        hasInvitedCampaigns={hasInvitedCampaigns}
        hasUnreadMessages={hasUnreadMessages}
      />
    );
  }

  if (location.pathname.startsWith("/profile")) {
    return (
      <AppNavBar
        isAuthenticated={uid !== null}
        type='influencer'
        shouldShowCampaigns={shouldShowCampaigns}
        hasInvitedCampaigns={hasInvitedCampaigns}
        hasUnreadMessages={hasUnreadMessages}
      />
    );
  }

  return (
    <AppNavBar
      isAuthenticated={uid !== null}
      type={type.current}
      shouldShowCampaigns={shouldShowCampaigns}
      hasInvitedCampaigns={hasInvitedCampaigns}
      hasUnreadMessages={hasUnreadMessages}
    />
  );
}

export default NavBar;
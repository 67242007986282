import React from 'react';
import '../css/web.css';

const ProgressTimeline = ( { status, states } ) => {
  const currentIndex = states.indexOf(status);

  return (
    <div className="progress-timeline-container">
      {states.map((state, index) =>
        <React.Fragment key={state}>
          <div 
            className={`progress-timeline-item ${currentIndex >= index ? 'filled' : ''}`}
          >
            {state[0].toUpperCase() + state.substring(1)}
          </div>
          {index < states.length - 1 &&
            <div className={`progress-timeline-bar ${currentIndex > index ? 'filled-bar' : ''}`}></div>
          }
        </React.Fragment>
      )}
    </div>
  );
}

export default ProgressTimeline;
